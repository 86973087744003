import { format, isValid, isBefore } from 'date-fns'
import { toNumber } from 'lodash'
import {
  formatCurrency,
  validateTaxId,
  parseDateFromString,
  findCountry,
  findUSState,
  convertToLowercase,
  convertToUppercase,
} from 'utils'
import * as yup from 'yup'
import { getFlatfileConfig, RecordTypes } from 'flatfile-config'
import { US_COUNTRY_VALUE } from 'config/locations'
import {
  BD_TRANSACTION_TYPES,
  BD_PAYMENT_INSTRUCTIONS,
  PAYMENT_METHODS,
} from 'config/benefit-distributions'
import { invalidPostalCodes } from '../config/products'
import formatZipCode from './formatZipCode'

const numericRegex = /[0-9]/g
const currencyRegex = /[0-9-.]/g

const requiredError = [{ message: 'Required.', level: 'error' }]

// returns false if a value has invalid currency formatting (e.g., "10,00", "20.000", "30.00.00", "40.00,200")
// or if it doesn't contain any number (e.g., "apple", "")
const isValidAmount = (value) => {
  const sanitized = value.replaceAll(/[^0-9.,]/g, '')
  if (!sanitized) return false
  const currencyParts = sanitized.split('.')
  // If a decimal point exists, ensure that it only occurs at the end of the string and is followed by 1 or 2 digits
  if (
    currencyParts.length > 2 ||
    (currencyParts.length === 2 && !/^[0-9]{1,2}$/.test(currencyParts[1]))
  )
    return false
  // Check the value provided for proper separation (i.e., ignore the decimal)
  const [first, ...rest] = currencyParts[0].split(',')
  if (rest.length === 0) return true
  return first.length <= 3 && rest.every((v) => v.length === 3)
}

const removeCurrencySymbolAndParse = (value) => {
  // Regular expression to remove any non-digit characters except '.', '-'
  const sanitizedValue = value.replace(/[^\d.]/g, '')
  return parseFloat(sanitizedValue) || 0
}

const formatAndValidateCurrency = (input, ignoreFormatErrorMessage = false) => {
  if (ignoreFormatErrorMessage) input = input.replace(/[^$\d,.]/g, '')
  const sanitizedInput = input.match(currencyRegex)?.join('')
  const amount = toNumber(sanitizedInput)
  if (isNaN(amount) || !isValidAmount(input)) {
    return {
      value: input,
      info: ignoreFormatErrorMessage
        ? []
        : [
            {
              message: 'Format must be: $X,XXX.XX',
              level: 'error',
            },
          ],
    }
  }
  return {
    value: formatCurrency(amount),
    info:
      amount < 0
        ? [
            {
              message: 'Amount cannot be negative',
              level: 'error',
            },
          ]
        : [],
  }
}

const formatToPercentage = (input, ignoreFormatErrorMessage = false) => {
  if (ignoreFormatErrorMessage) input = input.replace(/[^$\d,.]/g, '')
  const sanitizedInput = input.match(currencyRegex)?.join('')
  let value = toNumber(sanitizedInput)
  if (isNaN(value) || !isValidAmount(input)) {
    return {
      value: input,
      info: ignoreFormatErrorMessage
        ? []
        : [
            {
              message: 'Format must be: XX.XX%',
              level: 'error',
            },
          ],
    }
  }
  let formattedNumber = value.toFixed(2)
  return {
    value: `${formattedNumber}%`,
    info:
      formattedNumber < 0
        ? [
            {
              message: 'Percentage cannot be negative',
              level: 'error',
            },
          ]
        : formattedNumber > 100
        ? [
            {
              message: 'Percentage must be between 0 and 100',
              level: 'error',
            },
          ]
        : [],
  }
}

const flatfileConfig = getFlatfileConfig(RecordTypes.BD_PARTICIPANT)
const fieldKeys = flatfileConfig.fields.map((field) => field.key)

function formatAndValidateBDRecord(originalRecord, paymentMethod) {
  let convertedRecord = fieldKeys.reduce((record, field) => {
    record[field] = { value: record[field]?.trim() ?? '' }
    return record
  }, originalRecord)

  const formattedRecord = formatRecord(convertedRecord, paymentMethod)
  const formattedAndValidatedRecord = validateRecord(formattedRecord)

  return formattedAndValidatedRecord
}

const formatRecord = (record, paymentMethod) => {
  let {
    planEin,
    planNumber,
    zipCode,
    dob,
    ssn,
    state,
    countryCode,
    amount,
    transactionType,
    paymentInstructions,
    tpaBenefitDistributionProcessingFee,
    inspiraBenefitDisbursementProcessingFee,
    stateForCheck,
    zipForCheck,
    taxableAmount,
    taxableAmountNotDetermined,
    totalDistribution,
    employeeContributions,
    federalIncomeTax,
    percentageTotalDistribution,
    capitalGain,
    netAppreciation,
    distributionCode,
    totalEmployeeContributions,
    amountAllocable,
    stateTaxWithheld,
  } = record

  if (planEin.value) {
    const sanitizedPlanEin = planEin.value.match(numericRegex)?.join('')
    // formats EIN to XX-XXXXXXX
    if (sanitizedPlanEin) {
      planEin.value = sanitizedPlanEin.replace(/(\d{2})(\d+)/, '$1-$2')
    }
  }
  if (planNumber.value) {
    const sanitizedPlanNumber = planNumber.value.match(numericRegex)?.join('')
    if (sanitizedPlanNumber) {
      planNumber.value = sanitizedPlanNumber.replace(/(\d{3})/, '$1')
    }
  }
  if (zipCode.value) {
    zipCode.value = formatZipCode(zipCode.value)
  }
  if (dob.value) {
    const dobDate = parseDateFromString(dob.value.trim())
    let formattedDob = dob.value

    if (dobDate) {
      formattedDob = format(dobDate, 'yyyy/MM/dd')
    } else {
      const sanitizedDob = dob.value.match(numericRegex)?.join('')
      if (sanitizedDob) {
        formattedDob = sanitizedDob.replace(/(\d{4})(\d{2})(\d{2})/, '$1/$2/$3')
      }
    }
    dob.value = formattedDob
  }
  if (ssn.value) {
    const firstLetter = ssn.value.match(/(([\d]\D*?){9})([a-zA-Z])/)?.[3] || ''
    const sanitizedSsn = ssn.value.match(numericRegex)?.join('')
    // note: this approach concatenates numbers first: e.g., 111-22-3333A000 -> 111-22-3333000A
    if (sanitizedSsn) {
      ssn.value =
        sanitizedSsn.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3') + firstLetter
    }
  }

  if (countryCode.value) {
    const matchingCountry = findCountry(countryCode.value)

    if (matchingCountry) {
      countryCode.value = matchingCountry.value
    } else {
      countryCode.info = [
        {
          message: 'Must be a valid country name or country code',
          level: 'error',
        },
      ]
    }
  }

  if (state.value && countryCode.value === US_COUNTRY_VALUE) {
    const matchingState = findUSState(state.value)
    if (matchingState) {
      state.value = matchingState.value
    } else {
      state.info = [{ message: 'Must be a valid US state', level: 'error' }]
    }
  }
  if (amount.value) {
    record.amount = formatAndValidateCurrency(amount.value)
  }

  if (tpaBenefitDistributionProcessingFee.value) {
    record.tpaBenefitDistributionProcessingFee = formatAndValidateCurrency(
      tpaBenefitDistributionProcessingFee.value
    )
  }

  if (
    transactionType.value.toLowerCase() ===
    BD_TRANSACTION_TYPES.check.toLocaleLowerCase()
  ) {
    inspiraBenefitDisbursementProcessingFee.value = '35'
    record.inspiraBenefitDisbursementProcessingFee = formatAndValidateCurrency(
      inspiraBenefitDisbursementProcessingFee.value
    )
  }
  if (transactionType.value.toUpperCase() === BD_TRANSACTION_TYPES.ach) {
    inspiraBenefitDisbursementProcessingFee.value = '35'
    record.inspiraBenefitDisbursementProcessingFee = formatAndValidateCurrency(
      inspiraBenefitDisbursementProcessingFee.value
    )
    transactionType.value = convertToUppercase(transactionType.value)
  }
  if (transactionType.value.toUpperCase() !== BD_TRANSACTION_TYPES.ach) {
    transactionType.value = convertToLowercase(transactionType.value)
  }
  if (paymentInstructions.value) {
    paymentInstructions.value = convertToLowercase(paymentInstructions.value)
  }

  if (
    [
      BD_TRANSACTION_TYPES.wire.toLocaleLowerCase(),
      BD_TRANSACTION_TYPES.overnight.toLocaleLowerCase(),
    ].includes(transactionType.value.toLowerCase())
  ) {
    inspiraBenefitDisbursementProcessingFee.value = '65'
    record.inspiraBenefitDisbursementProcessingFee = formatAndValidateCurrency(
      inspiraBenefitDisbursementProcessingFee.value
    )
  }

  if (inspiraBenefitDisbursementProcessingFee.value) {
    record.inspiraBenefitDisbursementProcessingFee = formatAndValidateCurrency(
      inspiraBenefitDisbursementProcessingFee.value
    )
  }

  ///Distribution Amount Calculation Start///

  let amountInput = removeCurrencySymbolAndParse(amount.value)
  let tpaProcessingFeeInput = removeCurrencySymbolAndParse(
    tpaBenefitDistributionProcessingFee.value
  )
  let inspiraProcessingFeeInput = removeCurrencySymbolAndParse(
    inspiraBenefitDisbursementProcessingFee.value
  )

  let distributionAmountInputDD = (
    amountInput -
    (tpaProcessingFeeInput + inspiraProcessingFeeInput)
  ).toFixed(2)
  let distributionAmountInputCC = (amountInput - tpaProcessingFeeInput).toFixed(
    2
  )

  if (paymentMethod == PAYMENT_METHODS.deductFromDistribution) {
    record.grossDistributionAmount = formatAndValidateCurrency(
      distributionAmountInputDD.toString()
    )
    record.grossDistribution = formatAndValidateCurrency(
      distributionAmountInputDD.toString()
    )
  }
  if (paymentMethod == PAYMENT_METHODS.creditCard) {
    record.grossDistributionAmount = formatAndValidateCurrency(
      distributionAmountInputCC.toString()
    )
    record.grossDistribution = formatAndValidateCurrency(
      distributionAmountInputCC.toString()
    )
  }
  if (capitalGain.value) {
    record.capitalGain = formatAndValidateCurrency(capitalGain.value)
  }
  if (netAppreciation.value) {
    record.netAppreciation = formatAndValidateCurrency(netAppreciation.value)
  }
  if (totalEmployeeContributions.value) {
    record.totalEmployeeContributions = formatAndValidateCurrency(
      totalEmployeeContributions.value
    )
  }
  if (amountAllocable.value) {
    record.amountAllocable = formatAndValidateCurrency(amountAllocable.value)
  }
  ///Distribution Amount Calculation End///
  if (stateForCheck.value) {
    const matchingState = findUSState(stateForCheck.value)
    if (matchingState) {
      stateForCheck.value = matchingState.value
    } else {
      stateForCheck.info = [
        { message: 'Must be a valid US state', level: 'error' },
      ]
    }
  }
  if (zipForCheck.value) {
    zipForCheck.value = zipForCheck.value.replaceAll(/[^a-zA-Z0-9- ]/g, '')
    const sanitizedZip = zipForCheck.value.match(numericRegex)?.join('')
    if (sanitizedZip) {
      zipForCheck.value = sanitizedZip
      if (sanitizedZip.length === 4 || sanitizedZip.length === 8) {
        zipForCheck.value = '0' + zipForCheck.value
      } else if (sanitizedZip.length === 3 || sanitizedZip.length === 7) {
        zipForCheck.value = '00' + zipForCheck.value
      } else if (sanitizedZip.length === 2 || sanitizedZip.length === 6) {
        zipForCheck.value = '000' + zipForCheck.value
      }
    }
    if (sanitizedZip.length > 5) {
      zipForCheck.value =
        zipForCheck.value.substring(0, 5) + '-' + zipForCheck.value.substring(5)
    }
  }
  if (taxableAmount.value) {
    record.taxableAmount = formatAndValidateCurrency(taxableAmount.value)
  }
  if (taxableAmountNotDetermined.value) {
    taxableAmountNotDetermined.value = convertToUppercase(
      taxableAmountNotDetermined.value
    )
  }
  if (totalDistribution.value) {
    totalDistribution.value = convertToUppercase(totalDistribution.value)
  }
  if (employeeContributions.value) {
    record.employeeContributions = formatAndValidateCurrency(
      employeeContributions.value
    )
  }
  if (federalIncomeTax.value) {
    record.federalIncomeTax = formatToPercentage(federalIncomeTax.value)
  }
  if (percentageTotalDistribution.value) {
    record.percentageTotalDistribution = formatToPercentage(
      percentageTotalDistribution.value
    )
  }
  if (distributionCode.value) {
    distributionCode.value = convertToUppercase(distributionCode.value)
  }
  if (stateTaxWithheld.value) {
    record.stateTaxWithheld = formatToPercentage(stateTaxWithheld.value)
  }

  return record
}

/* validation, especially presence validation, should be configured under 'fields' outlined in flatfile configuration-- 
 that way, if a file is missing required fields, flatfile would throw an error and prevent the user from proceeding to the "Review" step;
 However, some validations may be very complicated and not be achieved with flatfile validators. For such, use data hooks:  */
const validateRecord = (record) => {
  let {
    countryCode,
    city,
    state,
    zipCode,
    dob,
    ssn,
    emailAddress,
    transactionType,
    paymentInstructions,
    wireAch,
    abaRouting,
    wireAch2,
    wireAch3,
    ffcName,
    ffcNumber,
    accountType,
    checkPayableInfo,
    accountNumber,
    addressForCheck,
    cityForCheck,
    stateForCheck,
    zipForCheck,
    payerStateNumber,
  } = record
  if (countryCode.value === US_COUNTRY_VALUE) {
    if (zipCode.value) {
      const zipCodeRe = new RegExp('^[0-9]{5}(?:-[0-9]{4})?$')
      const postalCode = zipCode.value.substring(0, 5)
      if (!zipCodeRe.test(zipCode.value)) {
        zipCode.info = [
          {
            message: 'US Zip Codes must have format: XXXXX or XXXXX-XXXX',
            level: 'error',
          },
        ]
      } else if (invalidPostalCodes.includes(postalCode)) {
        zipCode.info = [
          {
            message: 'Please enter a valid Zip Code',
            level: 'error',
          },
        ]
      }
    } else {
      // city and state are required if zip code is missing
      if (!city.value) city.info = requiredError
      if (!state.value) state.info = requiredError
      // zip code is required if both city and state are missing
      if (!city.value && !state.value) zipCode.info = requiredError
    }
  } else {
    if (state.value.length > 22) {
      state.info = [
        {
          message: 'Maximum length exceeded - must be 22 characters or less',
          level: 'error',
        },
      ]
    }
  }

  if (dob.value) {
    const dobDate = new Date(dob.value)
    if (isValid(dobDate)) {
      const currentDate = new Date()
      const maxDate = new Date(currentDate)
      maxDate.setFullYear(currentDate.getFullYear() - 100)
      const minDate = new Date(currentDate)
      minDate.setFullYear(currentDate.getFullYear() - 10)
      if (dobDate > minDate) {
        dob.info = [{ message: 'Must be over 10 years.', level: 'error' }]
      } else {
        if (isBefore(dobDate, maxDate)) {
          dob.info = [{ message: 'Must be under 100 years.', level: 'error' }]
        }
      }
    } else {
      const dateRe = new RegExp('^[0-9]{4}/[0-9]{2}/[0-9]{2}$')
      dob.info = dateRe.test(dob.value)
        ? [
            {
              message: 'Must be a valid date', // e.g., "2000/22/44"
              level: 'error',
            },
          ]
        : [
            {
              message: 'Format must be yyyy/mm/dd',
              level: 'error',
            },
          ]
    }
  }

  if (ssn.value) {
    const ssnRe = new RegExp('^[0-9]{3}-[0-9]{2}-[0-9]{4}[a-zA-Z]?$')
    if (!ssnRe.test(ssn.value)) {
      ssn.info = [
        {
          message: 'Format must be XXX-XX-XXXX',
          level: 'error',
        },
      ]
    } else if (!validateTaxId(ssn.value)) {
      ssn.info = [
        {
          message: 'Given SSN is not valid',
          level: 'error',
        },
      ]
    }
  }

  if (emailAddress.value) {
    const schema = yup.string().email()
    const isValidEmail = schema.isValidSync(emailAddress.value)
    if (!isValidEmail) {
      emailAddress.info = [
        {
          message: 'Must be valid email format: example@email.com',
          level: 'error',
        },
      ]
    }
  }
  if (
    transactionType.value === BD_TRANSACTION_TYPES.ach &&
    paymentInstructions.value == BD_PAYMENT_INSTRUCTIONS.directRollover
  ) {
    paymentInstructions.info = [
      {
        message: 'Invalid if ACH & payment instructions is direct rollover',
        level: 'error',
      },
    ]
  }

  if (
    transactionType.value === BD_TRANSACTION_TYPES.wire ||
    transactionType.value === BD_TRANSACTION_TYPES.ach
  ) {
    if (!wireAch.value) {
      wireAch.info = [
        {
          message: 'Required if wire or ACH, blank if check',
          level: 'error',
        },
      ]
    }
    if (!abaRouting.value) {
      abaRouting.info = [
        {
          message: 'Required if wire or ACH, blank if check',
          level: 'error',
        },
      ]
    }
    if (!wireAch2.value) {
      wireAch2.info = [
        {
          message: 'Required if wire or ACH, blank if check',
          level: 'error',
        },
      ]
    }
    if (!wireAch3.value) {
      wireAch3.info = [
        {
          message: 'Required if wire or ACH, blank if check',
          level: 'error',
        },
      ]
    }
  }

  if (
    transactionType.value === BD_TRANSACTION_TYPES.wire &&
    paymentInstructions.value === BD_PAYMENT_INSTRUCTIONS.directRollover
  ) {
    if (!ffcName.value) {
      ffcName.info = [
        {
          message: 'Required for wire and direct rollover, blank if check',
          level: 'error',
        },
      ]
    }
    if (!ffcNumber.value) {
      ffcNumber.info = [
        {
          message: 'Required for wire and direct rollover, blank if check',
          level: 'error',
        },
      ]
    }
  }

  if (
    (transactionType.value === BD_TRANSACTION_TYPES.wire ||
      transactionType.value === BD_TRANSACTION_TYPES.ach) &&
    !accountType.value
  ) {
    accountType.info = [
      {
        message: 'Required if wire or ACH, blank if check',
        level: 'error',
      },
    ]
  }

  if (
    (transactionType.value === BD_TRANSACTION_TYPES.check ||
      transactionType.value === BD_TRANSACTION_TYPES.overnight) &&
    !checkPayableInfo.value
  ) {
    checkPayableInfo.info = [
      {
        message: 'Required when check, blank if wire or ACH',
        level: 'error',
      },
    ]
  }

  if (
    (transactionType.value === BD_TRANSACTION_TYPES.check &&
      paymentInstructions.value === BD_PAYMENT_INSTRUCTIONS.directRollover) ||
    (transactionType.value === BD_TRANSACTION_TYPES.overnight &&
      paymentInstructions.value === BD_PAYMENT_INSTRUCTIONS.directRollover)
  ) {
    if (!accountNumber.value) {
      accountNumber.info = [
        {
          message:
            'Required for check and direct rollover, blank for Wire or ACH',
          level: 'error',
        },
      ]
    }
  }

  if (
    (transactionType.value === BD_TRANSACTION_TYPES.check &&
      paymentInstructions.value === BD_PAYMENT_INSTRUCTIONS.directRollover) ||
    (transactionType.value === BD_TRANSACTION_TYPES.overnight &&
      paymentInstructions.value === BD_PAYMENT_INSTRUCTIONS.directRollover)
  ) {
    if (!addressForCheck.value) {
      addressForCheck.info = [
        {
          message:
            'Required when check and direct rollover, blank if wire or ACH',
          level: 'error',
        },
      ]
    }
    if (!cityForCheck.value) {
      cityForCheck.info = [
        {
          message:
            'Required when check and direct rollover, blank if wire or ACH',
          level: 'error',
        },
      ]
    }
    if (!stateForCheck.value) {
      stateForCheck.info = [
        {
          message:
            'Required when check and direct rollover, blank if wire or ACH',
          level: 'error',
        },
      ]
    }
    if (!zipForCheck.value) {
      zipForCheck.info = [
        {
          message:
            'Required when check and direct rollover, blank if wire or ACH',
          level: 'error',
        },
      ]
    }
  }
  if (zipForCheck.value) {
    const zipCodeRe = new RegExp('^[0-9]{5}(?:-[0-9]{4})?$')
    const postalCode = zipForCheck.value.substring(0, 5)
    if (!zipCodeRe.test(zipForCheck.value)) {
      zipForCheck.info = [
        {
          message: 'US Zip Codes must have format: XXXXX or XXXXX-XXXX',
          level: 'error',
        },
      ]
    } else if (invalidPostalCodes.includes(postalCode)) {
      zipForCheck.info = [
        {
          message: 'Please enter a valid Zip Code',
          level: 'error',
        },
      ]
    }
  }
  if (payerStateNumber.value) {
    const matchingState = findUSState(payerStateNumber.value)
    if (matchingState) {
      payerStateNumber.value = matchingState.value
    } else {
      payerStateNumber.info = [
        { message: 'Must be a valid US state', level: 'error' },
      ]
    }
  }
  // if (taxableAmount.value || employeeContributions.value) {
  //   const taxableAmountInput = removeCurrencySymbolAndParse(taxableAmount.value)
  //   const employeeContributionsInput = removeCurrencySymbolAndParse(
  //     employeeContributions.value
  //   )
  //   const grossDistributionInput = removeCurrencySymbolAndParse(
  //     grossDistribution.value
  //   )
  //   const total = taxableAmountInput + employeeContributionsInput
  //   if (total !== grossDistributionInput) {
  //     employeeContributions.info = [
  //       {
  //         message:
  //           'Employee contributions/designated Roth contributions or insurance premiums + Taxable amount must equal Gross distribution',
  //         level: 'error',
  //       },
  //     ]
  //     taxableAmount.info = [
  //       {
  //         message:
  //           'Employee contributions/designated Roth contributions or insurance premiums + Taxable amount must equal Gross distribution',
  //         level: 'error',
  //       },
  //     ]
  //   }
  // }
  // if (dob.value && distributionCode.value) {
  //   const regex = /[12]/
  //   const bRegex = /^(7|8|B|B[0-9])$/
  //   const matchRegex = regex.test(distributionCode.value)
  //   const matchRegexB = bRegex.test(distributionCode.value)
  // const calculateExactAge = (dob) => {
  //   const [year, month, day] = dob.split('/').map(Number)
  //   const dobDate = new Date(year, month - 1, day)
  //   const today = new Date()
  //   let years = today.getFullYear() - dobDate.getFullYear()
  //   let months = today.getMonth() - dobDate.getMonth()
  //   const age = years + months / 12
  //   return age
  // }
  //   const age = calculateExactAge(dob.value)
  //   if (matchRegex && age >= 59.5) {
  //     distributionCode.info = [
  //       {
  //         message: 'Participant must be younger than 59.5',
  //         level: 'error',
  //       },
  //     ]
  //   }
  //   if (matchRegexB && age <= 59.5) {
  //     distributionCode.info = [
  //       {
  //         message: ' Participant must be 59.5 or older',
  //         level: 'error',
  //       },
  //     ]
  //   }
  // }
  // if (employeeContributions.value && distributionCode.value) {
  //   const regex = /^B[0-9]?$/
  //   const matched = regex.test(distributionCode.value)
  //   if (!matched) {
  //     employeeContributions.info = [
  //       {
  //         message: 'Value not valid for distribution code entered',
  //         level: 'error',
  //       },
  //     ]
  //   }
  // }
  // if (stateTaxWithheld.value && distributionCode.value) {
  // const distributionCodeRegex = /^[GH]4?$/
  //   const matchedDistribution = distributionCodeRegex.test(
  //     distributionCode.value
  //   )
  //   const isBlank = blankRegex.test(stateTaxWithheld.value)
  //   if (matchedDistribution && !isBlank) {
  //     stateTaxWithheld.info = [
  //       {
  //         message: 'State tax withheld must be 0 for Distribution code entered',
  //         level: 'error',
  //       },
  //     ]
  //   }
  // }
  // if (federalIncomeTax.value && distributionCode.value) {
  //   const matchedDistribution = distributionCodeRegex.test(
  //     distributionCode.value
  //   )
  // const blankRegex = /^(0\.00%|)$/
  //   const isBlank = blankRegex.test(federalIncomeTax.value)
  //   if (matchedDistribution && !isBlank) {
  //     federalIncomeTax.info = [
  //       {
  //         message:
  //           'Federal income tax withheld must be 0 for Distribution code entered',
  //         level: 'error',
  //       },
  //     ]
  //   }
  //   const regex = /^([1247]|[A-FI-Z][1247])$/
  //   const matched = regex.test(distributionCode.value)
  //   const sanitizedInput = federalIncomeTax.value.replace('%', '')
  //   const parsedNumber = parseFloat(sanitizedInput)
  //   if (matched && parsedNumber < 20) {
  //     federalIncomeTax.info = [
  //       {
  //         message:
  //           'Federal income tax withheld must be greater than or equal to 20%',
  //         level: 'error',
  //       },
  //     ]
  //   }
  // }

  return record
}

export default formatAndValidateBDRecord
