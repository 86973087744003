import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import { isEmpty, groupBy, concat, reduce } from 'lodash'
import { Redirect, useHistory } from 'react-router-dom'
import { PlanMappingForm } from 'components'
import * as actions from '../actions'
import { getMatchesForPlans, sortSelectOptions } from 'utils'
import { COMPANY_NAME } from 'config/portal'
import {
  NO_SERVICES_AGREEMENT_OPTION,
  ReasonForDiscard,
} from 'config/automatic-rollovers'

const propTypes = {
  participantsAgreementHash: PropTypes.object,
  setParticipantsAgreementHash: PropTypes.func.isRequired,
  participantsForSubmission: PropTypes.arrayOf(Types.rolloverParticipant)
    .isRequired,
  completedServicesAgreements: PropTypes.arrayOf(
    Types.servicesAgreementSummary
  ),
  setDiscardedParticipants: PropTypes.func.isRequired,
  discardedParticipants: PropTypes.arrayOf(Types.rolloverParticipant)
    .isRequired,
  updateParticipantsWithMappedAgreement: PropTypes.func.isRequired,
}

const defaultProps = {
  participantsAgreementHash: null,
  completedServicesAgreements: null,
}

function PlanMappingView({
  setParticipantsAgreementHash,
  participantsAgreementHash,
  participantsForSubmission,
  completedServicesAgreements,
  setDiscardedParticipants,
  discardedParticipants,
  updateParticipantsWithMappedAgreement,
}) {
  if (isEmpty(participantsForSubmission) && isEmpty(discardedParticipants)) {
    return <Redirect to="/automatic-rollovers/upload-file" />
  }
  const history = useHistory()

  const servicesAgreementOptions = completedServicesAgreements?.map(
    (servicesAgreement) => ({
      value: servicesAgreement.serviceAgreementID,
      label: servicesAgreement.name,
    })
  )
  const participantHash = groupBy(participantsForSubmission, 'planName')

  // auto match each plan to an existing services agreement
  const servicesAgreementMatchesByPlan = getMatchesForPlans(
    participantHash,
    completedServicesAgreements
  )

  const initialValues =
    participantsAgreementHash ||
    reduce(
      participantHash,
      (acc, participants, planName) => {
        const { exactMatch } = servicesAgreementMatchesByPlan[planName]
        // Create a encoded name for each unique plan name to be used in the mapping form
        // This is necessary in case a name ends with a special
        // character such as a period (which may cause issues with form values)
        const encodedPlanName = window.btoa(planName)
        acc[encodedPlanName] = {
          planName,
          servicesAgreement: exactMatch?.serviceAgreementID || '',
          participants,
          ...servicesAgreementMatchesByPlan[planName],
        }
        return acc
      },
      {}
    )
  const total = Object.keys(servicesAgreementMatchesByPlan).length

  const handleSubmit = (values) => {
    setParticipantsAgreementHash(values)
    updateParticipantsWithMappedAgreement(values)

    const missingPlanParticipantsData = reduce(
      values,
      (missingPlanParticipants, { servicesAgreement, participants }) => {
        if (servicesAgreement === NO_SERVICES_AGREEMENT_OPTION.value) {
          return concat(missingPlanParticipants, participants)
        }
        return missingPlanParticipants
      },
      []
    )
    setDiscardedParticipants({
      participants: missingPlanParticipantsData,
      reasonForDiscard: ReasonForDiscard.MISSING_AGREEMENT,
    })

    history.push({
      pathname: '/automatic-rollovers/plan-confirmation',
      state: {
        search: history.location.state?.search,
      },
    })
  }

  return (
    <div className="card full-height upload-participant-file-view-container">
      <div className="form-masthead">
        <h2>Match your plans to agreements</h2>
        <p>
          We searched for possible matches between your plans and services
          agreements.{' '}
          <strong>
            Review the unmatched plans and select the corresponding services
            agreement on file with {COMPANY_NAME['short']}{' '}
          </strong>
          .
        </p>
      </div>
      <div
        className={`plan-mapping-container ${
          total > 10 ? 'scroll-enabled' : ''
        }`}
      >
        <div className="form-section">
          <PlanMappingForm
            formType={'ARO'}
            initialValues={initialValues}
            selectOptions={sortSelectOptions(servicesAgreementOptions, 'label')}
            onSubmit={handleSubmit}
            servicesAgreements={completedServicesAgreements}
          />
        </div>
      </div>
    </div>
  )
}

PlanMappingView.propTypes = propTypes
PlanMappingView.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    participantsForSubmission: selectors.participantsForSubmission(state),
    completedServicesAgreements: selectors.completedServicesAgreements(state),
    discardedParticipants: selectors.discardedParticipants(state),
    participantsAgreementHash: selectors.participantsAgreementHash(state),
  }
}

const mapDispatchToProps = {
  setParticipantsAgreementHash: actions.setParticipantsAgreementHash,
  updateParticipantsWithMappedAgreement:
    actions.updateParticipantsWithMappedAgreement,
  setDiscardedParticipants: actions.setDiscardedParticipants,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PlanMappingView
)
