import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { TableColumn } from 'lp-components'
import * as apiActions from 'api-actions'
import { selectors } from '../reducer'
import { compose } from 'redux'
import { connect } from 'react-redux'
import {
  Spinner,
  ComponentFailure,
  NoTableResults,
  PaginatedAndSortableTable,
  TableHeading,
} from 'components'
import AddNewUser from './AddNewUser'
import EditUser from './EditUser'
import * as actions from '../actions'
import * as Types from 'types'
import { userRoleOptions } from 'config/portal'
import { displayAttribute } from 'utils'

const propTypes = {
  companyUsers: PropTypes.arrayOf(Types.companyUser),
  fetchCompanyUsers: PropTypes.func.isRequired,
  triggerUsersFetch: PropTypes.bool.isRequired,
  setTriggerUsersFetch: PropTypes.func.isRequired,
}

const defaultProps = {
  companyUsers: null,
}

const roleDisplayNameMap = userRoleOptions.reduce((acc, option) => {
  acc[option.value] = option.key
  return acc
}, {})

function UsersDetailsHeader({ children }) {
  return <div className="users-details-header">{children}</div>
}

function UsersDetails({
  fetchCompanyUsers,
  companyUsers,
  triggerUsersFetch,
  setTriggerUsersFetch,
}) {
  const [error, setError] = useState(false)

  useEffect(() => {
    window.appEventData.pop()
    var appEventData = window.appEventData || []
    appEventData.push({
      event: 'Page Load Completed',
    })
  }, [])

  useEffect(() => {
    async function getCompanyUsers() {
      try {
        await fetchCompanyUsers()
      } catch {
        setError(true)
      }
      setTriggerUsersFetch(false)
    }
    if (triggerUsersFetch) getCompanyUsers()
  }, [triggerUsersFetch])

  const isLoading = !companyUsers && !error

  if (isLoading)
    return (
      <div className="user-details-container">
        <UsersDetailsHeader />
        <Spinner />
      </div>
    )
  if (error) return <ComponentFailure />

  return (
    <div className="user-details-container">
      <UsersDetailsHeader>
        <AddNewUser
          onSubmitSuccess={() => setTriggerUsersFetch(true)}
          buttonStyling="button-primary-outline add-new-user-btn"
        />
      </UsersDetailsHeader>
      {triggerUsersFetch && <Spinner />}
      <TableHeading title="User details" as="h1" />
      <PaginatedAndSortableTable
        tableId="users-details-table"
        data={companyUsers}
        initialColumn="name"
        noRowsFoundMessage={
          <NoTableResults
            title="No results found"
            details={
              <>
                It looks like you don't have any users yet.
                <br />
                To get started please select “Add New User”.
              </>
            }
          />
        }
      >
        <TableColumn name="name" />
        <TableColumn
          name="role"
          format={(val) => roleDisplayNameMap[val] ?? val}
          disabled
        />
        <TableColumn name="status" disabled />
        <TableColumn name="phone" format={displayAttribute} disabled />
        <TableColumn name="email" format={displayAttribute} disabled />
        <TableColumn
          name="actions"
          component={({ data }) => (
            <EditUser
              data={data}
              onSubmitSuccess={() => setTriggerUsersFetch(true)}
            />
          )}
          disabled
        />
      </PaginatedAndSortableTable>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    companyUsers: selectors.companyUsers(state),
    triggerUsersFetch: selectors.triggerUsersFetch(state),
  }
}

const mapDispatchToProps = {
  fetchCompanyUsers: apiActions.fetchCompanyUsers,
  setTriggerUsersFetch: actions.setTriggerUsersFetch,
}

UsersDetails.propTypes = propTypes

UsersDetails.defaultProps = defaultProps

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  UsersDetails
)
