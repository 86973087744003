import { US_STATES } from 'config/locations'

function findUSState(val) {
  const normalizedUserInput = val.toUpperCase().replace(/\s+/g, ' ').trim()

  return US_STATES.find(({ fullName, value }) => {
    const normalizedName = fullName.toUpperCase()
    return (
      normalizedName === normalizedUserInput || normalizedUserInput === value
    )
  })
}

export default findUSState
