import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import {
  Spinner,
  FullPageSpinner,
  ComponentFailure,
  TimeoutModal,
  QuickFilterCard,
  NoTableResults,
} from 'components'
import { PlanAgreementsTable, UploadPlanAgreementModal } from '../components'
import {
  sanitizeForSearch,
  parseDateFromString,
  isDateInRange,
  convertToSearchParams,
  useSearchParams,
} from 'utils'
import { selectors } from '../reducer'
import { selectors as globalSelectors } from 'global-reducer'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as apiActions from 'api-actions'
import { useLocation, useHistory } from 'react-router-dom'
import { isEqual, some, castArray } from 'lodash'
import {
  AroActionItems,
  BDActionItems,
  Path,
  AccountAudience,
  FLASH_SUCCESS_WITH_USER_ACTION_TIMEOUT,
  UserRole,
  isUncashedCheckExperienceEnabled,
} from 'config/portal'
import { BD_ADDENDUM_SUCCESS_MESSAGE } from 'config/notifications'
import { ServicesAgreementStatus } from 'config/automatic-rollovers'
import { ProductTypeTitles, ProductAgreementType } from 'config/products'
import * as actions from '../actions'
import {
  isBefore,
  isAfter,
  isSameDay,
  subDays,
  startOfToday,
  startOfDay,
} from 'date-fns'
import { Icon as CheckmarkIcon } from 'images/status-icons/checkmark.svg'
import { Icon as AlertIcon } from 'images/status-icons/alert.svg'
import { flashSuccessMessage } from 'redux-flash'
import { Icon as PlusIcon } from 'images/plus.svg'
const TABLE_ID = 'services-agreements-table'

const today = startOfToday()
const startOfRecentDate = subDays(today, 7) // recent being within the last 7 days
const ARO_FUNDING_INSTRUCTIONS_URL =
  process.env.REACT_APP_ARO_FUNDING_INSTRUCTIONS_URL

const INITIAL_FILTERS = {
  agreementStatus: [],
  initialInvestment: [],
  planSponsor: [],
  startLastUpdatedDate: '',
  endLastUpdatedDate: '',
}

/* --- Quick filters criteria --- */
// Recently Approved Agreements are Services Agreements with
// a portalAgreementStatus of Completed and a createdDate within the last 7 days
const RECENTLY_APPROVED_FILTERS = {
  ...INITIAL_FILTERS,
  agreementStatus: [ServicesAgreementStatus.COMPLETED],
  startLastUpdatedDate: startOfRecentDate,
  endLastUpdatedDate: today,
}

// Missing Signatures are Services Agreements with a portalAgreementStatus of Pending signatures
const MISSING_SIGNATURES_FILTERS = {
  ...INITIAL_FILTERS,
  agreementStatus: [ServicesAgreementStatus.PENDING_SIGNATURES],
}

const propTypes = {
  agreementsType: PropTypes.string.isRequired,
  fetchServiceAgreements: PropTypes.func.isRequired,
  servicesAgreements: PropTypes.arrayOf(Types.servicesAgreementSummary),
  fetchServicesAgreements: PropTypes.func.isRequired,
  shouldTriggerAllServicesAgreementsFetch: PropTypes.bool.isRequired,
  setShouldTriggerAllServicesAgreementsFetch: PropTypes.func.isRequired,
  currentUser: Types.user.isRequired,
  flashSuccessMessageHandler: PropTypes.func.isRequired,
  hasCompletedAddendum: PropTypes.bool.isRequired,
}

const defaultProps = {
  servicesAgreements: null,
}

function ServicesAgreementsHeader({ children, agreementsType }) {
  return (
    <div className="header-bar">
      <div className="header-bar-content">
        <div className="header-bar-page-title">
          <h1>
            {agreementsType === ProductAgreementType.BD
              ? ProductTypeTitles.BENEFIT_DISTRIBUTIONS
              : ProductTypeTitles.AUTOMATIC_ROLLOVERS}
          </h1>
          {agreementsType === ProductAgreementType.BD && (
            <p>
              Ensure the plan has an automatic rollover services agreement and
              benefit distributions addendum on file before initiating a
              distribution.
            </p>
          )}
        </div>
        {children}
      </div>
    </div>
  )
}

function TableComponentFailure() {
  return (
    <div className="content-wrapper all-plans">
      <ServicesAgreementsHeader />
      <div className="services-agreements-table-wrapper">
        <ComponentFailure />
      </div>
    </div>
  )
}

const getSuccessMessage = (isPartialSubmission) => {
  if (isPartialSubmission) {
    return `Your participant file has partially been submitted. Please provide any missing agreements or <a href=${ARO_FUNDING_INSTRUCTIONS_URL} target="_blank" rel="noreferrer noopener">view funding instructions.</a>`
  }

  return `We received your participant file, and we’ll send you an email when the participants are ready to be viewed in the platform. In the meantime,
  <a href=${ARO_FUNDING_INSTRUCTIONS_URL} target="_blank" rel="noreferrer noopener">view funding instructions.</a>`
}

function AllPlanAgreements({
  agreementsType,
  hasCompletedAddendum,
  fetchServiceAgreements,
  servicesAgreements,
  fetchServicesAgreements,
  shouldTriggerAllServicesAgreementsFetch,
  setShouldTriggerAllServicesAgreementsFetch,
  currentUser,
  flashSuccessMessageHandler,
}) {
  const location = useLocation()
  const history = useHistory()
  const searchParams = useSearchParams()
  const noServicesAgreements = !servicesAgreements?.length
  useEffect(() => {
    window.appEventData.pop()
    var appEventData = window.appEventData || []
    appEventData.push({
      event: 'Page Load Completed',
    })
  }, [])
  const [error, setError] = useState(false)
  const [selectedServicesAgreement, setSelectedServicesAgreement] =
    useState(null)
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [showTimeoutModal, setShowTimeoutModal] = useState(
    !!location.state?.showTimeoutModal
  )
  const [showAroSuccessModal, setShowAroSuccessModal] = useState(
    !!location.state?.showAroSuccessModal
  )

  const [showAddendumSuccessModal, setshowAddendumSuccessModal] = useState(
    !!location.state?.showAddendumSuccessModal
  )

  // Determines if the Automatic Rollover submission--which the user was redirected from--
  // was partial (i.e., at least one plan was missing an agreement) or complete (i.e., every plan was mapped to an agreement)
  // This information is only relevant when the component first mounts and the success modal is shown
  const isAroPartialSubmission = useMemo(
    () => !!location.state?.isPartialSubmission,
    []
  )

  useEffect(() => {
    // Reset location.state from ARO flow so the modal doesn't remain open when the page is refreshed
    history.replace(location.pathname + location.search + location.hash)

    async function getServicesAgreements() {
      try {
        await fetchServiceAgreements()
        await fetchServicesAgreements(agreementsType)
        setShouldTriggerAllServicesAgreementsFetch(false)
      } catch {
        setError(true)
      }
    }
    if (shouldTriggerAllServicesAgreementsFetch) getServicesAgreements()
  }, [shouldTriggerAllServicesAgreementsFetch])

  useEffect(() => {
    if (showAroSuccessModal) {
      const successMessage = getSuccessMessage(isAroPartialSubmission)
      flashSuccessMessageHandler(successMessage, {
        timeout: FLASH_SUCCESS_WITH_USER_ACTION_TIMEOUT,
      })
      setShowAroSuccessModal(false)
    }
  }, [
    showAroSuccessModal,
    flashSuccessMessageHandler,
    isAroPartialSubmission,
    setShowAroSuccessModal,
  ])

  useEffect(() => {
    if (showAddendumSuccessModal) {
      flashSuccessMessageHandler(BD_ADDENDUM_SUCCESS_MESSAGE, {
        timeout: FLASH_SUCCESS_WITH_USER_ACTION_TIMEOUT,
      })
      setshowAddendumSuccessModal(false)
    }
  }, [
    showAddendumSuccessModal,
    flashSuccessMessageHandler,
    setshowAddendumSuccessModal,
  ])

  const filters = useMemo(() => {
    let initialFilters = { ...INITIAL_FILTERS }
    const {
      agreementStatus,
      initialInvestment,
      planSponsor,
      startLastUpdatedDate,
      endLastUpdatedDate,
    } = searchParams

    if (agreementStatus)
      initialFilters.agreementStatus = castArray(agreementStatus)
    if (initialInvestment)
      initialFilters.initialInvestment = castArray(initialInvestment)
    if (planSponsor) initialFilters.planSponsor = castArray(planSponsor)
    if (startLastUpdatedDate)
      initialFilters.startLastUpdatedDate = startOfDay(
        parseDateFromString(startLastUpdatedDate)
      )
    if (endLastUpdatedDate)
      initialFilters.endLastUpdatedDate = startOfDay(
        parseDateFromString(endLastUpdatedDate)
      )

    return initialFilters
  }, [searchParams])

  const searchValue = searchParams.q ?? ''

  const updateSearchParams = (filters, searchValue) => {
    history.push({
      pathname: location.pathname,
      search: convertToSearchParams({
        ...filters,
        q: searchValue,
      }),
    })
  }

  // User can search services agreements by plan name, sponsor name, and plan EIN
  const filteredServicesAgreements = useMemo(() => {
    if (!servicesAgreements) return

    const filteredServicesAgreements = servicesAgreements.filter(
      ({
        portalAgreementStatus,
        portalAgreementType,
        planSponsorCompany,
        createdDate,
      }) => {
        const {
          agreementStatus,
          initialInvestment,
          planSponsor,
          startLastUpdatedDate,
          endLastUpdatedDate,
        } = filters
        const lastUpdatedDate = parseDateFromString(createdDate)
        return (
          (agreementStatus.length
            ? agreementStatus.includes(portalAgreementStatus)
            : true) &&
          (initialInvestment.length
            ? initialInvestment.includes(portalAgreementType)
            : true) &&
          (planSponsor.length
            ? planSponsor.includes(planSponsorCompany.name)
            : true) &&
          (startLastUpdatedDate
            ? isAfter(lastUpdatedDate, startLastUpdatedDate) ||
              isSameDay(lastUpdatedDate, startLastUpdatedDate)
            : true) &&
          (endLastUpdatedDate
            ? isBefore(lastUpdatedDate, endLastUpdatedDate) ||
              isSameDay(lastUpdatedDate, endLastUpdatedDate)
            : true)
        )
      }
    )

    // ignore hyphens and case
    const sanitizedValue = sanitizeForSearch(searchValue)
    if (sanitizedValue === '') return filteredServicesAgreements
    if (currentUser.accountAudience === AccountAudience.PLAN_FIDUCIARY) {
      return filteredServicesAgreements.filter(({ name }) =>
        sanitizeForSearch(name).includes(sanitizedValue)
      )
    }

    return filteredServicesAgreements.filter(
      ({ name, planSponsorCompany, planEIN }) =>
        sanitizeForSearch(name).includes(sanitizedValue) ||
        (planSponsorCompany.name &&
          sanitizeForSearch(planSponsorCompany.name).includes(
            sanitizedValue
          )) ||
        (planEIN && sanitizeForSearch(planEIN).includes(sanitizedValue))
    )
  }, [filters, searchValue, servicesAgreements])

  const isLoading = !servicesAgreements && !error

  if (isLoading) return <FullPageSpinner />
  if (error) return <TableComponentFailure />

  const hasCompletedServicesAgreement = some(servicesAgreements, [
    'portalAgreementStatus',
    ServicesAgreementStatus.COMPLETED,
  ])

  const handleAddPlanAgreementAction = () =>
    history.push({
      pathname:
        agreementsType === ProductAgreementType.BD
          ? Path.ADD_NEW_BD_SERVICES_AGREEMENT
          : Path.ADD_NEW_SERVICES_AGREEMENT,
      state: {
        search: history.location.search,
      },
    })

  const handleAddNewAddendumAction = () =>
    history.push({
      pathname: Path.ADD_NEW_BD_ADDENDUM,
      state: {
        ignorePrompt: true,
      },
    })

  /**
   * @description Handles onClick action for when the user clicks on ***Initiate Rollover*** or ***Initiate Distribution*** button
   */
  const handleInitiateParticipantUploadAction = () =>
    history.push({
      pathname:
        agreementsType === ProductAgreementType.ARO
          ? isUncashedCheckExperienceEnabled()
            ? '/automatic-rollovers/select-rollover-type'
            : '/automatic-rollovers/upload-file'
          : '/benefit-distributions/select-payment-method',
      state: {
        hasConfirmed: true,
        search: location.search,
      },
    })

  // Quick filters counter
  const missingSignaturesCount = servicesAgreements.reduce(
    (acc, { portalAgreementStatus }) => {
      if (portalAgreementStatus === ServicesAgreementStatus.PENDING_SIGNATURES)
        acc++
      return acc
    },
    0
  )
  const recentlyApprovedAgreementsCount = servicesAgreements.reduce(
    (acc, { portalAgreementStatus, createdDate }) => {
      const isRecent = isDateInRange({
        date: createdDate,
        startOfDateRange: startOfRecentDate,
        endOfDateRange: today,
      })
      if (
        portalAgreementStatus === ServicesAgreementStatus.COMPLETED &&
        isRecent
      )
        acc++
      return acc
    },
    0
  )
  const fieldMappings = [
    { field: 'planSponsorCompany.name', displayName: 'Plan Sponsor Name' },
    { field: 'planEIN', displayName: 'EIN' },
    { field: 'name', displayName: 'Complete Legal Plan Name' },
    { field: 'portalAgreementType', displayName: 'Initial Investment' },
    { field: 'portalAgreementStatus', displayName: 'Agreement Status' },
    { field: 'portalAgreementDate', displayName: 'Date Created' },
  ]
  const excludePlanSponsorAndEin =
    currentUser.accountAudience === 'Plan Fiduciary'
  const filteredFieldMappings = excludePlanSponsorAndEin
    ? fieldMappings.slice(2)
    : fieldMappings

  const productActionItems = () => {
    if (agreementsType === ProductAgreementType.ARO) {
      return (
        <>
          <button
            type="button"
            onClick={() => handleAddPlanAgreementAction()}
            className="button-text"
          >
            <PlusIcon aria-hidden="true" />
            {AroActionItems.CREATE_NEW_SERVICES_AGREEMENT}
          </button>
          {hasCompletedServicesAgreement && (
            <button
              type="button"
              onClick={() => handleInitiateParticipantUploadAction()}
              className="button-primary"
            >
              {AroActionItems.INITIATE_ROLLOVER}
            </button>
          )}
        </>
      )
    } else if (agreementsType === ProductAgreementType.BD) {
      return (
        <>
          <button
            type="button"
            onClick={handleAddNewAddendumAction}
            className="button-text"
          >
            <PlusIcon aria-hidden="true" />
            {BDActionItems.CREATE_NEW_ADDENDUM}
          </button>
          {hasCompletedAddendum && (
            <button
              type="button"
              onClick={() => handleInitiateParticipantUploadAction()}
              className="button-primary"
            >
              {BDActionItems.INITIATE_DISTRIBUTION}
            </button>
          )}
        </>
      )
    }
  }

  return (
    <div className="content-wrapper all-plans">
      <ServicesAgreementsHeader agreementsType={agreementsType}>
        <div className="services-agreements-actions">
          {productActionItems()}
        </div>
      </ServicesAgreementsHeader>
      {agreementsType === ProductAgreementType.ARO && (
        <div className="quick-filters-container">
          <div className="flex-columns">
            <div className="flex-column">
              <QuickFilterCard
                controlId={TABLE_ID}
                isActive={isEqual(filters, RECENTLY_APPROVED_FILTERS)}
                count={recentlyApprovedAgreementsCount}
                label="Recently approved agreements"
                icon={CheckmarkIcon}
                onClick={() => {
                  if (!isEqual(filters, RECENTLY_APPROVED_FILTERS))
                    updateSearchParams(RECENTLY_APPROVED_FILTERS, searchValue)
                  else updateSearchParams(INITIAL_FILTERS, searchValue)
                }}
              />
            </div>
            {(currentUser.accountAudience !== AccountAudience.PLAN_FIDUCIARY ||
              (currentUser.accountAudience === AccountAudience.PLAN_FIDUCIARY &&
                currentUser.retirementServicesPortalProfile ===
                  UserRole.ADMIN)) && (
              <div className="flex-column">
                <QuickFilterCard
                  controlId={TABLE_ID}
                  isActive={isEqual(filters, MISSING_SIGNATURES_FILTERS)}
                  count={missingSignaturesCount}
                  label="Missing signatures"
                  icon={AlertIcon}
                  onClick={() => {
                    if (!isEqual(filters, MISSING_SIGNATURES_FILTERS))
                      updateSearchParams(
                        MISSING_SIGNATURES_FILTERS,
                        searchValue
                      )
                    else updateSearchParams(INITIAL_FILTERS, searchValue)
                  }}
                />
              </div>
            )}
          </div>
        </div>
      )}
      {shouldTriggerAllServicesAgreementsFetch && <Spinner />}
      <PlanAgreementsTable
        agreementsType={agreementsType}
        currentUser={currentUser}
        filters={filters}
        tableId="services-agreements-table"
        servicesAgreements={filteredServicesAgreements}
        searchValue={searchValue}
        noServicesAgreements={noServicesAgreements}
        fieldMappings={
          excludePlanSponsorAndEin ? filteredFieldMappings : fieldMappings
        }
        noRowsFoundMessage={
          agreementsType !== ProductAgreementType.BD ? (
            <NoTableResults
              title={
                noServicesAgreements
                  ? 'You don’t have any service agreements at this time.'
                  : 'No results found.'
              }
              titleAs="h2"
              details={
                noServicesAgreements
                  ? 'Need to create a new agreement? You can do that now.'
                  : 'Try adjusting your filter or search.'
              }
            >
              {noServicesAgreements && (
                <button
                  type="button"
                  className="button-primary"
                  onClick={handleAddPlanAgreementAction}
                >
                  {AroActionItems.CREATE_NEW_SERVICES_AGREEMENT}
                </button>
              )}
            </NoTableResults>
          ) : (
            <NoTableResults
              title={
                !hasCompletedAddendum
                  ? 'You don’t have any addendums at this time.'
                  : 'No results found.'
              }
              titleAs="h2"
              details={
                !hasCompletedAddendum
                  ? 'Need to create a new addendum? You can do that now.'
                  : 'Try adjusting your filter or search.'
              }
            >
              {!hasCompletedAddendum && (
                <button
                  type="button"
                  className="button-primary"
                  onClick={handleAddNewAddendumAction}
                >
                  {BDActionItems.CREATE_NEW_ADDENDUM}
                </button>
              )}
            </NoTableResults>
          )
        }
        handleUploadClick={(servicesAgreement) => {
          setSelectedServicesAgreement(servicesAgreement)
          setShowUploadModal(true)
        }}
      />
      {showUploadModal && (
        <UploadPlanAgreementModal
          onClose={() => {
            setShowUploadModal(false)
            setSelectedServicesAgreement(null)
          }}
          servicesAgreement={selectedServicesAgreement}
          onSubmitSuccess={() => {
            setShowUploadModal(false)
            setSelectedServicesAgreement(null)
            setShouldTriggerAllServicesAgreementsFetch(true)
          }}
        />
      )}
      {showTimeoutModal && (
        <TimeoutModal onClose={() => setShowTimeoutModal(false)} />
      )}
    </div>
  )
}

function mapStateToProps(state) {
  return {
    servicesAgreements: selectors.servicesAgreements(state),
    shouldTriggerAllServicesAgreementsFetch:
      selectors.shouldTriggerAllServicesAgreementsFetch(state),
    currentUser: globalSelectors.currentUser(state),
    hasCompletedAddendum: globalSelectors.hasCompletedAddendum(state),
  }
}

const mapDispatchToProps = {
  fetchServiceAgreements: apiActions.fetchServiceAgreements,
  fetchServicesAgreements: apiActions.fetchServicesAgreements,
  setShouldTriggerAllServicesAgreementsFetch:
    actions.setShouldTriggerAllServicesAgreementsFetch,
  flashSuccessMessageHandler: flashSuccessMessage,
}

AllPlanAgreements.propTypes = propTypes
AllPlanAgreements.defaultProps = defaultProps

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AllPlanAgreements
)
