import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { Icon as CompanyIcon } from 'images/company-outline.svg'
import { Icon as UsersIcon } from 'images/users-outline.svg'

const propTypes = {
  children: PropTypes.node.isRequired,
}

const defaultProps = {}

function Layout({ children }) {
  return (
    <div className="content-wrapper company-layout">
      <aside>
        <ul className="card company-nav">
          <li>
            <NavLink to="/company/details" className="company-link">
              <CompanyIcon aria-hidden="true" />
              Company details
            </NavLink>
          </li>
          <li>
            <NavLink to="/company/users" className="company-link">
              <UsersIcon aria-hidden="true" />
              User details
            </NavLink>
          </li>
        </ul>
      </aside>
      {children}
    </div>
  )
}

Layout.propTypes = propTypes

Layout.defaultProps = defaultProps

export default Layout
