import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { AddNewAddendumForm } from '../forms'
import { useHistory } from 'react-router-dom'
import * as apiActions from 'api-actions'
import { selectors } from '../../reducer'
import { selectors as productSelectors } from '../../../products/reducer'
import * as actions from '../../actions'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Path } from 'config/portal'
import { FlowActions } from '../components'

const propTypes = {
  completedServicesAgreements: PropTypes.arrayOf(
    Types.servicesAgreementSummary
  ),
  fetchCompletedServicesAgreements: PropTypes.func.isRequired,
  addendumServicesAgreementHash: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  setAddendumServicesAgreementHash: PropTypes.func.isRequired,
}

const defaultProps = {
  addendumServicesAgreementHash: null,
  completedServicesAgreements: null,
}

function AddNewAddendumView({
  fetchCompletedServicesAgreements,
  completedServicesAgreements,
  addendumServicesAgreementHash,
  setAddendumServicesAgreementHash,
}) {
  useEffect(() => {
    if (!completedServicesAgreements) {
      fetchCompletedServicesAgreements()
    }
  }, [])

  const history = useHistory()

  const servicesAgreementOptions = completedServicesAgreements?.map(
    (servicesAgreement) => ({
      value: servicesAgreement.serviceAgreementID,
      label: servicesAgreement.name,
    })
  )

  const [newAddendumServicesAgreement, setNewAddendumServicesAgreement] =
    useState('')

  const [agreementErrorState, setAgreementErrorState] = useState(false)

  const initialValues =
    servicesAgreementOptions || addendumServicesAgreementHash

  const handleSubmit = () => {
    if (newAddendumServicesAgreement !== '') {
      setAddendumServicesAgreementHash(newAddendumServicesAgreement)
      setAgreementErrorState(false)
      history.push({
        pathname: Path.REVIEW_AND_SUBMIT_BD_ADDENDUM,
        state: {
          search: history.location.state?.search,
        },
      })
    } else {
      setAgreementErrorState(true)
    }
  }

  const handleAddPlanAgreementAction = () =>
    history.push({
      pathname: Path.ADD_NEW_SERVICES_AGREEMENT,
    })

  return (
    <div className="card full-height complete-services-agreement-view">
      <div className="complete-services-agreement-form-container">
        <div className="form-masthead">
          <h2>Start by selecting a services agreement</h2>
        </div>
        <p className="form-masthead-text">
          Choose the services agreement you’d like to add a benefit
          distributions addendum to. Keep in mind, you can only add addendums to
          services agreements that have been completed and approved.
        </p>
        <div className="form-section">
          {initialValues && (
            <AddNewAddendumForm
              initialValues={initialValues}
              onSubmit={handleSubmit}
              setNewAddendumServicesAgreement={setNewAddendumServicesAgreement}
              servicesAgreements={completedServicesAgreements}
              agreementErrorState={agreementErrorState}
            />
          )}
        </div>
        <div className="addendum-new-agreement-section">
          <div className="addendum-new-agreement-text">
            Haven’t created a services agreement yet?
          </div>
          <div
            onClick={() => handleAddPlanAgreementAction()}
            className="addendum-new-agreement-link"
          >
            Get started.
          </div>
        </div>
        <FlowActions handleSubmit={handleSubmit} preventBack />
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    completedServicesAgreements: selectors.completedServicesAgreements(state),
    addendumServicesAgreementHash:
      selectors.addendumServicesAgreementHash(state),
    servicesAgreements: productSelectors.servicesAgreements(state),
    shouldTriggerAllServicesAgreementsFetch:
      productSelectors.shouldTriggerAllServicesAgreementsFetch(state),
  }
}

const mapDispatchToProps = {
  setCompleteServicesAgreementForm: actions.setCompleteServicesAgreementForm,
  resetErisapediaSearches: actions.resetErisapediaSearches,
  setAddendumServicesAgreementHash: actions.setAddendumServicesAgreementHash,
  fetchCompletedServicesAgreements: apiActions.fetchCompletedServicesAgreements,
}

AddNewAddendumView.propTypes = propTypes
AddNewAddendumView.defaultProps = defaultProps

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AddNewAddendumView
)
