import React from 'react'
import { withFormikAdapter } from 'utils'
import DropdownSelect from './DropdownSelect'
import {
  CheckboxGroup,
  checkboxGroupPropTypes,
  fieldOptionsType,
  InputLabel,
} from 'lp-components'

const propTypes = {
  ...checkboxGroupPropTypes,
  options: fieldOptionsType,
}

function DropdownCheckboxGroup(props) {
  const {
    input: { name, value },
    label,
  } = props
  return (
    <fieldset>
      <InputLabel {...{ label, name }} />
      <DropdownSelect selectedValues={value} className="checkboxes">
        <CheckboxGroup {...props} label={false} />
      </DropdownSelect>
    </fieldset>
  )
}

DropdownCheckboxGroup.propTypes = propTypes

export default withFormikAdapter()(DropdownCheckboxGroup)
