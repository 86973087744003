import { toNumber } from 'lodash'

// Convert a currency string (e.g., "$2,800.50") to a number (e.g., 2800.5)
function convertNumber(val) {
  const sanitizedInput = val?.replaceAll(/[$,]/g, '')
  const coercedValue = toNumber(sanitizedInput)
  return isFinite(coercedValue) ? coercedValue : 0
}

export default convertNumber
