import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { SubmitButton, Button } from 'lp-components'
import { Link, useHistory } from 'react-router-dom'
import { noop } from 'lodash'
import { Icon as ArrowRightIcon } from 'images/arrow-right.svg'
import { Icon as ArrowLeftIcon } from 'images/arrow-left.svg'

const propTypes = {
  handleSubmit: PropTypes.func,
  handleBack: PropTypes.func,
  exitPath: PropTypes.string.isRequired,
  submitContent: PropTypes.node,
  isSubmitting: PropTypes.bool,
  preventBack: PropTypes.bool,
}

const defaultProps = {
  handleSubmit: noop,
  preventBack: false,
  submitContent: 'Continue',
  isSubmitting: false,
}

function FlowActions({
  exitPath,
  handleSubmit,
  handleBack,
  submitContent,
  isSubmitting,
  preventBack,
}) {
  const history = useHistory()

  return (
    <div className="flow-button-container ">
      <div className="flow-button-left">
        <Link
          className="button-text"
          to={(location) => {
            return {
              pathname: exitPath,
              search: location.state?.search,
            }
          }}
        >
          Cancel
        </Link>
        {!preventBack && (
          <Button
            className="button-primary-outline"
            onClick={(e) => {
              e.preventDefault()
              handleBack ? handleBack() : history.goBack()
            }}
            variant="text"
          >
            <ArrowLeftIcon aria-hidden="true" /> Back
          </Button>
        )}
      </div>
      <div className="flow-button-right">
        <SubmitButton onClick={handleSubmit} submitting={isSubmitting}>
          {submitContent}{' '}
          <ArrowRightIcon aria-hidden="true" className="icon-continue" />
        </SubmitButton>
      </div>
    </div>
  )
}

FlowActions.propTypes = exact(propTypes)
FlowActions.defaultProps = defaultProps

export default React.memo(FlowActions)
