import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { FlowActions } from '../../products/components'
import { Formik } from 'formik'
import * as Yup from 'yup'
import {
  sortedPlanTypeOptions,
  planTypeValues,
  ServicesAgreementPlanType,
  sortedPlanStatusOptions,
  planStatusValues,
  ServicesAgreementPlanStatus,
} from 'config/automatic-rollovers'
import {
  Notification,
  Select,
  ScrollErrorForm,
  HiddenLabel,
  ConfirmationCheckbox,
} from 'components'
import { Variant as NotificationVariant } from 'components/Notification'
import { reduce, concat, omit } from 'lodash'
import { sortSelectOptions } from 'utils'

const propTypes = {
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

const defaultProps = {}

const PlanConfirmationForm = React.forwardRef(function PlanConfirmationForm(
  { initialValues, onSubmit },
  ref
) {
  const plansInitialValues = omit(initialValues, 'confirm')

  const tableData = sortSelectOptions(
    reduce(
      plansInitialValues,
      (data, { planName, planStatus, planType }, id) => {
        const row = {
          planName,
          planStatus,
          planType,
          id,
        }
        return concat(data, row)
      },
      []
    ),
    'planName'
  )

  const confirmValidation = {
    confirm: Yup.boolean().required('Required').oneOf([true], 'Required'),
  }

  const validationSchema = Yup.object(
    reduce(
      plansInitialValues,
      (schema, _, id) => {
        schema[id] = Yup.object({
          planType: Yup.string()
            .oneOf(planTypeValues, 'Must be a valid plan type')
            .required('Required'),
          planStatus: Yup.string()
            .oneOf(planStatusValues, 'Must be a valid plan status')
            .required('Required'),
        })
        return schema
      },
      confirmValidation
    )
  )

  return (
    <div className="workflow-card-container plan-confirmation">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        innerRef={ref}
      >
        {({ isSubmitting, values }) => (
          <ScrollErrorForm>
            <div className="form-masthead">
              <h2>Confirm your plan information</h2>
              <p>
                Review your plan’s type and status and make any necessary
                changes.
              </p>
            </div>
            <div className="form-section form-section--no-bottom-margin">
              <div className="form-header">
                <h3>Plan information</h3>
              </div>
              <div className="plan-information">
                <div className="plan-information-table-container">
                  <div className="table-header">
                    <div className="table-cell">Plan name</div>
                    <div className="table-cell">Plan type</div>
                    <div className="table-cell">Plan status</div>
                  </div>
                  {tableData.map((row) => {
                    const isDefinedBenefit =
                      values[row.id].planType ===
                      ServicesAgreementPlanType.DEFINED_BENEFIT
                    const isTerminating =
                      values[row.id].planStatus ===
                      ServicesAgreementPlanStatus.TERMINATING

                    return (
                      <div key={row.id}>
                        <div className="table-row">
                          <div className="table-cell">{row.planName}</div>
                          <div className="table-cell">
                            <Select
                              name={`${row.id}[planType]`}
                              label="Plan type"
                              placeholder="Select plan type"
                              options={sortedPlanTypeOptions}
                              labelComponent={HiddenLabel}
                            />
                          </div>
                          <div className="table-cell">
                            <Select
                              name={`${row.id}[planStatus]`}
                              label="Plan status"
                              placeholder="Select plan status"
                              options={sortedPlanStatusOptions}
                              labelComponent={HiddenLabel}
                            />
                          </div>
                        </div>
                        {isDefinedBenefit && isTerminating && (
                          <Notification type={NotificationVariant.WARNING}>
                            Generally, participants of terminating defined
                            benefit plans are not eligible for rollover, unless
                            the plan is not covered by the Pension Benefit
                            Guaranty Corporation (PBGC). It is the plan
                            administrator's responsibility to determine whether
                            the plan is covered by the PBGC and whether
                            participant accounts are eligible for rollover.
                          </Notification>
                        )}
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
            <div className="form-section">
              <div className="form-header">
                <h3>Acknowledgements</h3>
              </div>
              <div className="acknowledgements">
                <ConfirmationCheckbox
                  name="confirm"
                  label="By checking this box, I confirm the plan type and status above are correct."
                />
              </div>
            </div>
            <FlowActions isSubmitting={isSubmitting} />
          </ScrollErrorForm>
        )}
      </Formik>
    </div>
  )
})

PlanConfirmationForm.propTypes = exact(propTypes)
PlanConfirmationForm.defaultProps = defaultProps

export default React.memo(PlanConfirmationForm)
