export const AgreementStatus = {
  ACTIVE: 'Active',
  ACCEPTED: 'Accepted',
  NEGATIVE_CONSENT: 'Negative Consent',
  SIGNED: 'Signed',
  PENDING: 'Pending',
  PENDING_DOCUSIGN_SIGNATURE: 'Pending Docusign Signature',
  IN_REVIEW: 'In Review',
  CANCELED: 'Canceled',
  MISSING: 'Missing',
}

export const AgreementStatusForTypes = {
  ACTIVE: 'Active',
  ACCEPTED: 'Accepted',
  NEGATIVE_CONSENT: 'Negative Consent',
  SIGNED: 'Signed',
  PENDING: 'Pending',
  PENDING_DOCUSIGN_SIGNATURE: 'Pending Docusign Signature',
  IN_REVIEW: 'In Review',
  CANCELED: 'Canceled',
  MISSING: 'Missing',
  DEFAULT: '',
}

export const AVAILABLE_STATUSES = [
  AgreementStatus.ACTIVE,
  AgreementStatus.ACCEPTED,
  AgreementStatus.NEGATIVE_CONSENT,
  AgreementStatus.SIGNED,
]
export const ProductType = {
  AUTOMATIC_ROLLOVERS: 'Automatic rollovers',
  BENEFIT_DISTRIBUTIONS: 'Benefit distributions',
  SEARCH_SERVICES: 'Search services',
  UNCASHED_CHECKS: 'Uncashed checks',
}

export const ProductTypeTitles = {
  AUTOMATIC_ROLLOVERS: 'Automatic rollover services agreements',
  BENEFIT_DISTRIBUTIONS: 'Benefit distributions',
  SEARCH_SERVICES: 'Search services',
  UNCASHED_CHECKS: 'Uncashed checks',
}

export const ORDERED_PRODUCTS = [
  ProductType.AUTOMATIC_ROLLOVERS,
  ProductType.UNCASHED_CHECKS,
  ProductType.SEARCH_SERVICES,
  ProductType.BENEFIT_DISTRIBUTIONS,
]
// list of products that are always enabled, overriding service agreement conditions
export const ALWAYS_ENABLED_PRODUCTS = [
  ProductType.AUTOMATIC_ROLLOVERS,
  ProductType.SEARCH_SERVICES,
  ProductType.BENEFIT_DISTRIBUTIONS,
]
export const AgreementType = {
  ANNUITY: 'Annuity',
  ARP: 'ARP',
  ARP_PGF: 'ARP PGF',
  ARP_SAF: 'ARP SAF',
  BENEFIT_DISTRIBUTION: 'Benefit Distribution',
  CUSTODIAL: 'Custodial',
  CUSTODIAL_WIND_UP: 'Custodial - Wind Up',
  DIRECT_ROLLOVER: 'Direct Rollover',
  EMERGENCY_SAVINGS_ACCOUNT: 'Emergency Savings Account',
  LNFI: 'LNFI (Lincoln Financial)',
  NOTIFICATION: 'Notification',
  QTA: 'QTA',
  RESIGN_AND_APPOINT: 'Resign and Appoint',
  SEARCH: 'Search',
  SIMPLE_IRA_PLAN: 'SIMPLE IRA Plan',
  TRS: 'TRS',
  UNCASHED_CHECKS: 'Un-Cashed Checks',
  VALG: 'VALG (Valic)',
  WPS_COLLABORATION: 'WPS Collaboration',
  WPS_EMERGENCY_SAVINGS: 'WPS Emergency Savings',
  WPS_PAYROLL_DEDUCTED: 'WPS Payroll Deducted',
  WPS_SEP_IRA_PLAN: 'WPS SEP IRA Plan',
  WPS_SIMPLE_IRA_PLAN: 'WPS SIMPLE IRA Plan',
  NATW: 'NATW (Nationwide)',
}

export const ServiceAgreementMapping = {
  [AgreementType.ANNUITY]: null,
  [AgreementType.ARP]: ProductType.AUTOMATIC_ROLLOVERS,
  [AgreementType.ARP_PGF]: ProductType.AUTOMATIC_ROLLOVERS,
  [AgreementType.ARP_SAF]: ProductType.AUTOMATIC_ROLLOVERS,
  [AgreementType.CUSTODIAL]: null,
  [AgreementType.CUSTODIAL_WIND_UP]: null,
  [AgreementType.DIRECT_ROLLOVER]: null,
  [AgreementType.LNFI]: ProductType.AUTOMATIC_ROLLOVERS,
  [AgreementType.NOTIFICATION]: null,
  [AgreementType.QTA]: ProductType.AUTOMATIC_ROLLOVERS,
  [AgreementType.RESIGN_AND_APPOINT]: ProductType.AUTOMATIC_ROLLOVERS,
  [AgreementType.SEARCH]: ProductType.SEARCH_SERVICES,
  [AgreementType.TRS]: ProductType.AUTOMATIC_ROLLOVERS,
  [AgreementType.VALG]: ProductType.AUTOMATIC_ROLLOVERS,
  [AgreementType.NATW]: ProductType.AUTOMATIC_ROLLOVERS,
}

export const invalidPostalCodes = [
  '01234',
  '34567',
  '56789',
  '67890',
  '00000',
  '11111',
  '22222',
  '33333',
  '55555',
  '66666',
  '77777',
  '99999',
]

export const ProductAgreementType = {
  ARO: 'Automatic Rollover',
  BD: 'Benefit Distributions',
  SEARCH: 'Search Services',
  UNCASHED_CHECKS: 'Uncashed Checks',
}

export const PlanAgreementsTableHeaders = {
  ARO: 'My services agreements',
  BD: 'My plans',
}
