import React from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route, Switch } from 'react-router-dom'
import * as Views from './views'
import Layout from './Layout'
import { Path, isSearchPaymentEnabled } from 'config/portal'

const propTypes = {
  match: PropTypes.object.isRequired,
}

const defaultProps = {}

function Routes({ match: { path } }) {
  window.appEventData.pop()
  var appEventData = window.appEventData || []
  appEventData.push({
    event: 'Page Load Completed',
  })
  return (
    <Layout>
      <Switch>
        <Route exact path={path + '/details'} component={Views.DetailsView} />
        {isSearchPaymentEnabled() ? (
          <Route
            path={Path.ADD_PAYMENT_DETAILS}
            component={Views.PaymentDetails}
          />
        ) : null}
        <Route exact path={path + '/password'} component={Views.PasswordView} />
        <Redirect path="*" to={path + '/details'} />
      </Switch>
    </Layout>
  )
}

Routes.propTypes = propTypes
Routes.defaultProps = defaultProps

export default Routes
