import React from 'react'
import Routes from './Routes'
import { Route } from 'react-router-dom'
import '../../scss/application.scss'
import history from 'browser-history'
import store from 'store'
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import * as chameleon from '@chamaeleonidae/chmln'

// Log runtime errors with sentry service
import 'sentry'

// Initialize fullstory analytics tracking
import 'fullstory'

// Top-level wrapper initializes redux and react-router.
function App() {
  chameleon.init(process.env.REACT_APP_CHAMELEON_PK, {
    fastUrl: 'https://fast.chameleon.io/',
  })
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Route component={Routes} />
      </ConnectedRouter>
    </Provider>
  )
}

export default App
