import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Modal } from 'lp-components'

const propTypes = {
  onClose: PropTypes.func.isRequired,
}

const defaultProps = {}

function TimeoutModal({ onClose }) {
  return (
    <Modal id="timeout-modal" onClose={onClose}>
      <h2>Processing agreement</h2>

      <p>
        Processing your agreement submission is taking longer than usual. Please
        wait a few moments and refresh to see your agreement on the Services
        Agreement list. If it does not appear after 3 minutes, please resubmit.
        We apologize for any inconvenience.
      </p>

      <div className="timeout-modal-actions">
        <button
          className="button-primary timeout-modal_close-button"
          type="button"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </Modal>
  )
}

TimeoutModal.propTypes = exact(propTypes)
TimeoutModal.defaultProps = defaultProps

export default React.memo(TimeoutModal)
