import { toNumber } from 'lodash'

// Note: This function assumes arguments a and b are either a number (e.g., 10),
// numeric string (e.g., "25.09"), hyphen ("-"), or empty string ("").
function numberSorter(a, b) {
  // Display "-" first then sort the rest in ascending order
  if (a === '-' || a === '') return -1
  if (b === '-' || b === '') return 1
  return toNumber(a) - toNumber(b)
}

export default numberSorter
