import PropTypes from 'prop-types'
import { UserRole, UserStatus } from 'config/portal'
import {
  SigningMethod,
  ServicesAgreementStatus,
  ServicesAgreementPlanStatusForTypes,
  ServicesAgreementPlanType,
  ServicesAgreementPlanStatus,
  RolloverAccountStatus,
  RolloverAccountType,
} from 'config/automatic-rollovers'
import { SearchOrderStatus, SearchTypeID } from 'config/search-services'
import {
  ProductType,
  AgreementType,
  AgreementStatus,
  AgreementStatusForTypes,
} from 'config/products'

export const searches = PropTypes.shape({
  status: PropTypes.oneOf(Object.values(SearchOrderStatus)).isRequired,
  searchTypeID: PropTypes.oneOf(Object.values(SearchTypeID)).isRequired,
  submittedOn: PropTypes.string.isRequired,
  planFiduciary: PropTypes.string,
  orderNumber: PropTypes.string.isRequired,
  newIndicator: PropTypes.string,
  searchOrderID: PropTypes.string,
})

export const searchOrder = PropTypes.shape({
  requestedCompanyName: PropTypes.string,
  orderNumber: PropTypes.string,
  status: PropTypes.string,
  planFiduciary: PropTypes.string,
  numberOfSearches: PropTypes.string,
  type: PropTypes.string,
  submittedOn: PropTypes.string,
  submittedBy: PropTypes.string,
  dateCompleted: PropTypes.string,
  resultsAvailableUntil: PropTypes.string,
})

const userShape = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  contactID: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  userPhone: PropTypes.string,
  isTCAccepted: PropTypes.bool,
  retirementServicesPortalProfile: PropTypes.string.isRequired,
  accountAudience: PropTypes.string.isRequired,
  accountID: PropTypes.string.isRequired,
  accountName: PropTypes.string.isRequired,
}

export const user = PropTypes.shape(userShape)
export const admin = PropTypes.shape({
  ...userShape,
  accountPhone: PropTypes.string.isRequired,
  accountBillingStreet: PropTypes.string.isRequired,
  accountBillingCity: PropTypes.string.isRequired,
  accountBillingState: PropTypes.string,
  accountBillingPostalCode: PropTypes.string.isRequired,
  accountBillingCountry: PropTypes.string.isRequired,
  accountBillingWebsite: PropTypes.string,
  accountEIN: PropTypes.string,
})
export const newSearchFlowData = PropTypes.shape({
  searchType: PropTypes.oneOf(Object.values(SearchTypeID)),
  recordsData: PropTypes.arrayOf(PropTypes.object),
  dmfPermissible: PropTypes.string,
  dmfPermissibleAdditional: PropTypes.string,
  glbaPermissible: PropTypes.string,
  dppaPermissible: PropTypes.string,
})

export const productType = PropTypes.oneOf(Object.values(ProductType))

export const subscribedProduct = PropTypes.shape({
  type: productType.isRequired,
  status: PropTypes.oneOf(Object.values(AgreementStatus)).isRequired,
  agreementId: PropTypes.string,
  signerName: PropTypes.string,
})

export const servicesAgreementSummary = PropTypes.shape({
  serviceAgreementID: PropTypes.string.isRequired,
  agreementID: PropTypes.string.isRequired,
  type: PropTypes.string,
  name: PropTypes.string,
  portalAgreementType: PropTypes.string,
  portalAgreementStatus: PropTypes.oneOf(
    Object.values(ServicesAgreementStatus)
  ),
  portalAgreementDate: PropTypes.string,
  createdDate: PropTypes.string,
  planEIN: PropTypes.string,
  planType: PropTypes.oneOf(Object.values(ServicesAgreementPlanType)),
  planStatus: PropTypes.oneOf(
    Object.values(ServicesAgreementPlanStatusForTypes)
  ),
  planNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  providerPlanId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  recordKeeperCompany: PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  TPACompany: PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  planSponsorCompany: PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
})

const companyFields = {
  name: PropTypes.string,
  phone: PropTypes.string,
  billingStreet: PropTypes.string,
  billingCity: PropTypes.string,
  billingState: PropTypes.string,
  billingPostalCode: PropTypes.string,
}

const contactFields = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  mailingStreet: PropTypes.string,
  mailingCity: PropTypes.string,
  mailingState: PropTypes.string,
  mailingPostalCode: PropTypes.string,
}

export const servicesAgreementShow = PropTypes.shape({
  serviceAgreementID: PropTypes.string.isRequired,
  agreementID: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  portalAgreementType: PropTypes.string,
  portalAgreementStatus: PropTypes.oneOf(
    Object.values(ServicesAgreementStatus)
  ),
  portalAgreementDate: PropTypes.string,
  createdDate: PropTypes.string,
  planType: PropTypes.oneOf(Object.values(ServicesAgreementPlanType)),
  planStatus: PropTypes.oneOf(Object.values(ServicesAgreementPlanStatus)),
  planEIN: PropTypes.string,
  providerPlanId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  planNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  signingMethod: PropTypes.oneOf([
    SigningMethod.ELECTRONIC,
    SigningMethod.GENERATE_DOCUMENT,
  ]),
  planSponsorCompany: PropTypes.shape(companyFields),
  planSponsorContact: PropTypes.shape(contactFields),
  TPACompany: PropTypes.shape(companyFields),
  TPAContact: PropTypes.shape(contactFields),
  recordKeeperCompany: PropTypes.shape(companyFields),
  recordKeeperContact: PropTypes.shape(contactFields),
})

export const companyUser = PropTypes.shape({
  contactID: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  phone: PropTypes.string,
  role: PropTypes.oneOf(Object.values(UserRole)).isRequired,
  status: PropTypes.oneOf(Object.values(UserStatus)).isRequired,
})

export const stage = PropTypes.shape({
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
})

export const rolloverParticipant = PropTypes.shape({
  recordkeeper: PropTypes.string,
  tpa: PropTypes.string,
  planSponsor: PropTypes.string.isRequired,
  planName: PropTypes.string.isRequired,
  planEin: PropTypes.string.isRequired,
  planNumber: PropTypes.string,
  planIdNumber: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  middleName: PropTypes.string,
  lastName: PropTypes.string.isRequired,
  addressLine1: PropTypes.string.isRequired,
  addressLine2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zipCode: PropTypes.string,
  countryCode: PropTypes.string,
  dob: PropTypes.string.isRequired,
  ssn: PropTypes.string.isRequired,
  emailAddress: PropTypes.string,
  traditionalIraPreTaxAmount: PropTypes.string,
  traditionalIraAfterTaxAmount: PropTypes.string,
  rothIraAmount: PropTypes.string,
})

export const rolloverAccount = PropTypes.shape({
  participantName: PropTypes.string.isRequired,
  accountType: PropTypes.oneOf(Object.values(RolloverAccountType)).isRequired,
  status: PropTypes.oneOf(Object.values(RolloverAccountStatus)).isRequired,
  lastModifiedDate: PropTypes.string.isRequired,
  initialInvestmentAmount: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
})

const companyEntity = {
  ...companyFields,
  ID: PropTypes.string.isRequired,
  website: PropTypes.string,
  billingCountry: PropTypes.string.isRequired,
}

const contactEntity = {
  ...contactFields,
  ID: PropTypes.string.isRequired,
  mailingCountry: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export const audienceDetails = PropTypes.shape({
  company: PropTypes.shape({
    ...companyEntity,
    type: PropTypes.string.isRequired,
  }),
  contact: PropTypes.shape(contactEntity),
})

export const availableInitialInvestment = PropTypes.shape({
  templateId: PropTypes.string.isRequired,
  templateName: PropTypes.string.isRequired,
  isDefault: PropTypes.bool,
})

// action menu items

export const actionMenuItem = PropTypes.shape({
  text: PropTypes.string.isRequired,
  handleSelect: PropTypes.func.isRequired,
})

export const actionMenuLink = PropTypes.shape({
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
})

export const actionMenuExternalLink = PropTypes.shape({
  text: PropTypes.string.isRequired,
  externalLink: PropTypes.string.isRequired,
})

export const tableColumn = PropTypes.shape({
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onClick: PropTypes.func,
  format: PropTypes.func,
  valueGetter: PropTypes.func,
  width: PropTypes.string,
})

export const companyOwner = PropTypes.shape({
  contactID: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  name: PropTypes.string.isRequired,
  phone: PropTypes.string,
})

export const completeServicesAgreementForm = PropTypes.shape({
  planDetails: PropTypes.shape({
    name: PropTypes.string.isRequired,
    ein: PropTypes.string.isRequired,
  }),
  signingMethod: PropTypes.string.isRequired,
  option: PropTypes.string.isRequired,
})

export const erisapediaResult = PropTypes.shape({
  planName: PropTypes.string.isRequired,
  planEIN: PropTypes.string.isRequired,
  planNumber: PropTypes.string,
  planType: PropTypes.string,
  planSponsorCompany: PropTypes.shape(companyFields),
  planSponsorContact: PropTypes.shape(contactFields),
})

export const paymentMethod = PropTypes.shape({
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  address1: PropTypes.string.isRequired,
  address2: PropTypes.string,
  zipCode: PropTypes.string.isRequired,
  paymentToken: PropTypes.string,
  fraudId: PropTypes.string,
  creditCardLastFour: PropTypes.string,
  cardExpMonth: PropTypes.string.isRequired,
  cardExpYear: PropTypes.string.isRequired,
  cardType: PropTypes.string.isRequired,
  cardStatus: PropTypes.string,
  cardLabel: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
})

export const completePaymentDetails = PropTypes.shape({
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  address1: PropTypes.string.isRequired,
  address2: PropTypes.string,
  email: PropTypes.string,
  zipCode: PropTypes.string.isRequired,
  paymentToken: PropTypes.string.isRequired,
  fraudId: PropTypes.string.isRequired,
  phone: PropTypes.string,
  creditCardLastFour: PropTypes.string.isRequired,
  cardExpMonth: PropTypes.string.isRequired,
  cardExpYear: PropTypes.string.isRequired,
  cardType: PropTypes.string.isRequired,
  cardStatus: PropTypes.string.isRequired,
  cardLabel: PropTypes.string.isRequired,
  fingerPrint: PropTypes.string.isRequired,
})

export const searchService = PropTypes.shape({
  id: PropTypes.string.isRequired,
  searchService: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  fee: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  feeExemption: PropTypes.bool.isRequired,
  turnaroundTime: PropTypes.string.isRequired,
})

export const searchServicesAgreementForm = PropTypes.shape({
  eSignDetails: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
  dialogRead: PropTypes.bool.isRequired,
  confirm: PropTypes.bool.isRequired,
})

export const searchOrderCost = PropTypes.shape({
  totalOrderCost: PropTypes.number.isRequired,
})

export const searchParticipant = PropTypes.shape({
  row: PropTypes.number.isRequired,
  planSponsorName: PropTypes.string.isRequired,
  planName: PropTypes.string.isRequired,
  planEin: PropTypes.string.isRequired,
  planNumber: PropTypes.string,
  ssn: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  middleName: PropTypes.string,
  lastName: PropTypes.string.isRequired,
  address1: PropTypes.string,
  address2: PropTypes.string,
  address3: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
  dob: PropTypes.string,
  dod: PropTypes.string,
  phone: PropTypes.string,
  workFor: PropTypes.string,
  reason: PropTypes.string,
  miscellaneous1: PropTypes.string,
  miscellaneous2: PropTypes.string,
})

export const searchServiceAgreement = PropTypes.shape({
  agreementStatus: PropTypes.oneOf(Object.values(AgreementStatusForTypes))
    .isRequired,
  agreementType: PropTypes.oneOf(Object.values(AgreementType)).isRequired,
  bdAddendumStatus: PropTypes.string.isRequired,
  contractID: PropTypes.string,
  customerSignedEmail: PropTypes.string,
  customerSignedId: PropTypes.string,
  customerSignedName: PropTypes.string,
  name: PropTypes.string.isRequired,
  planSponsorCompanyName: PropTypes.string,
  serviceAgreementsID: PropTypes.string.isRequired,
})
