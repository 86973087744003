import * as FullStory from '@fullstory/browser'
import { FULLSTORY_ORG_ID, isFullStoryEnabled } from 'config/portal'

/* eslint-disable no-console */

function initialize() {
  // Skip initializing if Fullstory feature flag is disabled or if Cypress is running
  if (!isFullStoryEnabled() || window.Cypress) return

  try {
    FullStory.init({
      orgId: FULLSTORY_ORG_ID,
    })
  } catch (e) {
    console.warn(`WARNING: Fullstory initialization failed: ${e}`)
  }
}

// Initialize on import
initialize()
