import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { BDAddendumAgreementForm } from '../forms'
import { useHistory } from 'react-router-dom'
import { Path } from 'config/portal'
import { TOAST_ERROR_CONTACT_SUPPORT } from 'config/notifications'
import * as apiActions from 'api-actions'
import * as productActions from '../../../products/actions'
import { selectors as globalSelectors } from 'global-reducer'
import { flashErrorMessage } from 'redux-flash'
import { selectors as selectors } from '../../reducer'
import { selectors as productSelectors } from '../../../products/reducer'

const propTypes = {
  currentUser: Types.user.isRequired,
  addendumServicesAgreementHash: PropTypes.object.isRequired,
  submitAddendumServicesAgreementForm: PropTypes.func.isRequired,
  setShouldTriggerAllServicesAgreementsFetch: PropTypes.func.isRequired,
  flashErrorMessageHandler: PropTypes.func.isRequired,
}

const defaultProps = {}

function ReviewAndSubmitAddendumView({
  currentUser,
  submitAddendumServicesAgreementForm,
  setShouldTriggerAllServicesAgreementsFetch,
  flashErrorMessageHandler,
  addendumServicesAgreementHash,
}) {
  const history = useHistory()

  const addendumAgreement = addendumServicesAgreementHash

  const handleSubmit = async () => {
    try {
      if (addendumAgreement != null) {
        // This Condition should be uncommented once the Addendum data flow form Add Addendum Flow is complete.
        await submitAddendumServicesAgreementForm({
          signerEmail: currentUser.userEmail,
          signerTitle: currentUser.userTitle,
          addendumStatus: 'Signed',
          signerName: currentUser.firstName,
          serviceAgreementId: addendumAgreement.value, // selectedAddendumAgreement.serviceAgreementID, to be added after the data flow fpr Addendum is completed.
        })
        setShouldTriggerAllServicesAgreementsFetch(true)
        history.push({
          pathname: Path.BD_SERVICES_AGREEMENTS,
          state: {
            ignorePrompt: true, // No need to warn the user about leaving the flow
            showAddendumSuccessModal: true,
          },
        })
      } else {
        //// This Condition should be uncommented once the Addendum data flow form Add Addendum Flow is complete.
        flashErrorMessageHandler(TOAST_ERROR_CONTACT_SUPPORT) //// This Condition should be uncommented once the Addendum data flow form Add Addendum Flow is complete.
      } //// This Condition should be uncommented once the Addendum data flow form Add Addendum Flow is complete.
    } catch (error) {
      flashErrorMessageHandler(TOAST_ERROR_CONTACT_SUPPORT)
    }
  }

  return (
    <div className="card full-height complete-search-agreement-view">
      <div className="complete-search-agreement-form-container">
        <div className="form-masthead">
          <h2>Next, review and agree to the addendum details</h2>
        </div>
        <div className="form-section">
          <BDAddendumAgreementForm
            initialValues={{
              confirm: false,
              dialogRead: false,
            }}
            onSubmit={handleSubmit}
            userDetails={{
              firstName: currentUser.firstName,
              lastName: currentUser.lastName,
              title: currentUser.userTitle,
            }}
          />
        </div>
      </div>
    </div>
  )
}

ReviewAndSubmitAddendumView.propTypes = propTypes
ReviewAndSubmitAddendumView.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentUser: globalSelectors.currentUser(state),
    shouldTriggerAllServicesAgreementsFetch:
      productSelectors.shouldTriggerAllServicesAgreementsFetch(state),
    servicesAgreements: productSelectors.servicesAgreements(state),
    addendumServicesAgreementHash:
      selectors.addendumServicesAgreementHash(state),
  }
}

const mapDispatchToProps = {
  submitAddendumServicesAgreementForm:
    apiActions.submitAddendumServicesAgreementForm,
  setShouldTriggerAllServicesAgreementsFetch:
    productActions.setShouldTriggerAllServicesAgreementsFetch,
  flashErrorMessageHandler: flashErrorMessage,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)(ReviewAndSubmitAddendumView)
)
