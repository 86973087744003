import React from 'react'
import PropTypes from 'prop-types'
import useCollapse from 'react-collapsed'
import classnames from 'classnames'
import { Icon as TogglePlusIcon } from 'images/utility-icons/plus.svg'
import { Icon as ToggleMinusIcon } from 'images/utility-icons/minus.svg'

const propTypes = {
  toggleText: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  headingLevel: PropTypes.oneOf([2, 3, 4, 5, 6]),
}

const defaultProps = {
  className: 'card',
  headingLevel: null, // this doesn't have to be used as a heading element
}

// isExpanded prop can be used to manually control state, but is not required
// https://github.com/roginfarrer/react-collapsed#control-it-yourself
// useCollapsed hook and additional configs can be found here:
// https://github.com/roginfarrer/react-collapsed#usecollapse-config

function Disclosure({
  toggleText,
  children,
  className,
  headingLevel,
  ...rest
}) {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({ rest })
  return (
    <div className={classnames('disclosure', className)}>
      <div aria-level={headingLevel}>
        <button className="disclosure-button" {...getToggleProps()}>
          {isExpanded ? (
            <ToggleMinusIcon aria-hidden="true" />
          ) : (
            <TogglePlusIcon aria-hidden="true" />
          )}
          {toggleText}
        </button>
      </div>
      <div className="disclosure-content" {...getCollapseProps()}>
        {children}
      </div>
    </div>
  )
}

Disclosure.propTypes = propTypes
Disclosure.defaultProps = defaultProps

export default Disclosure
