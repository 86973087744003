import { downloadFile } from 'utils'

function downloadCsv(csvData, { fileName }) {
  const csvContent = createCsvContent(csvData)
  return downloadFile(csvContent, {
    fileName,
    mimeType: 'text/csv;charset=utf-8',
  })
}

// ----- PRIVATE -----

const createCsvContent = (rows) => {
  return rows
    .map((row) => {
      return row
        .map((col = '') => {
          let val = col
          val = val.replace(/"/g, '""')
          return val.includes(',') || val.includes('"') ? `"${val}"` : val
        })
        .join(',')
    })
    .join('\n')
}

export default downloadCsv
