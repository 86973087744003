import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  link: PropTypes.string.isRequired,
  children: PropTypes.node,
}

const defaultProps = {
  children: null,
}

function ExternalLink({ link, children, ...rest }) {
  // by default, open the linked document in a new tab
  return (
    <a href={link} target="_blank" rel="noreferrer noopener" {...rest}>
      {children || link}
    </a>
  )
}

ExternalLink.propTypes = propTypes
ExternalLink.defaultProps = defaultProps

export default React.memo(ExternalLink)
