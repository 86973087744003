// tippy plugins

// Hides the tippy when the escape key is pressed
const hideOnEsc = {
  name: 'hideOnEsc',
  defaultValue: true,
  fn({ hide }) {
    function onKeyDown(event) {
      if (event.keyCode === 27) {
        hide()
      }
    }

    return {
      onShow() {
        document.addEventListener('keydown', onKeyDown)
      },
      onHide() {
        document.removeEventListener('keydown', onKeyDown)
      },
    }
  },
}

// Causes a popper to hide if no elements within it are in focus
const hideOnPopperBlur = {
  name: 'hideOnPopperBlur',
  defaultValue: true,
  fn(instance) {
    return {
      onCreate() {
        instance.popper.addEventListener('focusout', (event) => {
          if (
            instance.props.hideOnPopperBlur &&
            event.relatedTarget &&
            !instance.popper.contains(event.relatedTarget)
          ) {
            instance.hide()
          }
        })
      },
    }
  },
}

const preventEventBubbling = {
  name: 'preventEventBubbling',
  defaultValue: true,
  fn(instance) {
    return {
      onTrigger() {
        instance.reference.addEventListener('click', (e) => {
          e.stopPropagation()
        })
      },
    }
  },
}

export default { hideOnEsc, hideOnPopperBlur, preventEventBubbling }
