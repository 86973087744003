import { map } from 'lodash'

// Generates the options for a dropdown select from an array of records
// ie. Existing record keepers, existing plan sponsors, etc.
function generateSelectOptions(items, key, value) {
  const selectOptions = map(items, (item) => ({
    key: item[key],
    value: item[value],
  }))
  return selectOptions
}

export default generateSelectOptions
