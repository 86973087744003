import { useEffect, useState } from 'react'
import { differenceInMilliseconds } from 'date-fns'
import { useInterval } from 'react-use'

function calculateTimeRemaining(deadline) {
  if (!deadline) return null
  return differenceInMilliseconds(deadline, new Date())
}

// Custom hook for initializing and updating time remaining.
function useTimeRemaining(deadline, { updateInterval = 1000 } = {}) {
  const [timeRemaining, setTimeRemaining] = useState(null)
  useEffect(() => setTimeRemaining(calculateTimeRemaining(deadline)), [
    deadline,
  ])
  useInterval(() => {
    setTimeRemaining(calculateTimeRemaining(deadline))
  }, updateInterval)
  return timeRemaining
}

export default useTimeRemaining
