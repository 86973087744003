import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Icon as UploadIcon } from 'images/upload.svg'
import { Icon as DocumentIcon } from 'images/document.svg'
import { Icon as TrashIcon } from 'images/trash.svg'
import { ClickableTooltip } from 'components'
import { truncateFileName } from 'utils'

const propTypes = {
  fileName: PropTypes.string.isRequired,
  handleUpload: PropTypes.func,
  handleDelete: PropTypes.func,
}

const defaultProps = {
  handleUpload: null,
  handleDelete: null,
}

function FileNameContainer({ fileName, handleUpload, handleDelete }) {
  const displayFileName = truncateFileName(fileName)

  return (
    <div className="file-name-container">
      <div className="document">
        <div className="document-icon-wrapper">
          <DocumentIcon aria-hidden="true" />
        </div>
        <p>{displayFileName}</p>
      </div>
      {handleUpload && (
        <ClickableTooltip
          content="Reupload"
          onClick={handleUpload}
          className="action-button"
          ariaLabel="Reupload"
        >
          <UploadIcon className="icon-download" />
        </ClickableTooltip>
      )}
      {handleDelete && (
        <ClickableTooltip
          content="Remove file"
          onClick={handleDelete}
          className="action-button"
          ariaLabel="Remove file"
        >
          <TrashIcon />
        </ClickableTooltip>
      )}
    </div>
  )
}

FileNameContainer.propTypes = exact(propTypes)
FileNameContainer.defaultProps = defaultProps

export default React.memo(FileNameContainer)
