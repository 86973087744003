import React from 'react'
import exact from 'prop-types-exact'
import { SupportContactInformation } from 'config/notifications'
import { ExternalLink } from 'components'

const propTypes = {}
const defaultProps = {}

function SupportPhoneLink() {
  return (
    <ExternalLink
      id="support-link"
      link={`tel:${SupportContactInformation.phone}`}
    >
      {SupportContactInformation.phone}
    </ExternalLink>
  )
}

SupportPhoneLink.propTypes = exact(propTypes)
SupportPhoneLink.defaultProps = defaultProps

export default React.memo(SupportPhoneLink)
