import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect } from 'react-router-dom'
import Layout from './Layout'
import {
  Products,
  SearchHistory,
  SearchOrderDetails,
  AllPlanAgreements,
  PlanAgreementDetails,
} from './views'
import { ProductAgreementType } from '../../config/products'

const propTypes = {
  match: PropTypes.object.isRequired,
  hasAccessToSearchServices: PropTypes.bool.isRequired,
  hasAccessToAutomaticRollovers: PropTypes.bool.isRequired,
  hasAccessToBenefitDistributions: PropTypes.bool.isRequired,
}

const defaultProps = {}

function Routes({
  match: { path },
  hasAccessToSearchServices,
  hasAccessToAutomaticRollovers,
  hasAccessToBenefitDistributions,
}) {
  return (
    <Switch>
      <Route
        exact
        path={path + '/'}
        render={(routeProps) => {
          return (
            <Layout>
              <Products {...routeProps} />
            </Layout>
          )
        }}
      />
      {hasAccessToSearchServices && (
        <Route
          path={path + '/search/history'}
          render={(routeProps) => {
            return (
              <Layout>
                <SearchHistory {...routeProps} />
              </Layout>
            )
          }}
        />
      )}
      {hasAccessToSearchServices && (
        <Route
          path={path + '/search/:orderId'}
          render={(routeProps) => {
            return (
              <Layout>
                <SearchOrderDetails {...routeProps} />
              </Layout>
            )
          }}
        />
      )}
      {hasAccessToBenefitDistributions && (
        <Route
          path={path + '/benefit-distributions'}
          component={BenefitDistributionsRoutes}
        />
      )}
      {hasAccessToBenefitDistributions && (
        <Redirect
          path={path + '/benefit-distributions/home'}
          to={path + '/benefit-distributions/history'}
        />
      )}
      {hasAccessToAutomaticRollovers && (
        <Route
          path={path + '/automatic-rollovers'}
          component={AutomaticRolloverRoutes}
        />
      )}
      {hasAccessToAutomaticRollovers && (
        <Redirect
          path={path + '/automatic-rollovers/home'}
          to={path + '/automatic-rollovers/services-agreements'}
        />
      )}

      <Redirect path="*" to="/home" />
      {/* adding a Redirect to handle direct navigation to /products */}
    </Switch>
  )
}

function AutomaticRolloverRoutes({ match: { path } }) {
  return (
    <Switch>
      <Route
        exact
        path={path + '/services-agreements'}
        render={(routeProps) => {
          return (
            <Layout>
              <AllPlanAgreements
                agreementsType={ProductAgreementType.ARO}
                {...routeProps}
              />
            </Layout>
          )
        }}
      />
      <Route
        path={path + '/services-agreements/:servicesAgreementId'}
        render={(routeProps) => {
          return (
            <Layout>
              <PlanAgreementDetails
                agreementsType={ProductAgreementType.ARO}
                {...routeProps}
              />
            </Layout>
          )
        }}
      />
      <Redirect path="*" to={path + '/services-agreements'} />
    </Switch>
  )
}

function BenefitDistributionsRoutes({ match: { path } }) {
  return (
    <Switch>
      <Route
        exact
        path={path + '/history'}
        render={(routeProps) => (
          <Layout>
            <AllPlanAgreements
              agreementsType={ProductAgreementType.BD}
              {...routeProps}
            />
          </Layout>
        )}
      />
      <Route
        path={path + '/history/:servicesAgreementId'}
        render={(routeProps) => {
          return (
            <Layout>
              <PlanAgreementDetails
                agreementsType={ProductAgreementType.BD}
                {...routeProps}
              />
            </Layout>
          )
        }}
      />
      <Redirect path="*" to={path + '/history'} />
    </Switch>
  )
}

Routes.propTypes = propTypes
Routes.defaultProps = defaultProps

export default Routes
