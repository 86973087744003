import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { SelectCompanyInformationForm } from '../forms'
import { Redirect, useHistory } from 'react-router-dom'
import { isEmpty, find, concat } from 'lodash'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import * as actions from '../actions'
import { selectors as productSelectors } from '../../products/reducer'
import { ComponentFailure, Spinner } from 'components'
import * as apiActions from 'api-actions'
import { Path, MANUAL_ERISAPEDIA_OPTION } from 'config/portal'
import { ADD_PLAN_SPONSOR_OPTION } from 'config/automatic-rollovers'
import { isAddressEqual } from 'utils'

const propTypes = {
  servicesAgreements: PropTypes.arrayOf(Types.servicesAgreementSummary),
  fetchServicesAgreements: PropTypes.func.isRequired,
  completeServicesAgreementForm: Types.completeServicesAgreementForm,
  fetchErisapediaSearches: PropTypes.func.isRequired,
  erisapediaSearches: PropTypes.arrayOf(Types.erisapediaResult),
  setCompleteServicesAgreementForm: PropTypes.func.isRequired,
}

const defaultProps = {
  servicesAgreements: null,
  completeServicesAgreementForm: null,
  erisapediaSearches: null,
}

function SelectCompanyInformationView({
  servicesAgreements,
  fetchServicesAgreements,
  completeServicesAgreementForm,
  fetchErisapediaSearches,
  erisapediaSearches,
  setCompleteServicesAgreementForm,
}) {
  const history = useHistory()

  if (isEmpty(completeServicesAgreementForm))
    return <Redirect to={Path.ADD_NEW_BD_SERVICES_AGREEMENT} />

  const [error, setError] = useState(false)

  useEffect(() => {
    async function getErisapediaSearches() {
      try {
        await fetchErisapediaSearches({
          name: completeServicesAgreementForm.planDetails.name,
          ein: completeServicesAgreementForm.planDetails.ein,
        })
      } catch {
        setError(true)
      }
    }
    if (!erisapediaSearches) {
      getErisapediaSearches()
    }
  }, [erisapediaSearches, completeServicesAgreementForm.planDetails])

  useEffect(() => {
    async function getServicesAgreements() {
      try {
        await fetchServicesAgreements()
      } catch {
        setError(true)
      }
    }
    if (!servicesAgreements) getServicesAgreements()
  }, [servicesAgreements])

  const isLoading = (!erisapediaSearches || !servicesAgreements) && !error

  if (isLoading)
    return (
      <div className="complete-services-agreement-view">
        <Spinner />
      </div>
    )
  if (error) return <ComponentFailure />

  const erisapediaSearchOptions = erisapediaSearches.map((el, index) => {
    const option = `Option ${index + 1}`
    return {
      key: option,
      value: option,
      ...el,
    }
  })
  const manualOption = {
    key: `Option ${erisapediaSearchOptions.length + 1}`,
    value: MANUAL_ERISAPEDIA_OPTION,
    planName: completeServicesAgreementForm.planDetails.name,
    planEIN: completeServicesAgreementForm.planDetails.ein,
  }
  const allOptions = concat(erisapediaSearchOptions, manualOption)

  const handleSubmit = ({ option }) => {
    if (option === MANUAL_ERISAPEDIA_OPTION) {
      setCompleteServicesAgreementForm({
        option,
        allowPlanSponsorSelection: true,
      })
    } else {
      const selectedOption = find(
        erisapediaSearchOptions,
        ({ value }) => value === option
      )
      const { planSponsorCompany, planSponsorContact } = selectedOption

      setCompleteServicesAgreementForm({
        planSponsor: ADD_PLAN_SPONSOR_OPTION.value,
        planSponsorDetails: {
          company: planSponsorCompany,
          contact: {
            ...planSponsorContact,
            isSameAsCompanyAddress: isAddressEqual({
              company: planSponsorCompany,
              contact: planSponsorContact,
            }),
          },
        },
        option,
        allowPlanSponsorSelection: false,
      })
    }

    history.push({
      pathname: '/benefit-distributions/complete-services-agreement',
      state: {
        search: history.location.state?.search,
      },
    })
  }

  return (
    <div className="card full-height complete-services-agreement-view">
      <div className="complete-services-agreement-form-container">
        <div className="form-masthead">
          <h2>We found your plan information</h2>
          <p>Choose an option to continue.</p>
        </div>
        <div className="form-section form-section--with-top-border">
          <SelectCompanyInformationForm
            initialValues={{
              option: completeServicesAgreementForm.option || '',
            }}
            options={allOptions}
            servicesAgreements={servicesAgreements}
            onSubmit={handleSubmit}
          />
        </div>
      </div>
    </div>
  )
}

SelectCompanyInformationView.propTypes = propTypes
SelectCompanyInformationView.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    completeServicesAgreementForm:
      selectors.completeServicesAgreementForm(state),
    servicesAgreements: productSelectors.servicesAgreements(state),
    erisapediaSearches: selectors.erisapediaSearches(state),
  }
}

const mapDispatchToProps = {
  fetchServicesAgreements: apiActions.fetchServicesAgreements,
  fetchErisapediaSearches: apiActions.fetchErisapediaSearches,
  setCompleteServicesAgreementForm: actions.setCompleteServicesAgreementForm,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SelectCompanyInformationView
)
