import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import {
  SearchableSelect,
  ScrollErrorForm,
  HiddenLabel,
  ConfirmModal,
  Notification,
} from 'components'
import { Variant as NotificationVariant } from 'components/Notification'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { FlowActions as AroFlowActions } from '../main/products/components'
import { FlowActions as BDFlowActions } from '../main/benefit-distributions/components'
import { sortSelectOptions } from 'utils'
import { groupBy, concat, every, map, reduce, isEmpty } from 'lodash'
import { Path, BDActionItems, AroActionItems } from 'config/portal'
import { NO_SERVICES_AGREEMENT_OPTION } from 'config/automatic-rollovers'
import { useHistory, Link } from 'react-router-dom'
import { Icon as ArrowRightIcon } from 'images/arrow-right.svg'

const propTypes = {
  formType: PropTypes.string.isRequired,
  initialValues: PropTypes.object.isRequired,
  selectOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSubmit: PropTypes.func.isRequired,
  servicesAgreements: PropTypes.arrayOf(Types.servicesAgreementSummary)
    .isRequired,
}

const defaultProps = {
  initialValues: {},
  selectOptions: [],
}

const generateGroupedOptions = (exactMatch, likelyMatches, allOptions) => {
  if (exactMatch) {
    const { true: exactOption, false: remainingOptions } = groupBy(
      allOptions,
      ({ value }) => value === exactMatch.serviceAgreementID
    )
    return [
      { label: 'Exact Match', options: exactOption },
      remainingOptions && {
        label: 'Other Plans',
        options: remainingOptions,
      },
    ].filter(Boolean)
  }

  if (likelyMatches) {
    const likelyMatchesIds = likelyMatches.map(
      ({ serviceAgreementID }) => serviceAgreementID
    )
    const { true: likelyOptions, false: remainingOptions } = groupBy(
      allOptions,
      ({ value }) => likelyMatchesIds.includes(value)
    )
    return [
      {
        label: likelyMatches.length > 1 ? 'Likely Matches' : 'Likely Match',
        options: likelyOptions,
      },
      remainingOptions && {
        label: 'Other Plans',
        options: remainingOptions,
      },
    ].filter(Boolean)
  }

  return [
    {
      label: 'All Plans',
      options: allOptions,
    },
  ]
}

const isNoPlansSelected = (errors) => (!isEmpty(errors) ? true : false)

const isMissingAllPlans = (planHash) =>
  every(
    planHash,
    ({ servicesAgreement }) =>
      servicesAgreement === NO_SERVICES_AGREEMENT_OPTION.value
  )

const isMatchedAllPlans = (planHash, currentOptions) =>
  every(planHash, ({ servicesAgreement }) =>
    currentOptions.slice(0, -1).includes(servicesAgreement)
  )

const isMixMatchedAllPlans = (planHash, currentOptions) =>
  every(planHash, ({ servicesAgreement }) =>
    currentOptions.includes(servicesAgreement)
  )

function PlanMappingForm({
  formType,
  initialValues,
  selectOptions,
  onSubmit,
  servicesAgreements,
}) {
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [autoMatchedAllPlans, setAutomatchedAllPlans] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const [newDiscardedParticipants, setNewDiscardedParticipants] = useState([])
  const errorCardRef = useRef()
  const history = useHistory()

  useEffect(() => {
    planNames.length === 0
      ? setAutomatchedAllPlans(true)
      : setAutomatchedAllPlans(false)
  }, [planNames])

  const planNames = sortSelectOptions(
    map(initialValues, (value, key) => {
      if (!value.exactMatch) {
        return {
          name: window.atob(key),
          encodedName: key,
        }
      }
      return null
    }).filter(Boolean),
    'name'
  )
  const planNamesAutomatched = sortSelectOptions(
    map(initialValues, (value, key) => {
      if (value.exactMatch) {
        return {
          name: window.atob(key),
          encodedName: key,
        }
      }
      return null
    }).filter(Boolean),
    'name'
  )
  const selectOptionValues = concat(
    selectOptions,
    NO_SERVICES_AGREEMENT_OPTION
  ).map((option) => option.value)

  const planSchema = Yup.object(
    reduce(
      initialValues,
      (schema, _, key) => {
        schema[key] = Yup.object({
          servicesAgreement: Yup.string()
            .required('Select the corresponding agreement')
            .oneOf(selectOptionValues, 'Must be a valid option'),
        })
        return schema
      },
      {}
    )
  )

  const handlePopup = () => {
    setShowPopup((prevShowPopup) => !prevShowPopup)
  }

  const handleView = () => {
    setShowForm((prevShowForm) => !prevShowForm)
  }

  const ActionItems = formType === 'BD' ? BDActionItems : AroActionItems

  return (
    <div className="workflow-card-container plan-confirmation">
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          if (isMissingAllPlans(values)) {
            const container = errorCardRef.current
            if (container) {
              container.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
            }
            return
          }

          let selectedAgreements = Object.entries(values)

          let noServicesAgreements = selectedAgreements.filter((agreement) => {
            return (
              agreement[1].servicesAgreement === 'no-services-agreement' &&
              agreement
            )
          })

          if (noServicesAgreements.length === 0) {
            onSubmit(values, noServicesAgreements)
          } else {
            setNewDiscardedParticipants(noServicesAgreements)
            handlePopup()
          }
        }}
        validationSchema={planSchema}
      >
        {({ values, errors }) => {
          const noPlansSelected = isNoPlansSelected(errors)
          const missingAllPlans = isMissingAllPlans(values)
          const mixMatchedAllPlans = isMixMatchedAllPlans(
            values,
            selectOptionValues
          )
          const matchedAllPlans = isMatchedAllPlans(values, selectOptionValues)

          return (
            <ScrollErrorForm>
              {matchedAllPlans && !missingAllPlans ? (
                <Notification type={NotificationVariant.SUCCESS}>
                  <p className="text-title--small">
                    Success! You’ve matched all your plans to agreements.
                  </p>
                </Notification>
              ) : autoMatchedAllPlans && !missingAllPlans ? (
                <Notification type={NotificationVariant.SUCCESS}>
                  <p className="text-title--small">
                    Good news! All your plans were automatically matched to
                    agreements.
                  </p>
                </Notification>
              ) : noPlansSelected ? (
                <Notification type={NotificationVariant.ERROR}>
                  <p className="text-title--small">
                    All your plans must be matched to agreements.
                  </p>
                </Notification>
              ) : missingAllPlans ? (
                <div
                  className="form-section form-section--with-top-border plan-mapping error"
                  ref={errorCardRef}
                >
                  <Notification type={NotificationVariant.ERROR}>
                    <p className="text-title--small">
                      You must match at least one plan to an agreement to submit
                      a {formType === 'BD' ? 'distribution' : 'rollover'}
                    </p>
                  </Notification>
                  <button
                    type="button"
                    className="button-primary add-services-agreement"
                    onClick={() => setShowConfirmModal(true)}
                  >
                    {ActionItems.CREATE_NEW_SERVICES_AGREEMENT}
                  </button>
                </div>
              ) : null}
              {showConfirmModal && (
                <ConfirmModal
                  onClose={() => setShowConfirmModal(false)}
                  onConfirm={() => {
                    setShowConfirmModal(false)
                    history.push({
                      pathname:
                        formType === 'BD'
                          ? Path.ADD_NEW_BD_SERVICES_AGREEMENT
                          : Path.ADD_NEW_SERVICES_AGREEMENT,
                      state: {
                        ignorePrompt: true,
                      },
                    })
                  }}
                  confirmContent="Yes, cancel"
                >
                  <h2>Quit & Exit</h2>
                  <p>
                    {formType === 'BD'
                      ? `Are you sure you want to exit the new distribution workflow
                    to continue with adding a new Services Agreement?`
                      : `Are you sure you want to exit the new rollover workflow to
                    continue with adding a new Services Agreement?`}
                  </p>
                </ConfirmModal>
              )}
              {!autoMatchedAllPlans && (
                <div className="plan-agreement-header-container">
                  <div className="plan-agreement-header">
                    Complete legal plan name
                  </div>
                  <div className="plan-agreement-header">
                    Services agreement name
                  </div>
                </div>
              )}
              {planNames.map(({ name, encodedName }) => {
                const servicesAgreementValue =
                  values[encodedName].servicesAgreement
                const selectedServicesAgreement = servicesAgreements.find(
                  ({ serviceAgreementID }) =>
                    serviceAgreementID === servicesAgreementValue
                )
                const { exactMatch, likelyMatches } = initialValues[encodedName]
                const groupedOptions = generateGroupedOptions(
                  exactMatch,
                  likelyMatches,
                  selectOptions
                )
                return (
                  <div key={encodedName} className="form-section">
                    <dl className="plan-content">
                      <div className="plan-information">
                        <div className="plan-information-table-container">
                          <div className="table-row">
                            <div className="table-cell">{name}</div>
                            <div className="arrow">
                              <ArrowRightIcon aria-hidden="true" />
                            </div>
                            <div className="table-cell">
                              <SearchableSelect
                                classNamePrefix="mapping-select"
                                name={`${encodedName}[servicesAgreement]`}
                                label="Services agreement"
                                labelComponent={HiddenLabel}
                                noBorder={true}
                                noPadding={true}
                                placeholder="Select services agreement"
                                options={concat(
                                  NO_SERVICES_AGREEMENT_OPTION,
                                  groupedOptions
                                )}
                                formatGroupLabel={(data) => (
                                  <div className="select-group-label">
                                    <span>{data.label}</span>
                                  </div>
                                )}
                              />

                              {selectedServicesAgreement ? (
                                <Link
                                  target="_blank"
                                  rel="noreferrer noopener"
                                  className="link-text"
                                  to={`${Path.SERVICES_AGREEMENTS}/${selectedServicesAgreement.serviceAgreementID}`}
                                  aria-label={`View Services Agreement details for ${selectedServicesAgreement.name}`}
                                >
                                  View services agreement details
                                </Link>
                              ) : null}
                              {/* !selectedServicesAgreement &&
                                mixMatchedAllPlans ? (
                                <div className="warning-text">
                                  No service agreement selected
                                </div>
                              ) : */}
                            </div>
                          </div>
                        </div>
                        {mixMatchedAllPlans && !selectedServicesAgreement && (
                          <div className="plan-warning">
                            <Notification type={NotificationVariant.WARNING}>
                              <p className="text-title--small">
                                Selecting "No services agreement found" means
                                this plan won’t be included in your file
                                submission. If you want to submit this plan,
                                you’ll need to provide a signed agreement.
                              </p>
                            </Notification>
                          </div>
                        )}
                      </div>
                    </dl>
                  </div>
                )
              })}
              <div className="plan-info">
                <p>
                  We automatically matched {planNamesAutomatched.length} of your
                  plans to agreements. If everything looks good, no additional
                  action is needed.{' '}
                </p>
                <button
                  type="button"
                  className="button-text"
                  onClick={handleView}
                >
                  {showForm ? 'Hide Agreements' : 'View Agreements'}
                </button>
              </div>

              {showForm && autoMatchedAllPlans && (
                <div className="plan-agreement-header-container">
                  <div className="plan-agreement-header">
                    Complete legal plan name
                  </div>
                  <div className="plan-agreement-header">
                    Services agreement name
                  </div>
                </div>
              )}

              {/* {showForm && planNamesAutomatched.length > 0 && (
                <div className="plan-information">
                  <div className="plan-information-table-container">
                    <div className="table-header">
                      <div className="table-cell">Complete legal plan name</div>
                      <div className="table-cell"></div>
                      <div className="table-cell">Services agreement name</div>
                    </div>
                  </div>
                </div>
              )} */}
              {showForm &&
                planNamesAutomatched.map(({ name, encodedName }) => {
                  const servicesAgreementValue =
                    values[encodedName].servicesAgreement
                  const selectedServicesAgreement = servicesAgreements.find(
                    ({ serviceAgreementID }) =>
                      serviceAgreementID === servicesAgreementValue
                  )
                  const { exactMatch, likelyMatches } =
                    initialValues[encodedName]
                  const groupedOptions = generateGroupedOptions(
                    exactMatch,
                    likelyMatches,
                    selectOptions
                  )
                  return (
                    <div key={encodedName} className="form-section">
                      <dl className="plan-content">
                        <div className="plan-information">
                          <div className="plan-information-table-container">
                            <div className="table-row">
                              <div className="table-cell">{name}</div>
                              <div className="arrow">
                                <ArrowRightIcon aria-hidden="true" />
                              </div>
                              <div className="table-cell">
                                <SearchableSelect
                                  classNamePrefix="mapping-select"
                                  name={`${encodedName}[servicesAgreement]`}
                                  label="Services agreement"
                                  labelComponent={HiddenLabel}
                                  noBorder={false}
                                  placeholder="Select services agreement"
                                  options={concat(
                                    NO_SERVICES_AGREEMENT_OPTION,
                                    groupedOptions
                                  )}
                                  formatGroupLabel={(data) => (
                                    <div className="select-group-label">
                                      <span>{data.label}</span>
                                    </div>
                                  )}
                                />
                                {selectedServicesAgreement && (
                                  <Link
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    className="link-text"
                                    to={`${Path.SERVICES_AGREEMENTS}/${selectedServicesAgreement.serviceAgreementID}`}
                                    aria-label={`View Services Agreement details for ${selectedServicesAgreement.name}`}
                                  >
                                    View services agreement details
                                  </Link>
                                )}
                              </div>
                            </div>
                          </div>
                          {mixMatchedAllPlans && !selectedServicesAgreement && (
                            <div className="plan-warning">
                              <Notification type={NotificationVariant.WARNING}>
                                <p className="text-title--small">
                                  Selecting "No services agreement found" means
                                  this plan won’t be included in your file
                                  submission. If you want to submit this plan,
                                  you’ll need to provide a signed agreement.
                                </p>
                              </Notification>
                            </div>
                          )}
                        </div>
                      </dl>
                    </div>
                  )
                })}
              {formType === 'BD' ? <BDFlowActions /> : <AroFlowActions />}
              {showPopup && newDiscardedParticipants.length > 0 && (
                <div className="discarded-popup-overlay">
                  <div className="discarded-plans-popup">
                    <div>
                      <h1>Discard participants from the following plans?</h1>
                    </div>
                    <p>
                      Participants from plans without matched agreements will be
                      discarded. You can download a list of these participants
                      after completing the process.
                    </p>
                    <ul className="discarded-plans">
                      {newDiscardedParticipants.map((plan) => {
                        return (
                          <li className="discarded-plan" key={plan[0]}>
                            {plan[1].planName}
                          </li>
                        )
                      })}
                    </ul>
                    <div className="button-section">
                      <button
                        className="button-text"
                        onClick={() => setShowPopup(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="button-primary"
                        onClick={() =>
                          onSubmit(values, newDiscardedParticipants)
                        }
                      >
                        Discard participants
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </ScrollErrorForm>
          )
        }}
      </Formik>
    </div>
  )
}

PlanMappingForm.propTypes = exact(propTypes)
PlanMappingForm.defaultProps = defaultProps

export default React.memo(PlanMappingForm)
