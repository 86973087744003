import React from 'react'
import { useFormikContext } from 'formik'
import { map, get } from 'lodash'
import { Select, Input, MaskedInput } from 'components'
import { COUNTRIES, US_STATES, US_COUNTRY_VALUE } from 'config/locations'
import { findUSState } from 'utils'

const US_STATE_OPTIONS = map(US_STATES, (state) => {
  const { value, fullName } = state
  return { key: fullName, value }
})
const COUNTRY_OPTIONS = map(COUNTRIES, (country) => {
  const { value, fullName } = country
  return { key: fullName, value }
})

const AddressRootField = {
  STREET: 'Street',
  CITY: 'City',
  STATE: 'State',
  POSTAL_CODE: 'PostalCode',
  COUNTRY: 'Country',
}

function AddressFields({ detailsType, addressType }) {
  const { values, setFieldValue, setFieldError, setFieldTouched } =
    useFormikContext()

  const resetField = (field) => {
    setFieldValue(field, '')
    setFieldError(field, '')
    setFieldTouched(field, false, false)
  }

  const prefix = addressType === 'company' ? 'billing' : 'mailing'
  const fieldNamePrefix = `${detailsType}.${addressType}.${prefix}`

  const isCountryUs =
    get(values, fieldNamePrefix + AddressRootField.COUNTRY) === US_COUNTRY_VALUE
  return (
    <>
      <div className="form-column full-row">
        <Input
          name={fieldNamePrefix + AddressRootField.STREET}
          label="Street address"
          placeholder="Enter street address"
        />
      </div>
      <div className="form-column">
        <Select
          name={fieldNamePrefix + AddressRootField.COUNTRY}
          label="Country"
          placeholder="Select country"
          options={COUNTRY_OPTIONS}
          onChange={(e) => {
            const selectedValue = e.target.value
            if (selectedValue !== US_COUNTRY_VALUE) {
              resetField(`${fieldNamePrefix}${AddressRootField.STATE}`)
            } else if (selectedValue === US_COUNTRY_VALUE) {
              const state = findUSState(
                get(values, fieldNamePrefix + AddressRootField.STATE)
              )
              if (state) {
                setFieldValue(
                  `${fieldNamePrefix}${AddressRootField.STATE}`,
                  state.value
                )
              } else {
                resetField(`${fieldNamePrefix}${AddressRootField.STATE}`)
              }
            }
          }}
        />
      </div>
      <div className="form-column">
        {isCountryUs ? (
          <Select
            name={fieldNamePrefix + AddressRootField.STATE}
            label="State/Province/Region"
            placeholder="Select State/Province/Region"
            options={US_STATE_OPTIONS}
          />
        ) : (
          <Input
            name={fieldNamePrefix + AddressRootField.STATE}
            label="State/Province/Region"
            placeholder="Enter State/Province/Region"
          />
        )}
      </div>
      <div className="form-column">
        {isCountryUs ? (
          <MaskedInput
            name={fieldNamePrefix + AddressRootField.POSTAL_CODE}
            label="Zip code"
            placeholder="Enter zip code"
            maskOptions={{
              numericOnly: true,
              blocks: [5, 0, 4],
              delimiters: ['', '-'],
            }}
          />
        ) : (
          <Input
            name={fieldNamePrefix + AddressRootField.POSTAL_CODE}
            label="Zip code"
            placeholder="Enter zip code"
          />
        )}
      </div>
      <div className="form-column">
        <Input
          name={fieldNamePrefix + AddressRootField.CITY}
          label="City"
          placeholder="Enter city"
        />
      </div>
    </>
  )
}
export default AddressFields
