export const US_STATES = [
  { value: 'AK', label: 'AK', fullName: 'Alaska' },
  { value: 'AL', label: 'AL', fullName: 'Alabama' },
  { value: 'AR', label: 'AR', fullName: 'Arkansas' },
  // { value: 'AB', label: 'AB', fullName: '' },
  { value: 'AZ', label: 'AZ', fullName: 'Arizona' },
  // { value: 'BC', label: 'BC', fullName: '' },
  { value: 'CA', label: 'CA', fullName: 'California' },
  { value: 'CO', label: 'CO', fullName: 'Colorado' },
  { value: 'CT', label: 'CT', fullName: 'Connecticut' },
  { value: 'DC', label: 'DC', fullName: 'District of Columbia' },
  { value: 'DE', label: 'DE', fullName: 'Delaware' },
  { value: 'FL', label: 'FL', fullName: 'Florida' },
  { value: 'GA', label: 'GA', fullName: 'Georgia' },
  // { value: 'GU', label: 'GU', fullName: 'Guam' },
  { value: 'HI', label: 'HI', fullName: 'Hawaii' },
  { value: 'ID', label: 'ID', fullName: 'Idaho' },
  { value: 'IL', label: 'IL', fullName: 'Illinois' },
  { value: 'IN', label: 'IN', fullName: 'Indiana' },
  { value: 'IA', label: 'IA', fullName: 'Iowa' },
  { value: 'KS', label: 'KS', fullName: 'Kansas' },
  { value: 'KY', label: 'KY', fullName: 'Kentucky' },
  { value: 'LA', label: 'LA', fullName: 'Louisiana' },
  { value: 'ME', label: 'ME', fullName: 'Maine' },
  // { value: 'MB', label: 'MB', fullName: '' },
  { value: 'MD', label: 'MD', fullName: 'Maryland' },
  { value: 'MA', label: 'MA', fullName: 'Massachusetts' },
  { value: 'MI', label: 'MI', fullName: 'Michigan' },
  { value: 'AA', label: 'AA', fullName: 'Armed Forces Americas' },
  { value: 'AE', label: 'AE', fullName: 'Armed Forces Europe' },
  { value: 'AP', label: 'AP', fullName: 'Armed Forces Pacific' },
  { value: 'MN', label: 'MN', fullName: 'Minnesota' },
  { value: 'MS', label: 'MS', fullName: 'Mississippi' },
  { value: 'MO', label: 'MO', fullName: 'Missouri' },
  { value: 'MT', label: 'MT', fullName: 'Montana' },
  { value: 'NE', label: 'NE', fullName: 'Nebraska' },
  { value: 'NV', label: 'NV', fullName: 'Nevada' },
  // { value: 'NB', label: 'NB', fullName: '' },
  { value: 'NH', label: 'NH', fullName: 'New Hampshire' },
  { value: 'NJ', label: 'NJ', fullName: 'New Jersey' },
  { value: 'NM', label: 'NM', fullName: 'New Mexico' },
  { value: 'NY', label: 'NY', fullName: 'New York' },
  // { value: 'NL', label: 'NL', fullName: '' },
  { value: 'NC', label: 'NC', fullName: 'North Carolina' },
  { value: 'ND', label: 'ND', fullName: 'North Dakota' },
  // { value: 'NT', label: 'NT', fullName: '' },
  // { value: 'NS', label: 'NS', fullName: '' },
  // { value: 'NU', label: 'NU', fullName: '' },
  { value: 'OH', label: 'OH', fullName: 'Ohio' },
  { value: 'OK', label: 'OK', fullName: 'Oklahoma' },
  // { value: 'ON', label: 'ON', fullName: '' },
  { value: 'OR', label: 'OR', fullName: 'Oregon' },
  { value: 'PA', label: 'PA', fullName: 'Pennsylvania' },
  // { value: 'PE', label: 'PE', fullName: '' },
  { value: 'PR', label: 'PR', fullName: 'Puerto Rico' },
  // { value: 'QC', label: 'QC', fullName: '' },
  { value: 'RI', label: 'RI', fullName: 'Rhode Island' },
  // { value: 'SK', label: 'SK', fullName: '' },
  { value: 'SC', label: 'SC', fullName: 'South Carolina' },
  { value: 'SD', label: 'SD', fullName: 'South Dakota' },
  { value: 'TN', label: 'TN', fullName: 'Tennessee' },
  { value: 'TX', label: 'TX', fullName: 'Texas' },
  { value: 'UT', label: 'UT', fullName: 'Utah' },
  { value: 'VT', label: 'VT', fullName: 'Vermont' },
  // { value: 'VI', label: 'VI', fullName: 'Virgin Islands' },
  { value: 'VA', label: 'VA', fullName: 'Virginia' },
  { value: 'WA', label: 'WA', fullName: 'Washington' },
  { value: 'WV', label: 'WV', fullName: 'West Virginia' },
  { value: 'WI', label: 'WI', fullName: 'Wisconsin' },
  { value: 'WY', label: 'WY', fullName: 'Wyoming' },
  // { value: 'YT', label: 'YT', fullName: '' },
]
export const US_COUNTRY_VALUE = 'US'
export const CANADA_COUNTRY_VALUE = 'CA'
export const COUNTRIES = [
  { value: 'AF', fullName: 'Afghanistan' },
  { value: 'AX', fullName: 'Aland Islands' },
  { value: 'AL', fullName: 'Albania' },
  { value: 'DZ', fullName: 'Algeria' },
  { value: 'AS', fullName: 'American Samoa' },
  { value: 'AO', fullName: 'Angola' },
  { value: 'AI', fullName: 'Anguilla' },
  { value: 'AQ', fullName: 'Antarctica' },
  { value: 'AG', fullName: 'Antigua & Barbuda' },
  { value: 'AR', fullName: 'Argentina' },
  { value: 'AM', fullName: 'Armenia' },
  { value: 'AW', fullName: 'Aruba' },
  { value: 'AU', fullName: 'Australia' },
  { value: 'AT', fullName: 'Austria' },
  { value: 'AZ', fullName: 'Azerbaidjan' },
  { value: 'WI', fullName: 'BRITISH WEST INDIES' },
  { value: 'BF', fullName: 'Bahamas' },
  { value: 'BH', fullName: 'Bahrain' },
  { value: 'BD', fullName: 'Bangladesh' },
  { value: 'BB', fullName: 'Barbados' },
  { value: 'BY', fullName: 'Belarus' },
  { value: 'BE', fullName: 'Belgium' },
  { value: 'BZ', fullName: 'Belize' },
  { value: 'BJ', fullName: 'Benin' },
  { value: 'BM', fullName: 'Bermuda' },
  { value: 'BT', fullName: 'Bhutan' },
  { value: 'BO', fullName: 'Bolivia' },
  { value: 'BA', fullName: 'Bosnia & Herzegovina' },
  { value: 'BW', fullName: 'Botswana' },
  { value: 'BV', fullName: 'Bouvet Island' },
  { value: 'BR', fullName: 'Brazil' },
  { value: 'IO', fullName: 'British Indian Ocean Territory' },
  { value: 'BN', fullName: 'Brunei' },
  { value: 'BG', fullName: 'Bulgaria' },
  { value: 'BU', fullName: 'Burma' },
  { value: 'BI', fullName: 'Burundi' },
  { value: 'KH', fullName: 'Cambodia' },
  { value: 'CM', fullName: 'Cameroon' },
  { value: CANADA_COUNTRY_VALUE, fullName: 'Canada' },
  { value: 'CV', fullName: 'Cape Verde' },
  { value: 'KY', fullName: 'Cayman Islands' },
  { value: 'CF', fullName: 'Central African Republic' },
  { value: 'CL', fullName: 'Chile' },
  { value: 'CN', fullName: 'China' },
  { value: 'CX', fullName: 'Christmas Island' },
  { value: 'CO', fullName: 'Colombia' },
  { value: 'KM', fullName: 'Comoros' },
  { value: 'CG', fullName: 'Congo' },
  { value: 'CD', fullName: 'Congo, Democratic Republic' },
  { value: 'CK', fullName: 'Cook Islands' },
  { value: 'CR', fullName: 'Costa Rica' },
  { value: 'HR', fullName: 'Croatia' },
  { value: 'CU', fullName: 'Cuba' },
  { value: 'CC', fullName: 'Curacao' },
  { value: 'CY', fullName: 'Cyprus' },
  { value: 'CZ', fullName: 'Czech Republic' },
  { value: 'CS', fullName: 'Czechoslovakia' },
  { value: 'DK', fullName: 'Denmark' },
  { value: 'DJ', fullName: 'Djibouti' },
  { value: 'DM', fullName: 'Dominica' },
  { value: 'DO', fullName: 'Dominican Republic' },
  { value: 'ER', fullName: 'Ecuador' },
  { value: 'EG', fullName: 'Egypt' },
  { value: 'SV', fullName: 'El Salvador' },
  { value: 'GQ', fullName: 'Equatorial Guinea' },
  { value: 'Ee', fullName: 'Estonia' },
  { value: 'ET', fullName: 'Ethiopia' },
  { value: 'EU', fullName: 'Euro' },
  { value: 'EC', fullName: 'European Currency Unit' },
  { value: 'XE', fullName: 'European Institutions' },
  { value: 'FK', fullName: 'Falkland Islands' },
  { value: 'FO', fullName: 'Faroe Islands' },
  { value: 'FJ', fullName: 'Fiji' },
  { value: 'FL', fullName: 'Finland' },
  { value: 'FR', fullName: 'France' },
  { value: 'GF', fullName: 'French Guiana' },
  { value: 'PF', fullName: 'French Polynesia' },
  { value: 'TF', fullName: 'French Southern Territories' },
  { value: 'GA', fullName: 'Gabon' },
  { value: 'GM', fullName: 'Gambia' },
  { value: 'GE', fullName: 'Georgia' },
  { value: 'DE', fullName: 'Germany' },
  { value: 'GH', fullName: 'Ghana' },
  { value: 'GI', fullName: 'Gibraltar' },
  { value: 'GB', fullName: 'Great Britian' },
  { value: 'GR', fullName: 'Greece' },
  { value: 'GL', fullName: 'Greenland' },
  { value: 'GD', fullName: 'Grenada' },
  { value: 'GP', fullName: 'Guadeloupe (French)' },
  { value: 'GU', fullName: 'Guam' },
  { value: 'GT', fullName: 'Guatemala' },
  { value: 'GG', fullName: 'Guernsey' },
  { value: 'GN', fullName: 'Guinea' },
  { value: 'GW', fullName: 'Guinea-Bissau' },
  { value: 'GY', fullName: 'Guyana' },
  { value: 'HT', fullName: 'Haiti' },
  { value: 'HM', fullName: 'Heard Island & McDonald Island' },
  { value: 'NL', fullName: 'Holland' },
  { value: 'VA', fullName: 'Holy See (Vatican)' },
  { value: 'HN', fullName: 'Honduras' },
  { value: 'HK', fullName: 'Hong Kong' },
  { value: 'HU', fullName: 'Hungary' },
  { value: 'IS', fullName: 'Iceland' },
  { value: 'IN', fullName: 'India' },
  { value: 'ID', fullName: 'Indonesia' },
  { value: 'IR', fullName: 'Iran' },
  { value: 'IQ', fullName: 'Iraq' },
  { value: 'IE', fullName: 'Ireland' },
  { value: 'IM', fullName: 'Isle of Man' },
  { value: 'IL', fullName: 'Israel' },
  { value: 'IT', fullName: 'Italy' },
  { value: 'CI', fullName: 'Ivory Coast' },
  { value: 'JM', fullName: 'Jamaica' },
  { value: 'JP', fullName: 'Japan' },
  { value: 'JE', fullName: 'Jersey C.I.' },
  { value: 'JO', fullName: 'Jordan' },
  { value: 'KZ', fullName: 'Kazakhstan' },
  { value: 'KE', fullName: 'Kenya' },
  { value: 'KI', fullName: 'Kiribati' },
  { value: 'KR', fullName: 'Korea' },
  { value: 'KW', fullName: 'Kuwait' },
  { value: 'KG', fullName: 'Kyrgyzstan' },
  { value: 'LA', fullName: 'Laos' },
  { value: 'LV', fullName: 'Latvia' },
  { value: 'LB', fullName: 'Lebanon' },
  { value: 'LS', fullName: 'Lesotho' },
  { value: 'LR', fullName: 'Liberia' },
  { value: 'LI', fullName: 'Liechtenstein' },
  { value: 'LT', fullName: 'Lithuania' },
  { value: 'LU', fullName: 'Luxembourg' },
  { value: 'LY', fullName: 'Lybia' },
  { value: 'MO', fullName: 'Macao' },
  { value: 'MK', fullName: 'Macedonia' },
  { value: 'MG', fullName: 'Madagascar' },
  { value: 'MW', fullName: 'Malawi' },
  { value: 'MY', fullName: 'Malaysia' },
  { value: 'MV', fullName: 'Maldives' },
  { value: 'ML', fullName: 'Mali' },
  { value: 'MT', fullName: 'Maltax' },
  { value: 'MH', fullName: 'Marshall Islands' },
  { value: 'MQ', fullName: 'Martinique' },
  { value: 'MR', fullName: 'Mauritania' },
  { value: 'MU', fullName: 'Mauritius' },
  { value: 'YT', fullName: 'Mayotte' },
  { value: 'MX', fullName: 'Mexico' },
  { value: 'FM', fullName: 'Micronesia' },
  { value: 'MD', fullName: 'Moldova' },
  { value: 'MC', fullName: 'Monaco' },
  { value: 'MN', fullName: 'Mongolia' },
  { value: 'ME', fullName: 'Montenegro' },
  { value: 'MS', fullName: 'Montserrat' },
  { value: 'MA', fullName: 'Morocco' },
  { value: 'MZ', fullName: 'Mozambique' },
  { value: 'YY', fullName: 'Multinational' },
  { value: 'MM', fullName: 'Myanmar (Burma)' },
  { value: 'NA', fullName: 'Namibia' },
  { value: 'XO', fullName: 'Nato, Shape' },
  { value: 'NR', fullName: 'Nauru' },
  { value: 'NP', fullName: 'Nepal' },
  { value: 'NV', fullName: 'Netherlands' },
  { value: 'AN', fullName: 'Netherlands Antilles' },
  { value: 'NC', fullName: 'New Caledonia' },
  { value: 'NZ', fullName: 'New Zealand' },
  { value: 'NI', fullName: 'Nicaragua' },
  { value: 'NE', fullName: 'Niger' },
  { value: 'NG', fullName: 'Nigeria' },
  { value: 'NU', fullName: 'Niue' },
  { value: 'NF', fullName: 'Norfolk Island' },
  { value: 'KP', fullName: 'North Korea' },
  { value: 'MP', fullName: 'Northern Mariana Islands' },
  { value: 'NO', fullName: 'Norway' },
  { value: 'OM', fullName: 'Oman' },
  { value: 'OT', fullName: 'Other' },
  { value: 'YD', fullName: 'P.D.R. Of The Yemen' },
  { value: 'PK', fullName: 'Pakistan' },
  { value: 'PW', fullName: 'Palau' },
  { value: 'PS', fullName: 'Palestinian Territory' },
  { value: 'PA', fullName: 'Panama' },
  { value: 'PG', fullName: 'Papua New Guinea' },
  { value: 'PY', fullName: 'Paraguay' },
  { value: 'PE', fullName: 'Peru' },
  { value: 'PH', fullName: 'Philippines' },
  { value: 'PN', fullName: 'Pitcairn' },
  { value: 'PL', fullName: 'Poland' },
  { value: 'PT', fullName: 'Portugal' },
  { value: 'PR', fullName: 'Puerto Rico' },
  { value: 'QA', fullName: 'Qatar' },
  { value: 'RE', fullName: 'Reunion' },
  { value: 'RH', fullName: 'Rhodesia' },
  { value: 'RO', fullName: 'Rumainia' },
  { value: 'RU', fullName: 'Russian Federation' },
  { value: 'RW', fullName: 'Rwanda' },
  { value: 'BL', fullName: 'Saint Barthelemy' },
  { value: 'SH', fullName: 'Saint Helena' },
  { value: 'KN', fullName: 'Saint Kitts & Nevis' },
  { value: 'LC', fullName: 'Saint Lucia' },
  { value: 'MF', fullName: 'Saint Martin' },
  { value: 'PM', fullName: 'Saint Pierre & Miquelon' },
  { value: 'VC', fullName: 'Saint Vincent & The Grenadines' },
  { value: 'WS', fullName: 'Samoa (see Western Samoa)' },
  { value: 'SM', fullName: 'San Marino' },
  { value: 'ST', fullName: 'Sao Tome & Principe' },
  { value: 'SA', fullName: 'Saudi Arabia' },
  { value: 'SN', fullName: 'Senegal' },
  { value: 'RS', fullName: 'Serbia' },
  { value: 'SC', fullName: 'Seychelles' },
  { value: 'SL', fullName: 'Sierra Leone' },
  { value: 'SG', fullName: 'Singapore' },
  { value: 'SK', fullName: 'Slovakia' },
  { value: 'SI', fullName: 'Slovenia' },
  { value: 'SB', fullName: 'Solomon Islands' },
  { value: 'SO', fullName: 'Somalia' },
  { value: 'ZA', fullName: 'South Africa' },
  { value: 'GS', fullName: 'South Georgia & Sandwich Ils.' },
  { value: 'ES', fullName: 'Spain' },
  { value: 'LK', fullName: 'Sri Lanka' },
  { value: 'SD', fullName: 'Sudan' },
  { value: 'XS', fullName: 'Supranational' },
  { value: 'SR', fullName: 'Surinam' },
  { value: 'SJ', fullName: 'Svalbard & Jan Mayen' },
  { value: 'SZ', fullName: 'Swaziland' },
  { value: 'SW', fullName: 'Sweden' },
  { value: 'CH', fullName: 'Switzerland' },
  { value: 'SY', fullName: 'Syria' },
  { value: 'TW', fullName: 'Taiwan' },
  { value: 'TJ', fullName: 'Tajikstan' },
  { value: 'TZ', fullName: 'Tanzania' },
  { value: 'TD', fullName: 'Tchad' },
  { value: 'TH', fullName: 'Thailand' },
  { value: 'TL', fullName: 'Timor-Leste' },
  { value: 'TG', fullName: 'Togo' },
  { value: 'TK', fullName: 'Tokelau' },
  { value: 'TO', fullName: 'Tonga' },
  { value: 'TT', fullName: 'Trinidad and Tobago' },
  { value: 'TN', fullName: 'Tunisia' },
  { value: 'TR', fullName: 'Turkey' },
  { value: 'TM', fullName: 'Turkmenistan' },
  { value: 'TC', fullName: 'Turks & Caicos Islands' },
  { value: 'TV', fullName: 'Tuvalu' },
  { value: 'VI', fullName: 'US VIRGIN ISLANDS' },
  { value: 'UG', fullName: 'Uganda' },
  { value: 'UA', fullName: 'Ukraine' },
  { value: 'AE', fullName: 'United Arab Emirates' },
  { value: 'UK', fullName: 'United Kingdom' },
  { value: US_COUNTRY_VALUE, fullName: 'United States' },
  { value: 'HV', fullName: 'Upper Volta' },
  { value: 'UY', fullName: 'Uruguay' },
  { value: 'UZ', fullName: 'Uzbekistan' },
  { value: 'VU', fullName: 'Vanuatu' },
  { value: 'VE', fullName: 'Venezuela' },
  { value: 'VN', fullName: 'Vietnam' },
  { value: 'VG', fullName: 'Virgin Islands, British' },
  { value: 'WF', fullName: 'Wallis & Futuna' },
  { value: 'EH', fullName: 'Western Sahara' },
  { value: 'YE', fullName: 'Yemen' },
  { value: 'YU', fullName: 'Yugoslavia' },
  { value: 'ZR', fullName: 'Zaire' },
  { value: 'ZM', fullName: 'Zambia' },
  { value: 'ZW', fullName: 'Zimbabwe' },
]

export const COUNTRY_ALIAS_DICT = {
  GUADELOUPE: 'GP',
  'HOLY SEE': 'VA',
  VATICAN: 'VA',
  SAMOA: 'WS',
  'WESTERN SAMOA': 'WS',
  USA: US_COUNTRY_VALUE,
  'UNITED STATES OF AMERICA': US_COUNTRY_VALUE,
  UAE: 'AE',
}
export const US_ZIP_CODE_REGEX = /^[0-9]{5}(?:-[0-9]{4})?$/
