import { isValid, parseISO, isDate } from 'date-fns'

function parseDateFromString(dateStr, { ignoreTimezone = true } = {}) {
  if (!dateStr) return
  if (isDate(dateStr)) return dateStr

  let parsedDate

  if (hasISOFormatting(dateStr)) {
    const stringToParse = ignoreTimezone ? dateStr.split('T')[0] : dateStr
    parsedDate = parseISO(stringToParse)
  } else {
    parsedDate = new Date(dateStr)
  }

  if (!isValid(parsedDate)) return

  return parsedDate
}

// ----- PRIVATE ----
function hasISOFormatting(str) {
  return /^\d{4}[-/]/.test(str)
}

export default parseDateFromString
