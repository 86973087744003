import React from 'react'
import { Formik } from 'formik'
import { ScrollErrorForm, RadioGroup } from 'components'
import * as Yup from 'yup'
import { map } from 'lodash'
import { FlowActions } from '../components'
import PropTypes from 'prop-types'
import { Icon as DeductFromDistributionIcon } from 'images/icon-container.svg'
import { Icon as CreditCardIcon } from 'images/credit-card.svg'

const propTypes = {
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  paymentMethods: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ).isRequired,
}

function getIcon(option) {
  switch (option) {
    case 'deductFromDistribution':
      return DeductFromDistributionIcon
    case 'creditCard':
      return CreditCardIcon
    default:
      return () => null
  }
}

function PaymentMethodOptionLabel({ htmlFor, option }) {
  const PaymentMethodIcon = getIcon(option.key)
  return (
    <label htmlFor={htmlFor} className="select-payment-method-container">
      <div className="icon-container">
        <PaymentMethodIcon aria-hidden="true" />
      </div>
      <div className="description-container">
        <p className="label">{option.value}</p>
        <p className="description">{option.description}</p>
      </div>
    </label>
  )
}

function SelectPaymentMethodForm({ initialValues, onSubmit, paymentMethods }) {
  const paymentMethodsOptions = map(paymentMethods, 'value')
  const paymentMethodSchema = Yup.object({
    selectedPaymentMethod: Yup.string()
      .oneOf(paymentMethodsOptions, 'required.')
      .required('Select a payment method.'),
  })

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={paymentMethodSchema}
    >
      {() => (
        <ScrollErrorForm>
          <div className="form-section">
            <div className="form-header">
              <h3 id="select-payment-method">Select a payment method.</h3>
            </div>
            <RadioGroup
              name="selectedPaymentMethod"
              label={false}
              ariaLabelledby="select-payment-method"
              options={paymentMethods}
              className="card-radio-group"
              radioInputProps={{
                labelComponent: ({ htmlFor, option }) => {
                  return (
                    <PaymentMethodOptionLabel
                      htmlFor={htmlFor}
                      option={option}
                    />
                  )
                },
              }}
            />
          </div>
          <FlowActions submitContent="Continue" preventBack />
        </ScrollErrorForm>
      )}
    </Formik>
  )
}

SelectPaymentMethodForm.propTypes = propTypes

export default SelectPaymentMethodForm
