import { sumBy } from 'lodash'
import { BD_TRSANCTION_INFO } from 'flatfile-config'

// Add up participants plan summary data (used in ARO participant file flow)
// summaryData has the following shape: [ { planName: /string/, participants: /number/, traditionalIraPreTaxAmount: /number/,
// traditionalIraAfterTaxAmount: /number/, rothIraAmount: /number/ } ]
function sumUpParticipantsSummaryData(summaryData) {
  return {
    totalPlans: summaryData.length,
    totalParticipants: sumBy(summaryData, 'participants'),
    [BD_TRSANCTION_INFO.AMOUNT]: sumBy(summaryData, BD_TRSANCTION_INFO.AMOUNT),
    [BD_TRSANCTION_INFO.INSPIRA_BD_PROCESSING_FEE]: sumBy(
      summaryData,
      BD_TRSANCTION_INFO.INSPIRA_BD_PROCESSING_FEE
    ),
    [BD_TRSANCTION_INFO.TPA_BD_PROCESSING_FEE]: sumBy(
      summaryData,
      BD_TRSANCTION_INFO.TPA_BD_PROCESSING_FEE
    ),
    [BD_TRSANCTION_INFO.GROSS_DISTRIBUTION_AMOUNT]: sumBy(
      summaryData,
      BD_TRSANCTION_INFO.GROSS_DISTRIBUTION_AMOUNT
    ),
  }
}

export default sumUpParticipantsSummaryData
