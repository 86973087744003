import React from 'react'
import PropTypes from 'prop-types'
import { noop } from 'lodash'
import classnames from 'classnames'

const propTypes = {
  currentPage: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  alwaysShow: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number.isRequired,
  numRecords: PropTypes.number.isRequired,
  startRecordIndex: PropTypes.number.isRequired,
  endRecordIndex: PropTypes.number.isRequired,
}

const defaultProps = {
  alwaysShow: false,
  min: 1,
  max: 1,
}

function Nav({ children }) {
  return (
    <nav className="nav-pagination" aria-label="pagination">
      {children}
    </nav>
  )
}

function EmptyState() {
  return (
    <Nav>
      <ul />
    </Nav>
  )
}

function PageLink({
  onClick = noop,
  className = '',
  linkDisabled = false,
  children,
  ...rest
}) {
  return (
    <li className={className}>
      <a
        className={classnames({ 'disabled-link': linkDisabled })}
        onClick={linkDisabled ? noop : onClick}
        onKeyDown={(e) => {
          e.key === 'Enter' && !linkDisabled ? onClick() : noop()
        }}
        tabIndex="0"
        {...rest}
      >
        {children}
      </a>
    </li>
  )
}

function Paginator({
  currentPage,
  onChange,
  alwaysShow,
  min,
  max,
  numRecords,
  startRecordIndex,
  endRecordIndex,
}) {
  const totalPages = max - min + 1
  if (totalPages === 1 && !alwaysShow) return <EmptyState />

  return (
    <div className="paginator-container pagination">
      <div className="record-count-container">
        <p>{`${startRecordIndex}-${endRecordIndex} of ${numRecords}`}</p>
      </div>
      <Nav>
        <ul>
          {/* Skip to First Page */}
          <PageLink
            className="first"
            onClick={() => onChange(min)}
            aria-label="First page"
            linkDisabled={currentPage === min}
          />

          {/* Previous Page */}
          <PageLink
            className="prev"
            onClick={() => onChange(currentPage - 1)}
            aria-label="Previous page"
            linkDisabled={currentPage === min}
          />

          {/* Next Page */}
          <PageLink
            className="next"
            onClick={() => onChange(currentPage + 1)}
            aria-label="Next page"
            linkDisabled={currentPage === max}
          />

          {/* Skip to Last Page */}
          <PageLink
            className="last"
            onClick={() => onChange(max)}
            aria-label="Last page"
            linkDisabled={currentPage === max}
          />
        </ul>
      </Nav>
    </div>
  )
}

Paginator.propTypes = propTypes
Paginator.defaultProps = defaultProps

export default React.memo(Paginator)
