import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect } from 'react-router-dom'
import * as Views from './views'
import * as BDAddendumViews from './bd-addendum-flow/views'
import Layout from './Layout'
import {
  CreateNewServicesAgreementStage,
  Path,
  BDActionItems,
  CREATE_NEW_SERVICES_AGREEMENT_STAGES,
  isBenefitDistributionsAgreementsEnabled,
  CreateNewAddendumStage,
  NEW_ADDENDUM_STAGES,
  PARTICIPANT_FILE_UPLOAD_STAGES_BD,
  ParticipantFileUploadStageBD,
} from 'config/portal'

const propTypes = {
  match: PropTypes.object.isRequired,
}
const defaultProps = {}

const PARTICIPANT_FILE_UPLOAD_HEADER = BDActionItems.INITIATE_DISTRIBUTION

function Routes({ match: { path } }) {
  // window.appEventData.pop()
  // var appEventData = window.appEventData || []
  // appEventData.push({
  //   event: 'Page Load Completed',
  // })
  const addendumStages = NEW_ADDENDUM_STAGES
  return (
    <Switch>
      {isBenefitDistributionsAgreementsEnabled() && (
        <Route
          path={path + '/add-new-services-agreement'}
          render={() => (
            <Layout
              title={BDActionItems.CREATE_NEW_SERVICES_AGREEMENT}
              subject="services agreement"
              currentStage={
                CreateNewServicesAgreementStage.ADD_NEW_SERVICES_AGREEMENT
              }
              stages={CREATE_NEW_SERVICES_AGREEMENT_STAGES}
            >
              <Views.AddNewServicesAgreementView />
            </Layout>
          )}
        />
      )}
      <Route
        path={path + '/add-new-addendum'}
        render={() => (
          <Layout
            subject="addendum"
            title={'Create new addendum'}
            stages={addendumStages}
            currentStage={CreateNewAddendumStage.agreementSelection}
          >
            <BDAddendumViews.AddNewAddendumView />
          </Layout>
        )}
      />
      <Route
        path={path + '/review-and-submit-addendum'}
        render={() => (
          <Layout
            subject="addendum"
            title={'Create new addendum'}
            stages={addendumStages}
            currentStage={CreateNewAddendumStage.reviewAndSubmit}
          >
            <BDAddendumViews.ReviewAndSubmitAddendumView />
          </Layout>
        )}
      />
      <Route
        path={path + '/select-your-information'}
        render={() => (
          <Layout
            title={BDActionItems.CREATE_NEW_SERVICES_AGREEMENT}
            subject="services agreement"
            currentStage={
              CreateNewServicesAgreementStage.SELECT_COMPANY_INFORMATION
            }
            stages={CREATE_NEW_SERVICES_AGREEMENT_STAGES}
          >
            <Views.SelectCompanyInformationView />
          </Layout>
        )}
      />
      <Route
        path={path + '/complete-services-agreement'}
        render={() => (
          <Layout
            title={BDActionItems.CREATE_NEW_SERVICES_AGREEMENT}
            subject="services agreement"
            currentStage={
              CreateNewServicesAgreementStage.AGREEMENT_DETAILS_AND_SUBMIT
            }
            stages={CREATE_NEW_SERVICES_AGREEMENT_STAGES}
          >
            <Views.CompletePlanAgreementView />
          </Layout>
        )}
      />
      <Route
        path={path + '/select-payment-method'}
        render={() => (
          <Layout
            title={PARTICIPANT_FILE_UPLOAD_HEADER}
            subject="participant file upload"
            currentStage={ParticipantFileUploadStageBD.selectPaymentMethod}
            stages={PARTICIPANT_FILE_UPLOAD_STAGES_BD}
          >
            <Views.SelectPaymentMethodView />
          </Layout>
        )}
      />
      <Route
        path={path + '/upload-file'}
        render={() => (
          <Layout
            title={PARTICIPANT_FILE_UPLOAD_HEADER}
            subject="participant file upload"
            currentStage={ParticipantFileUploadStageBD.participantFileUpload}
            stages={PARTICIPANT_FILE_UPLOAD_STAGES_BD}
          >
            <Views.UploadParticipantFileView />
          </Layout>
        )}
      />
      <Route
        path={path + '/plan-mapping'}
        render={() => (
          <Layout
            title={PARTICIPANT_FILE_UPLOAD_HEADER}
            subject="participant file upload"
            currentStage={ParticipantFileUploadStageBD.planMapping}
            stages={PARTICIPANT_FILE_UPLOAD_STAGES_BD}
          >
            <Views.PlanMappingView />
          </Layout>
        )}
      />
      <Route
        path={path + '/plan-confirmation'}
        render={() => (
          <Layout
            title={PARTICIPANT_FILE_UPLOAD_HEADER}
            subject="participant file upload"
            currentStage={ParticipantFileUploadStageBD.planMapping}
            stages={PARTICIPANT_FILE_UPLOAD_STAGES_BD}
          >
            <Views.PlanConfirmationView />
          </Layout>
        )}
      />
      <Route
        path={path + '/review-and-submit-participant-data'}
        render={() => (
          <Layout
            title={PARTICIPANT_FILE_UPLOAD_HEADER}
            subject="participant file upload"
            currentStage={ParticipantFileUploadStageBD.reviewAndSubmit}
            stages={PARTICIPANT_FILE_UPLOAD_STAGES_BD}
          >
            <Views.ReviewAndSubmitView />
          </Layout>
        )}
      />
      <Redirect path="*" to={Path.BD_SERVICES_AGREEMENTS} />
    </Switch>
  )
}

Routes.propTypes = propTypes
Routes.defaultProps = defaultProps

export default Routes
