import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import { setSessionToken, clearSessionToken } from '../services/auth'
import * as apiActions from 'api-actions'
import { TitleTag, IdleModal, SupportModal, FullPageSpinner } from 'components'
import { selectors as globalSelectors } from './reducer'
import { Redirect, useLocation } from 'react-router-dom'
import * as globalActions from './actions'
import { scrollToTop, setIdleTimer, getFeedbackUrlChanged } from 'utils'
import * as routerSelectors from 'connected-react-router'
import {
  REACT_APP_IDLE_TIMEOUT_DURATION,
  REACT_APP_IDLE_TIMEOUT_WARNING_DURATION,
  isFullStoryEnabled,
} from 'config/portal'
import { getTimeoutRedirect } from 'local-storage'
import { find } from 'lodash'
import * as FullStory from '@fullstory/browser'
import { useUpdateEffect } from 'react-use'

const propTypes = {
  children: PropTypes.node.isRequired,
  updateLastLogin: PropTypes.func.isRequired,
  fetchUserProfile: PropTypes.func.isRequired,
  fetchCompanyOwner: PropTypes.func.isRequired,
  companyOwner: PropTypes.object,
  currentUser: PropTypes.object,
  fetchServiceAgreements: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
  supportModalShown: PropTypes.bool.isRequired,
  setSupportModalShown: PropTypes.func.isRequired,
  showIdleModal: PropTypes.bool.isRequired,
  setShowIdleModal: PropTypes.func.isRequired,
}

const defaultProps = {
  currentUser: null,
  companyOwner: null,
}
function AuthorizedLayout({
  // isAuthenticated,
  children,
  updateLastLogin,
  companyOwner,
  currentUser,
  fetchUserProfile,
  fetchCompanyOwner,
  fetchServiceAgreements,
  pathname,
  supportModalShown,
  setSupportModalShown,
  showIdleModal,
  setShowIdleModal,
}) {
  const {
    isAuthenticated,
    loginWithRedirect,
    isLoading,
    getAccessTokenSilently,
  } = useAuth0()
  const [shouldRender, setShouldRender] = useState(false)
  const [fullPageError, setFullPageError] = useState(false)
  const [unauthorizedProfile, setUnauthorizedProfile] = useState(false)
  const fullStoryEnabled = isFullStoryEnabled()
  const location = useLocation()

  useEffect(() => {
    if (!isAuthenticated) return

    async function getUserData() {
      try {
        const accessToken = await getAccessTokenSilently()
        setSessionToken(accessToken)

        const userInfoResponse = await Promise.all([
          updateLastLogin(),
          fetchServiceAgreements(),
          fetchUserProfile(),
        ])

        // Allow companyOwner fetch to fail silently as it is not critical to the app
        if (!companyOwner) fetchCompanyOwner().catch(() => null)

        const authorizedUser = find(
          userInfoResponse,
          'retirementServicesPortalProfile'
        )
        if (!authorizedUser) setUnauthorizedProfile(true)

        setShouldRender(true)
        setIdleTimer({
          onIdle: () => setShowIdleModal(true),
          timeout: REACT_APP_IDLE_TIMEOUT_DURATION,
        })
      } catch {
        setFullPageError(true)
      }
    }

    getUserData()
  }, [isAuthenticated])

  // Capture user info for fullstory analytics
  useEffect(() => {
    if (!fullStoryEnabled || !currentUser || unauthorizedProfile) return

    const {
      contactID,
      firstName,
      lastName,
      userEmail,
      retirementServicesPortalProfile,
      accountAudience,
    } = currentUser
    const userInfo = {
      displayName: `${firstName} ${lastName}`,
      email: userEmail,
      audienceType: accountAudience,
      role: retirementServicesPortalProfile,
    }
    // Clear identity or release the current user
    FullStory.anonymize()
    FullStory.identify(contactID, userInfo)
  }, [currentUser, unauthorizedProfile, fullStoryEnabled])

  // Trigger GetFeedback virtual page views on URL change, while skipping on mount
  useUpdateEffect(() => {
    getFeedbackUrlChanged()
  }, [location])

  // Scroll to top of page when route changes
  useEffect(() => {
    scrollToTop()
  }, [pathname])

  if (unauthorizedProfile) return <Redirect to="/unauthorized-profile" />

  if (fullPageError) return <Redirect to="/error" />

  if (isLoading)
    return (
      <div className="unauthenticated-content">
        <FullPageSpinner />
      </div>
    )

  if (!isAuthenticated) {
    const { pathname, search, hash } = window.location
    clearSessionToken()
    const redirectBackTo = getTimeoutRedirect() || pathname + search + hash
    loginWithRedirect({
      appState: { redirectBackTo },
    })
    return null
  }

  if (!shouldRender)
    return (
      <div className="unauthenticated-content">
        <FullPageSpinner />
      </div>
    )

  return (
    <>
      <TitleTag pathname={window.location.pathname} />
      {children}
      {supportModalShown && (
        <SupportModal onClose={() => setSupportModalShown(false)} />
      )}
      {showIdleModal && (
        <IdleModal
          onContinue={() => {
            setIdleTimer({
              onIdle: () => setShowIdleModal(true),
              timeout: REACT_APP_IDLE_TIMEOUT_DURATION,
            })
            setShowIdleModal(false)
          }}
          timeoutDuration={REACT_APP_IDLE_TIMEOUT_WARNING_DURATION}
        />
      )}
    </>
  )
}

AuthorizedLayout.propTypes = exact(propTypes)
AuthorizedLayout.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    pathname: routerSelectors.getLocation(state).pathname,
    supportModalShown: globalSelectors.supportModalShown(state),
    showIdleModal: globalSelectors.showIdleModal(state),
    companyOwner: globalSelectors.companyOwner(state),
    currentUser: globalSelectors.currentUser(state),
  }
}

const mapDispatchToProps = {
  updateLastLogin: apiActions.updateLastLogin,
  fetchUserProfile: apiActions.fetchUserProfile,
  fetchCompanyOwner: apiActions.fetchCompanyOwner,
  fetchServiceAgreements: apiActions.fetchServiceAgreements,
  setSupportModalShown: globalActions.setSupportModalShown,
  setShowIdleModal: globalActions.setShowIdleModal,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AuthorizedLayout
)
