import React from 'react'
import PropTypes from 'prop-types'
import {
  Accordion as BaseAccordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import { noop } from 'lodash'

const propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      heading: PropTypes.string.isRequired,
      panel: PropTypes.element.isRequired,
      className: PropTypes.string,
    })
  ),
  allowZeroExpanded: PropTypes.bool,
  allowMultipleExpanded: PropTypes.bool,
  initialItemsExpanded: PropTypes.array,
  onChange: PropTypes.func,
  headingLevel: PropTypes.oneOf([2, 3, 4, 5, 6]),
}

const defaultProps = {
  allowZeroExpanded: true,
  allowMultipleExpanded: true,
  initialItemsExpanded: [],
  onChange: noop,
  headingLevel: 3,
}

function Accordion({
  items,
  allowZeroExpanded,
  allowMultipleExpanded,
  initialItemsExpanded,
  onChange,
  headingLevel,
}) {
  return (
    <BaseAccordion
      allowZeroExpanded={allowZeroExpanded}
      allowMultipleExpanded={allowMultipleExpanded}
      preExpanded={initialItemsExpanded}
      onChange={onChange}
    >
      {items.map((item) => (
        <AccordionItem
          className={item.className}
          uuid={item.uuid}
          key={item.heading}
        >
          <AccordionItemHeading aria-level={headingLevel}>
            <AccordionItemButton>{item.heading}</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>{item.panel}</AccordionItemPanel>
        </AccordionItem>
      ))}
    </BaseAccordion>
  )
}

Accordion.propTypes = propTypes
Accordion.defaultProps = defaultProps

export default Accordion
