import { useMemo } from 'react'
import { isEmpty, concat, sortBy } from 'lodash'
import {
  summarizeParticipantsDataByPlan,
  sumUpParticipantsSummaryData,
  createParticipantsSummaryOverviewRow,
} from 'utils'

// A hook that creates a summary of participants by plan for submission: used in ARO participant file flow
function useParticipantsSummary(participants) {
  const [participantsSummaryData, participantsSummaryOverview] = useMemo(() => {
    if (isEmpty(participants)) return []

    const summaryDataByPlan = summarizeParticipantsDataByPlan(participants)
    const summaryOverview = sumUpParticipantsSummaryData(summaryDataByPlan)
    const summaryOverviewRow = createParticipantsSummaryOverviewRow(
      summaryOverview
    )

    return [
      concat(sortBy(summaryDataByPlan, 'planName'), summaryOverviewRow),
      summaryOverview,
    ]
  }, [participants])

  return {
    data: participantsSummaryData,
    overview: participantsSummaryOverview,
  }
}

export default useParticipantsSummary
