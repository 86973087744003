import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as globalActions from '../main/actions'
import { ExternalLink } from 'components'
import { COMPANY_NAME, LEGAL_DISCLAIMERS } from 'config/portal'

function Footer({ setSupportModalShown }) {
  const currentYear = new Date().getFullYear()

  return (
    <footer className="footer">
      <section className="footer-menu">
        <button
          type="button"
          id="contact-button"
          onClick={() => setSupportModalShown(true)}
        >
          Contact us
        </button>
        <ExternalLink link="https://inspirafinancial.com/privacy-policy/terms/terms-of-use">
          Terms of Use/Service
        </ExternalLink>
        <ExternalLink link="https://inspirafinancial.com/privacy-policy/privacy/privacy-policy">
          Privacy
        </ExternalLink>
        <ExternalLink link="https://inspirafinancial.com/privacy-policy/security/security">
          Security
        </ExternalLink>
      </section>
      <section className="footer-text">
        <p>
          © {currentYear} {COMPANY_NAME.full}
        </p>
        <p>{LEGAL_DISCLAIMERS.footer}</p>
        <p>{LEGAL_DISCLAIMERS.trademark}</p>
      </section>
    </footer>
  )
}

const mapDispatchToProps = {
  setSupportModalShown: globalActions.setSupportModalShown,
}

export default compose(connect(null, mapDispatchToProps))(Footer)
