import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Modal, SubmitButton } from 'lp-components'
import { submissionMethodOptions } from 'config/automatic-rollovers'
import { ScrollErrorForm, RadioGroup } from 'components'
import { Formik } from 'formik'
import { displayInteger } from 'utils'
import * as Yup from 'yup'

const propTypes = {
  onClose: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  planNames: PropTypes.arrayOf(PropTypes.string),
  planListTitle: PropTypes.string.isRequired,
  disclosureParagraph: PropTypes.string.isRequired,
}

const defaultProps = {
  planNames: [],
}

const validationSchema = Yup.object({
  submissionMethod: Yup.string()
    .oneOf(
      submissionMethodOptions.map((option) => option.value),
      `Must be one of the following values: ${submissionMethodOptions
        .map((option) => option.key)
        .join(', ')}`
    )
    .required('Required'),
})

function IneligiblePlanModal({
  planNames,
  planListTitle,
  disclosureParagraph,
  onClose,
  onContinue,
  children,
}) {
  return (
    <Formik
      initialValues={{ submissionMethod: '' }}
      validationSchema={validationSchema}
      onSubmit={onContinue}
    >
      {() => {
        return (
          <Modal id="ineligible-plan-modal" onClose={onClose}>
            {children}
            <ScrollErrorForm>
              <div className="modal-body">
                <div className="form-body">
                  <div className="form-inner">
                    <p>{disclosureParagraph}</p>
                    <RadioGroup
                      name="submissionMethod"
                      label="How would you like to proceed with these plans?"
                      options={submissionMethodOptions}
                      radioInputProps={{
                        className: 'submission-methods',
                      }}
                    />
                    {planNames.length > 0 && (
                      <>
                        <div className="plan-list">
                          <h2 className="plan-list__title">{planListTitle}</h2>
                          <span className="text-label--medium">
                            Total participants:{' '}
                            {displayInteger(planNames.length)}
                          </span>
                        </div>
                        <h3>Plan name</h3>
                        <ul>
                          {planNames.map((participant) => (
                            <li key={participant}>{participant}</li>
                          ))}
                        </ul>
                      </>
                    )}
                  </div>
                </div>
                <div className="form-actions">
                  <SubmitButton>Continue</SubmitButton>
                </div>
              </div>
            </ScrollErrorForm>
          </Modal>
        )
      }}
    </Formik>
  )
}

IneligiblePlanModal.propTypes = exact(propTypes)
IneligiblePlanModal.defaultProps = defaultProps

export default IneligiblePlanModal
