import React, { useEffect } from 'react'
import { Icon as UnauthorizedIcon } from 'images/unauthorized.svg'
import { useAuth0 } from '@auth0/auth0-react'
import { clearSessionToken } from '../services/auth'
import { SupportEmailLink } from 'components'

const propTypes = {}

const defaultProps = {}

function UnauthorizedProfilePage() {
  useEffect(() => {
    window.appEventData.pop()
    var appEventData = window.appEventData || []
    appEventData.push({
      event: 'Page Load Completed',
    })
  }, [])
  const { logout } = useAuth0()

  function handleLogout() {
    clearSessionToken()
    logout({ returnTo: window.location.origin })
  }

  return (
    <div id="unauthorized-profile">
      <div className="error-state">
        <UnauthorizedIcon aria-hidden="true" width="90" height="90" />
        <h1 className="error-state__title">Unauthorized Credentials</h1>
        <p className="error-state__description">
          You’re not authorized to access this page. Please contact your sales
          support representative at <SupportEmailLink /> with any questions.
        </p>
        <button type="button" className="button-primary" onClick={handleLogout}>
          Return to Login
        </button>
      </div>
    </div>
  )
}

UnauthorizedProfilePage.propTypes = propTypes
UnauthorizedProfilePage.defaultProps = defaultProps

export default UnauthorizedProfilePage
