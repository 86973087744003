import React from 'react'
import { useFormikContext } from 'formik'
import { map } from 'lodash'
import { Input, MaskedInput, Checkbox } from 'components'

function ContactDetailsFields({ baseInitialValues, detailsType }) {
  const { setFieldValue, setFieldError, setFieldTouched } = useFormikContext()

  const resetFields = (fields, detailsType) => {
    map(fields, (field) => {
      setFieldValue(
        `${detailsType}.contact.${field}`,
        `${baseInitialValues[detailsType].contact[field]}` || '',
        false
      )
      setFieldError(`${detailsType}.contact.${field}`, '')
      setFieldTouched(`${detailsType}.contact.${field}`, false, false)
    })
  }

  return (
    <>
      <div className="form-column">
        <Input
          name={`${detailsType}.contact[firstName]`}
          label="First name"
          placeholder="Enter first name"
        />
      </div>
      <div className="form-column">
        <Input
          name={`${detailsType}.contact[lastName]`}
          label="Last name"
          placeholder="Enter last name"
        />
      </div>
      <div className="form-column">
        <Input
          name={`${detailsType}.contact[title]`}
          label="Title"
          placeholder="Enter title"
        />
      </div>
      <div className="form-column">
        <MaskedInput
          name={`${detailsType}.contact[phone]`}
          label="Contact phone"
          placeholder="Enter phone"
          maskOptions={{
            numericOnly: true,
            blocks: [0, 3, 0, 3, 4],
            delimiters: ['(', ')', ' ', '-'],
          }}
        />
      </div>
      <div className="form-column">
        <Input
          name={`${detailsType}.contact[email]`}
          label="Contact email"
          placeholder="Enter email"
        />
      </div>
      <div className="form-column">
        <Checkbox
          name={`${detailsType}.contact[isSameAsCompanyAddress]`}
          label="Address same as above"
          onChange={() =>
            resetFields(
              [
                'mailingStreet',
                'mailingCity',
                'mailingState',
                'mailingPostalCode',
                'mailingCountry',
              ],
              detailsType
            )
          }
        />
      </div>
    </>
  )
}

export default ContactDetailsFields
