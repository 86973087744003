import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Modal } from 'lp-components'
import classNames from 'classnames'

const propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  confirmContent: PropTypes.node,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

const defaultProps = {
  confirmContent: 'Yes',
  className: null,
}

function ProductExitModal({
  onClose,
  onConfirm,
  confirmContent,
  children,
  className,
}) {
  return (
    <Modal
      id="confirm-modal"
      onClose={onClose}
      className={classNames('modal-inner', className)}
    >
      {children}
      <div className="confirm-modal-actions">
        <button
          type="button"
          id="confirm-action"
          className="button-primary confirm-action"
          onClick={onConfirm}
        >
          {confirmContent}
        </button>
        <button
          id="cancel-action"
          type="button"
          className="button-text confirm-action"
          onClick={onClose}
        >
          No, dont cancel
        </button>
      </div>
    </Modal>
  )
}

ProductExitModal.propTypes = exact(propTypes)
ProductExitModal.defaultProps = defaultProps

export default React.memo(ProductExitModal)
