import { useMemo } from 'react'
import { isEmpty, concat, sortBy } from 'lodash'
import {
  summarizeParticipantsDataByPlanBD,
  sumUpParticipantsSummaryDataBD,
  createParticipantsSummaryOverviewRowBD,
} from 'utils'

// A hook that creates a summary of participants by plan for submission: used in ARO participant file flow
function useParticipantsSummaryBD(participants) {
  const [participantsSummaryData, participantsSummaryOverview] = useMemo(() => {
    if (isEmpty(participants)) return []

    const summaryDataByPlan = summarizeParticipantsDataByPlanBD(participants)
    const summaryOverview = sumUpParticipantsSummaryDataBD(summaryDataByPlan)
    const summaryOverviewRow =
      createParticipantsSummaryOverviewRowBD(summaryOverview)

    return [
      concat(sortBy(summaryDataByPlan, 'planName'), summaryOverviewRow),
      summaryOverview,
    ]
  }, [participants])

  return {
    data: participantsSummaryData,
    overview: participantsSummaryOverview,
  }
}

export default useParticipantsSummaryBD
