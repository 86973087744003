import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import { selectors as profileSelectors } from '../../profile/reducer'
import * as profileActions from '../../profile/actions'
import * as apiActions from 'api-actions'
import * as globalActions from '../../actions'
import { flashErrorMessage } from 'redux-flash'
import { Path, CREDIT_CARD_OPTION } from 'config/portal'
import { NO_SERVICES_AGREEMENT_OPTION } from 'config/automatic-rollovers'
import {
  TOAST_ERROR_CONTACT_SUPPORT,
  TOAST_ERROR_CONTACT_SUPPORT_EMAIL,
  TOAST_ERROR_CREDIT_CARD,
} from 'config/notifications'
import {
  formatCurrency,
  formatFileName,
  useParticipantsSummaryBD,
  downloadDiscardedParticipants,
  displayInteger,
} from 'utils'
import { Redirect, useHistory } from 'react-router-dom'
import { isEmpty, sum, some, map } from 'lodash'
import { Icon as DownloadIcon } from 'images/download.svg'
import { ConfirmModal, FullPageSpinner, ComponentFailure } from 'components'
import { selectors as globalSelectors } from 'global-reducer'
import { selectors as apiSelectors } from 'lp-redux-api'
import { RecordTypes } from 'flatfile-config'
import { US_COUNTRY_VALUE } from 'config/locations'
import { ReviewAndSubmitForm } from '../forms'
import { PAYMENT_METHODS } from 'config/benefit-distributions'
const propTypes = {
  currentUser: Types.user.isRequired,
  participantsAgreementHash: PropTypes.object,
  participantFileName: PropTypes.string,
  createParticipantsData: PropTypes.func.isRequired,
  deletePaymentMethod: PropTypes.func.isRequired,
  flashErrorMessageHandler: PropTypes.func.isRequired,
  // showSuccessModal: PropTypes.func.isRequired,
  participantsForSubmission: PropTypes.arrayOf(Types.rolloverParticipant)
    .isRequired,
  discardedParticipants: PropTypes.arrayOf(Types.rolloverParticipant)
    .isRequired,
  isSubmittingParticipantsData: PropTypes.bool.isRequired,
  selectedPaymentMethod: PropTypes.string.isRequired,
  paymentMethod: Types.paymentMethod,
  fetchPaymentMethod: PropTypes.func.isRequired,
  shouldTriggerPaymentInfoFetch: PropTypes.bool.isRequired,
  setShouldTriggerPaymentInfoFetch: PropTypes.func.isRequired,
  saveCompletePaymentDetails: PropTypes.func.isRequired,
  generateBDFeesCost: PropTypes.func.isRequired,
  bdFeesCost: PropTypes.object,
}

const defaultProps = {
  participantsAgreementHash: null,
  participantFileName: null,
  paymentMethod: null,
  bdFeesCost: null,
}

const FILE_NAME_PREFIX = 'RSP_'

const scrubData = (val) => val.replaceAll(/[$]/g, '')

function ReviewAndSubmitView({
  currentUser,
  participantsAgreementHash,
  participantFileName,
  createParticipantsData,
  flashErrorMessageHandler,
  participantsForSubmission,
  discardedParticipants,
  // showSuccessModal,
  isSubmittingParticipantsData,
  selectedPaymentMethod,
  paymentMethod,
  fetchPaymentMethod,
  deletePaymentMethod,
  shouldTriggerPaymentInfoFetch,
  setShouldTriggerPaymentInfoFetch,
  saveCompletePaymentDetails,
  bdFeesCost,
  generateBDFeesCost,
}) {
  const history = useHistory()
  const [error, setError] = useState(false)
  const [isNewPayment, setIsNewPayment] = useState(false)
  const [showReplacePayment, setShowReplacePayment] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [hasDownloaded, setHasDownloaded] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [sessionFlag, setSessionFlag] = useState(false)
  const {
    data: participantsSummaryData,
    overview: participantsSummaryOverview,
  } = useParticipantsSummaryBD(participantsForSubmission)

  useEffect(() => {
    async function getPaymentMethod() {
      try {
        setIsLoading(true)
        await fetchPaymentMethod()
        setIsLoading(false)
      } catch {
        setError(true)
      }
    }

    if (!paymentMethod || shouldTriggerPaymentInfoFetch) {
      getPaymentMethod()
      setShouldTriggerPaymentInfoFetch(false)
    }
  }, [paymentMethod, shouldTriggerPaymentInfoFetch])

  useEffect(() => {
    async function generateTotalFeesCost() {
      try {
        if (
          !sessionFlag &&
          participantsForSubmission &&
          selectedPaymentMethod === PAYMENT_METHODS.creditCard
        ) {
          await generateBDFeesCost({
            participants: participantsForSubmission,
          })
          setSessionFlag(true)
        }
      } catch (e) {
        setError(true)
      }
    }
    generateTotalFeesCost()
  }, [paymentMethod, participantsForSubmission, sessionFlag])

  const hasPaymentMethod = !isEmpty(paymentMethod)
  const isCreditCardPayment =
    selectedPaymentMethod === PAYMENT_METHODS.creditCard
  const totalPaymentDue = bdFeesCost?.summary.totalInspiraFees
  const hasBalance =
    participantsSummaryOverview?.inspiraBenefitDisbursementProcessingFee > 0
  const deductFromDistributionAmount =
    participantsSummaryOverview?.amount -
    sum([
      participantsSummaryOverview?.tpaBenefitDistributionProcessingFee,
      participantsSummaryOverview?.inspiraBenefitDisbursementProcessingFee,
    ])
  const creditCardDistributionAmount =
    participantsSummaryOverview?.amount -
    sum([participantsSummaryOverview?.tpaBenefitDistributionProcessingFee])

  const handleDeletePayment = async () => {
    try {
      await deletePaymentMethod()
      setShowReplacePayment(true)
    } catch (e) {
      flashErrorMessage(TOAST_ERROR_CONTACT_SUPPORT)
    }
  }

  const handleSubmit = async (values) => {
    if (!values.hasConfirmed) {
      if (!isEmpty(discardedParticipants) && !hasDownloaded) {
        setShowModal(true)
        return
      }
    }
    const isPartialSubmission = some(
      participantsAgreementHash,
      ({ servicesAgreement }) =>
        servicesAgreement === NO_SERVICES_AGREEMENT_OPTION.value
    )
    // scrub the '$' currency from IRA amounts before submission
    const scrubbedParticipantsForSubmission = map(
      participantsForSubmission,
      ({
        amount,
        inspiraBenefitDisbursementProcessingFee,
        tpaBenefitDistributionProcessingFee,
        grossDistributionAmount,
        ...rest
      }) => ({
        ...rest,
        amount: scrubData(amount),
        inspiraBenefitDisbursementProcessingFee: scrubData(
          inspiraBenefitDisbursementProcessingFee
        ),
        tpaBenefitDistributionProcessingFee: scrubData(
          tpaBenefitDistributionProcessingFee
        ),
        grossDistributionAmount: scrubData(grossDistributionAmount),
      })
    )
    const paymentDetails =
      isCreditCardPayment && isNewPayment
        ? {
            firstName: values.paymentDetails.firstName,
            lastName: values.paymentDetails.lastName,
            country: values.paymentDetails.country,
            state: values.paymentDetails.state,
            city: values.paymentDetails.city,
            address1: values.paymentDetails.address1,
            address2: values.paymentDetails.address2,
            zipCode: values.paymentDetails.zip,
            paymentToken: values.paymentDetails.cardData.paymentToken,
            fraudId: values.paymentDetails.cardData.fraud,
            creditCardLastFour: values.paymentDetails.cardData.last4Digits,
            cardExpMonth: values.paymentDetails.cardData.exp.split('/')[0],
            cardExpYear: values.paymentDetails.cardData.exp.split('/')[1],
            cardType: values.paymentDetails.cardData.ccType,
            cardStatus: 'Active',
            cardLabel: values.paymentDetails.cardData.cardLabel,
            phone: null,
            email: null,
          }
        : null
    try {
      await createParticipantsData({
        fileName: formatFileName(participantFileName, FILE_NAME_PREFIX),
        participants: scrubbedParticipantsForSubmission,
        summary: {
          totalPlans: participantsSummaryOverview.totalPlans,
          totalParticipants: participantsSummaryOverview.totalParticipants,
          totalDistributionAmount:
            selectedPaymentMethod === 'Pay with credit card'
              ? formatCurrency(creditCardDistributionAmount)
              : formatCurrency(deductFromDistributionAmount),
        },
      })

      history.push({
        pathname: Path.BD_SERVICES_AGREEMENTS,
        state: {
          ignorePrompt: true,
          showAroSuccessModal: true,
          isPartialSubmission,
        },
      })
    } catch (e) {
      flashErrorMessageHandler(TOAST_ERROR_CONTACT_SUPPORT)
    }
    try {
      if (isNewPayment) {
        const paymentResponse = await saveCompletePaymentDetails({
          paymentDetails,
        })
        if (paymentResponse.IsSuccess == false) {
          throw new Error('Credit card cannot be processed')
        }
      }
      setShouldTriggerPaymentInfoFetch(true)

      history.push({
        pathname: Path.BD_SERVICES_AGREEMENTS,
        state: {
          ignorePrompt: true,
          showAroSuccessModal: true,
          isPartialSubmission,
        },
      })
      // * This is for when we complete the submission process, we need payment success messages for BD.
      // showSuccessModal({
      //   title: 'Success!',
      //   message: isNewPayment
      //     ? SEARCH_ORDER_WITH_PAYMENT_SUCCESS_MESSAGE
      //     : SEARCH_ORDER_SUCCESS_MESSAGE,
      // })
    } catch (e) {
      if (e == 'Error: Credit card cannot be processed') {
        flashErrorMessageHandler(TOAST_ERROR_CREDIT_CARD)
      } else {
        flashErrorMessageHandler(TOAST_ERROR_CONTACT_SUPPORT_EMAIL)
      }
    }
  }

  const handleDownload = () => {
    setHasDownloaded(true)
    downloadDiscardedParticipants({
      participants: discardedParticipants,
      originalFileName: participantFileName,
      recordType: RecordTypes.ARO_PARTICIPANT,
    })
  }

  if (isEmpty(participantsAgreementHash)) {
    return <Redirect to="/benefit-distributions/upload-file" />
  }

  if (!selectedPaymentMethod)
    return <Redirect to={Path.SELECT_PAYMENT_METHOD} />

  if (error) return <ComponentFailure />

  return (
    <>
      <div className="card full-height upload-participant-file-view-container">
        {isSubmittingParticipantsData && <FullPageSpinner showBackground />}
        <div className="form-masthead">
          <h2>Lastly, review your submission and fees.</h2>
        </div>
        <div className="form-section">
          <p>
            Review your submission and verify it contains all your intended
            plans and participants. If you removed any participants during the
            process, they won’t be reflected in the table.
          </p>
          <p>
            We will review your submission, verify participant details, open
            accounts, and email you with account funding instructions.
          </p>
        </div>
        {!isEmpty(discardedParticipants) && (
          <div className="form-section discarded-participants">
            <div className="form-header">
              <h3 className="is-marginless">
                Participants not included in submission
              </h3>
            </div>
            <p>
              Some participants won’t be included in your submission if required
              information fails validation or isn’t provided. Download the data
              for these participants for your records and resubmit them once you
              have the required information.
            </p>
            <div className="download-container">
              <span>
                Total discarded participants:{' '}
                {displayInteger(discardedParticipants.length)}
              </span>
              <button type="button" onClick={handleDownload}>
                <DownloadIcon aria-hidden="true" />
                Download participant data
              </button>
            </div>
          </div>
        )}
        <ReviewAndSubmitForm
          initialValues={{
            confirm: false,
            paymentDetails: {
              paymentType: CREDIT_CARD_OPTION.value,
              firstName: '',
              lastName: '',
              cardData: '',
              country: US_COUNTRY_VALUE,
              address1: '',
              address2: '',
              city: '',
              state: '',
              zip: '',
            },
            confirmPayment: false,
          }}
          hasPaymentMethod={hasPaymentMethod}
          participantsSummaryData={participantsSummaryData}
          hasBalance={hasBalance}
          onSubmit={(params) => handleSubmit(params)}
          currentUser={currentUser}
          paymentMethod={paymentMethod}
          onConfirmDeletePayment={handleDeletePayment}
          isNewPayment={isNewPayment}
          setIsNewPayment={setIsNewPayment}
          isCreditCardPayment={isCreditCardPayment}
          isLoading={isLoading}
          showReplacePayment={showReplacePayment}
          selectedPaymentMethod={selectedPaymentMethod}
          totalPaymentDue={totalPaymentDue}
        />
        {showModal && (
          <ConfirmModal
            onClose={() => setShowModal(false)}
            onConfirm={async () => {
              await handleSubmit({ hasConfirmed: true })
              setShowModal(false)
            }}
            confirmContent="Yes, Submit"
          >
            <h2>Download discard participants</h2>
            <p>
              Are you sure you want to submit without downloading the discarded
              participants?
            </p>
          </ConfirmModal>
        )}
      </div>
    </>
  )
}

ReviewAndSubmitView.propTypes = propTypes
ReviewAndSubmitView.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentUser: globalSelectors.currentUser(state),
    participantFileName: selectors.participantFileName(state),
    participantsAgreementHash: selectors.participantsAgreementHash(state),
    participantsForSubmission: selectors.participantsForSubmission(state),
    discardedParticipants: selectors.discardedParticipants(state),
    isSubmittingParticipantsData: apiSelectors.isLoading(
      state,
      apiActions.createParticipantsData
    ),
    selectedPaymentMethod: selectors.selectedPaymentMethod(state),
    paymentMethod: profileSelectors.paymentMethod(state),
    shouldTriggerPaymentInfoFetch:
      profileSelectors.shouldTriggerPaymentInfoFetch(state),
    bdFeesCost: selectors.bdFeesCost(state),
  }
}

const mapDispatchToProps = {
  showSuccessModal: globalActions.showSuccessModal,
  createParticipantsData: apiActions.createParticipantsData,
  flashErrorMessageHandler: flashErrorMessage,
  deletePaymentMethod: apiActions.deletePaymentMethod,
  fetchPaymentMethod: apiActions.fetchPaymentMethod,
  setShouldTriggerPaymentInfoFetch:
    profileActions.setShouldTriggerPaymentInfoFetch,
  saveCompletePaymentDetails: apiActions.saveCompletePaymentDetails,
  generateBDFeesCost: apiActions.generateBDFeesCost,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ReviewAndSubmitView
)
