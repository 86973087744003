import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { ScrollErrorForm, RadioGroup, ConfirmationCheckbox } from 'components'
import { FlowActions } from '../components'
import * as Yup from 'yup'
import { map } from 'lodash'
import { COMPANY_NAME } from 'config/portal'

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  isAdvancedDeathAudit: PropTypes.bool.isRequired,
}
const defaultProps = {}

const dmfPermissibleOptions = [
  {
    key: '1',
    value:
      'The plan has a legitimate fraud prevention interest to detect and prevent fraud across its business and/or government activities.',
  },
  {
    key: '2',
    value:
      'The plan has a legitimate business purpose pursuant to a law, government rule, regulation or fiduciary duty.',
  },
]
const dmfPermissibleBusinessPurposeOption = dmfPermissibleOptions[1]
const dmfPermissibleAdditionalOptions = [
  {
    key: 'a. For uses permitted or required by law',
    value: 'a. For uses permitted or required by law',
  },
  {
    key: 'b. For uses permitted or required by government rules',
    value: 'b. For uses permitted or required by government rules',
  },
  {
    key: 'c. For uses permitted or required by regulation',
    value: 'c. For uses permitted or required by regulation',
  },
  {
    key: 'd. For uses necessary to fulfill or avoid violating fiduciary duties',
    value:
      'd. For uses necessary to fulfill or avoid violating fiduciary duties',
  },
]
const glbaPermissibleOptions = [
  {
    key: '1',
    value:
      'To protect against or prevent actual or potential fraud, unauthorized transactions, claims or other liability.',
  },
  {
    key: '2',
    value:
      'For required institutional risk control, or for resolving customer disputes or inquiries.',
  },
  {
    key: '3',
    value:
      'Use by persons holding a legal or beneficial interest relating to the consumer.',
  },
  {
    key: '4',
    value:
      'Use by persons acting in a fiduciary or representative capacity on behalf of the consumer.',
  },
  {
    key: '5',
    value:
      'In complying with federal, state, or local laws, rules, and other applicable legal requirements.',
  },
  {
    key: '6',
    value:
      'To the extent specifically permitted or required under other provisions of law and in accordance with the Rights to Financial Privacy Act of 1978, to law enforcement agencies (including the Bureau of Consumer Financial Protection, a Federal function regulator, the Secretary of Treasury, a State insurance authority, or the Federal Trade Commission), self-regulatory organizations, or for an investigation on a matter related to public safety.',
  },
]
const dppaPermissibleOptions = [
  {
    key: '1',
    value:
      'For use by any government agency, including any court or law enforcement agency, in carrying out its functions, or any private person or entity acting on behalf of a federal, state, or local agency in carrying out its functions.',
  },
  {
    key: '2',
    value:
      'For use in connection with any civil, criminal, administrative, or arbitral proceeding in any federal, state, or local court or agency, or before any self-regulatory body, including the service of process, investigation in anticipation of litigation, and the execution or enforcement of judgements and orders, or pursuant to an order of a federal, state, or local court.',
  },
  {
    key: '3',
    value:
      'For use in the normal course of business by a legitimate business or its agents, employees, or contractors, but only - (A) to verify the accuracy of personal information submitted by the individual to the business or its agents, employees, or contractors; and (B) if such information as so submitted is not correct or is no longer correct, to obtain the correct information, but only for the purposes of preventing fraud by, pursuing legal remedies against, or recovering on a debt or security interest against, the individual.',
  },
]
const dmfPermissibleValues = map(dmfPermissibleOptions, 'value')
const dmfPermissibleAdditionalValues = map(
  dmfPermissibleAdditionalOptions,
  'value'
)
const glbaPermissibleValues = map(glbaPermissibleOptions, 'value')
const dppaPermissibleValues = map(dppaPermissibleOptions, 'value')

function PermissibleUseForm({ onSubmit, initialValues, isAdvancedDeathAudit }) {
  const permissibleUseDetailsSchema = Yup.object({
    dmfPermissible: Yup.string()
      .oneOf(dmfPermissibleValues, 'Must be a valid DMF option')
      .required('Required'),
    glbaPermissible: Yup.string()
      .oneOf(glbaPermissibleValues, 'Must be a valid GLBA option')
      .required('Required'),
    dppaPermissible: isAdvancedDeathAudit
      ? Yup.string()
      : Yup.string()
          .oneOf(dppaPermissibleValues, 'Must be a valid DPPA option')
          .required('Required'),
    confirm: Yup.boolean().required('Required').oneOf([true], 'Required'),
    dmfPermissibleAdditional: Yup.string().when('dmfPermissible', {
      is: dmfPermissibleBusinessPurposeOption.value,
      then: (schema) =>
        schema
          .oneOf(
            dmfPermissibleAdditionalValues,
            'Must be a valid additional option'
          )
          .required('Required'),
    }),
  })
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={permissibleUseDetailsSchema}
    >
      {({ values, setFieldValue }) => {
        const { dmfPermissible } = values

        const showAdditionalOptions =
          dmfPermissible == dmfPermissibleBusinessPurposeOption.value
        return (
          <ScrollErrorForm>
            <div className="form-header">
              <h3 id="dmf-permissible-heading">
                Death Master File (DMF) permissible use
              </h3>
            </div>
            <div className="radio-container-input">
              <p className="permissible-questions">
                The plan agent certifies, represents and warrants that the
                plan’s permissible use of the services (as set forth in 42 USC
                1306c and 15 CFR §1110) is as selected below.
              </p>
              <p className="permissible-questions">
                Select the one that represents the DMF permissible use for the
                plan.
              </p>
              <RadioGroup
                options={dmfPermissibleValues}
                name="dmfPermissible"
                label={false}
                ariaLabelledby="dmf-permissible-heading"
                onChange={() => {
                  if (values.dmfPermissibleAdditional) {
                    setFieldValue('dmfPermissibleAdditional', '')
                  }
                }}
              />
              {showAdditionalOptions && (
                <div className="additional-options">
                  <p id="dmf-permissible-additional">
                    What is the plan’s legitimate business purpose for obtaining
                    death data?
                  </p>
                  <RadioGroup
                    options={dmfPermissibleAdditionalOptions}
                    name="dmfPermissibleAdditional"
                    label={false}
                    ariaLabelledby="dmf-permissible-additional"
                  />
                </div>
              )}
            </div>
            <div className="form-header">
              <h3 id="glba-permissible-heading">
                Gramm-Leach-Bliley Act (GLBA) permissible use
              </h3>
            </div>
            <div className="radio-container-input">
              <p className="permissible-questions">
                The services may include nonpublic personal information, which
                is governed by the privacy provisions of the Gramm-Leach-Bliley
                Act (15 U.S.C. § 6801 et seq.) and its implementing regulations
                (collectively, “GLBA”).
              </p>
              <p className="permissible-questions">
                The plan agent certifies, represents and warrants that (i) it
                has the permissible use under the GLBA to use and/or obtain such
                information that is selected below and (ii) it will only use
                such information for such purpose(s) selected below or, if
                applicable, for the purpose to which it has certified, (iii) it
                will comply with the GLBA.
              </p>
              <p className="permissible-questions">
                Select the one that represents the GLBA permissible use for the
                Plan.
              </p>
              <RadioGroup
                options={glbaPermissibleValues}
                name="glbaPermissible"
                label={false}
                ariaLabelledby="glba-permissible-heading"
              />
            </div>
            {!isAdvancedDeathAudit && (
              <>
                <div className="form-header">
                  <h3 id="dppa-permissible-heading">
                    Driver’s Privacy Protection Act (DPPA) permissible use
                  </h3>
                </div>
                <div className="radio-container-input">
                  <p className="permissible-questions">
                    The services may include nonpublic personal information,
                    which is governed by the Driver's Privacy Protection Act (18
                    U.S.C. § 2721 et seq.) and related state laws (collectively,
                    “DPPA”).
                  </p>
                  <p className="permissible-questions">
                    The plan agent certifies, represents and warrants that (i)
                    it has the permissible use under the DPPA to use and/or
                    obtain such information that is selected below (“DPPA
                    permissible use”) and (ii) it will only use such information
                    for such purpose(s) selected below or, if applicable, for
                    the purpose to which it has certified.
                  </p>
                  <p className="permissible-questions">
                    Select the one that represents the DPPA permissible use for
                    the Plan.
                  </p>
                  <RadioGroup
                    options={dppaPermissibleValues}
                    name="dppaPermissible"
                    label={false}
                    ariaLabelledby="dppa-permissible-heading"
                  />
                </div>
              </>
            )}
            <hr></hr>
            <p className="advanced-death-audit-section">
              <strong>No FCRA use.</strong> The services are not provided by
              "consumer reporting agencies" and do not constitute a "consumer
              report", as each is defined by the Fair Credit Reporting Act (15
              U.S.C. § 1681 et seq.) ("FCRA"). Plan agent represents and
              warrants that the services will not be used in whole, or in part,
              as a factor in determining an individual's creditworthiness or
              eligibility for (i) credit, (ii) insurance, (iii) employment, or
              (iv) for any other purpose under the FCRA.
            </p>
            <div className="form-section">
              <div className="form-header">
                <h3>Acknowledgements</h3>
              </div>
              <div className="acknowledgements" id="checkbox-search">
                <ConfirmationCheckbox
                  name="confirm"
                  label={
                    <>
                      <p className="confirm-description">
                        By checking this box, you certify that the above
                        responses to the DMF permissible use, GLBA permissible
                        use, and DPPA permissible use (collectively, the
                        “permissible use”) questions are applicable to each of
                        the plans included with this search order. By submitting
                        this search order, each plan authorizes{' '}
                        {COMPANY_NAME.long} to act as its designee to utilize
                        commercial, credit, and/or government sources to verify
                        or obtain information required to service a benefit plan
                        or policy.
                      </p>
                      <br></br>
                      <p className="confirm-description">
                        If the permissible uses are not the same for each plan,
                        a separate search order must be submitted for each plan.
                      </p>
                      <br></br>
                      <p className="confirm-description">
                        To the extent there are any changes with respect to a
                        search order after submission, plan shall provide
                        written notice of such change to {COMPANY_NAME.long}.
                      </p>
                    </>
                  }
                />
              </div>
            </div>
            <FlowActions />
          </ScrollErrorForm>
        )
      }}
    </Formik>
  )
}
PermissibleUseForm.propTypes = propTypes
PermissibleUseForm.defaultProps = defaultProps

export default PermissibleUseForm
