import React from 'react'
import { FlowActions as BaseFlowActions } from 'components'
import { Path } from 'config/portal'

const propTypes = {}

const defaultProps = {}

function FlowActions(props) {
  return <BaseFlowActions {...props} exitPath={Path.BD_SERVICES_AGREEMENTS} />
}

FlowActions.propTypes = propTypes
FlowActions.defaultProps = defaultProps

export default React.memo(FlowActions)
