import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  ScrollErrorForm,
  ConfirmationCheckbox,
  ConfirmModal,
  Notification,
  Spinner,
} from 'components'
import { Formik } from 'formik'
import * as Yup from 'yup'
import * as Types from 'types'
import { displayAttribute, formatCurrency } from 'utils'
import { isEmpty } from 'lodash'
import PaymentFields, { paymentSchema } from 'components/PaymentFields'
import { PAYMENT_CONFIRMATION_TEXT } from '../../../config/benefit-distributions'
import { Variant as NotificationVariant } from 'components/Notification'
import { ParticipantsSummaryTable, FlowActions } from '../components'

const propTypes = {
  initialValues: PropTypes.object.isRequired,
  hasBalance: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setIsNewPayment: PropTypes.func.isRequired,
  showReplacePayment: PropTypes.bool.isRequired,
  participantsSummaryData: PropTypes.array.isRequired,
  isCreditCardPayment: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  currentUser: Types.user.isRequired,
  paymentMethod: Types.paymentMethod,
  onConfirmDeletePayment: PropTypes.func.isRequired,
  totalPaymentDue: PropTypes.number,
}

const defaultProps = {
  totalPaymentDue: null,
}

function ReviewAndSubmitForm({
  onSubmit,
  isLoading,
  initialValues,
  setIsNewPayment,
  showReplacePayment,
  participantsSummaryData,
  isCreditCardPayment,
  currentUser,
  paymentMethod,
  onConfirmDeletePayment,
  hasBalance,
  totalPaymentDue,
}) {
  const [showRemovePaymentModal, setShowRemovePaymentModal] = useState(false)
  const hasPaymentMethod = !isEmpty(paymentMethod)
  const confirmSubmissionSchema = Yup.object({
    paymentDetails: Yup.lazy(() => {
      if (isCreditCardPayment) {
        if (hasBalance && !hasPaymentMethod) {
          return paymentSchema
        }
        if (!hasBalance && !hasPaymentMethod) {
          return Yup.object().nullable()
        }
        if (hasPaymentMethod && !hasBalance) {
          return Yup.object().nullable()
        }
        if (
          hasPaymentMethod &&
          hasBalance &&
          !!paymentMethod.creditCardLastFour
        ) {
          return Yup.object().nullable()
        }
        if (hasPaymentMethod && hasBalance) {
          return paymentSchema
        }
      }
      return Yup.object().nullable()
    }),
    confirmPayment: Yup.lazy(() => {
      if (isCreditCardPayment && hasBalance)
        return Yup.boolean().required('Required').oneOf([true], 'Required')
      return Yup.boolean().nullable()
    }),
  })

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={confirmSubmissionSchema}
    >
      {({ touched, errors, isSubmitting }) => (
        <ScrollErrorForm>
          <div className="form-section">
            <ParticipantsSummaryTable
              data={participantsSummaryData}
              title={<h3>Submission summary</h3>}
              subText={`Keep in mind, taxes will be deducted
              from the participant distribution 
              balance before final processing.`}
            />
            {isLoading && (
              <div className="complete-services-agreement-view">
                <Spinner />
              </div>
            )}
            {isCreditCardPayment &&
              !showReplacePayment &&
              !hasPaymentMethod && (
                <div className="payment-details-section">
                  <h2>Total payment due: {formatCurrency(totalPaymentDue)}</h2>
                  <div className="payment-replace-card">
                    <p>You do not have a card on file with Inspira.</p>
                    <button
                      className="button-text"
                      onClick={(e) => {
                        e.preventDefault()
                        setShowRemovePaymentModal(true)
                      }}
                    >
                      Add new card
                    </button>
                  </div>
                </div>
              )}
            {touched.paymentDetails && errors.paymentDetails && (
              <Notification type={NotificationVariant.ERROR}>
                {errors.paymentDetails.cardData}
              </Notification>
            )}
            {showRemovePaymentModal && (
              <ConfirmModal
                onClose={() => setShowRemovePaymentModal(false)}
                onConfirm={() => {
                  setShowRemovePaymentModal(false)
                  setIsNewPayment(true)
                  onConfirmDeletePayment()
                }}
                confirmContent="Yes, Replace"
                className="remove-payment-modal"
              >
                <h2>Replace payment details</h2>
                <p>
                  Keep in mind you can only have one card on file. Replacing it
                  here will replace it across the platform.
                </p>
              </ConfirmModal>
            )}

            {isCreditCardPayment && !showReplacePayment && hasPaymentMethod && (
              <div className="payment-details-section">
                <h2>Total payment due: {formatCurrency(totalPaymentDue)}</h2>
                <div className="payment-replace-card">
                  <p>We'll charge your card on file with Inspira.</p>
                  <button
                    className="button-text"
                    onClick={(e) => {
                      e.preventDefault()
                      setShowRemovePaymentModal(true)
                    }}
                  >
                    Replace card
                  </button>
                </div>
                <div className="payment-details">
                  <div className="payment-details-header">Card number</div>
                  <div className="payment-details-text">
                    {paymentMethod.cardType}: {paymentMethod.cardLabel}
                  </div>
                  <div className="payment-details-header">Billing address</div>
                  <div className="payment-details-text">
                    {displayAttribute(paymentMethod.address1)}{' '}
                    {displayAttribute(paymentMethod.city)},{' '}
                    {displayAttribute(paymentMethod.state)}{' '}
                    {displayAttribute(paymentMethod.zipCode)}
                  </div>
                </div>
              </div>
            )}
            {isCreditCardPayment && showReplacePayment && (
              <section className="new-payment-form">
                <div id="add-new-payment-form-bd">
                  <PaymentFields sectionName="paymentDetails" />
                </div>
              </section>
            )}
            {isCreditCardPayment && (
              <ConfirmationCheckbox
                name="confirmPayment"
                label={`
                ${PAYMENT_CONFIRMATION_TEXT} 
                I also confirm that all records being submitted are employees or relatives of employees of ${currentUser.accountName}`}
                errorText="You must agree to the disclosure to continue."
              />
            )}
          </div>
          <FlowActions isSubmitting={isSubmitting} submitContent="Submit" />
        </ScrollErrorForm>
      )}
    </Formik>
  )
}

ReviewAndSubmitForm.propTypes = propTypes
ReviewAndSubmitForm.defaultProps = defaultProps

export default ReviewAndSubmitForm
