import React from 'react'
import PropTypes from 'prop-types'
import { TableColumn } from 'lp-components'
import { PaginatedAndSortableTable, TableHeading } from 'components'
import * as Types from 'types'

const propTypes = {
  data: PropTypes.arrayOf(Types.searchParticipant),
}

const defaultProps = {}

function SearchRecordsSummaryTable({ data }) {
  return (
    <div className="search-records-summary-table-container">
      <TableHeading title="Participant data file summary" />
      <PaginatedAndSortableTable
        data={data}
        disableSort={true}
        tableId="search-records-summary-table"
      >
        <TableColumn name="planSponsorName" label="Plan fiduciary name" />
        <TableColumn name="planName" label="Plan name" />
        <TableColumn
          name="planEin"
          label="Employer Identification Number (EIN)"
        />
        <TableColumn name="planNumber" label="Three-digit plan number (PN)" />
        <TableColumn
          name="ssn"
          label="Participant SSN"
          format={(ssn) => (ssn ? 'xxx-xx-xxxx' : '')} // format sensitive information
        />
        <TableColumn name="firstName" label="Participant first name" />
        <TableColumn name="middleName" label="Participant middle name" />
        <TableColumn name="lastName" label="Participant last name" />
        <TableColumn name="address1" label="Participant address 1" />
        <TableColumn name="address2" label="Participant address 2" />
        <TableColumn name="address3" label="Participant address 3" />
        <TableColumn name="city" label="Participant city" />
        <TableColumn name="state" label="Participant state" />
        <TableColumn name="zip" label="Participant zip" />
        <TableColumn name="dob" label="Participant DOB" />
        <TableColumn name="dod" label="Participant DOD" />
        <TableColumn name="phone" label="Phone number" />
        <TableColumn name="workFor" label="Work For" />
        <TableColumn name="reason" label="Reason" />
        <TableColumn name="miscellaneous1" label="Miscellaneous 1" />
        <TableColumn name="miscellaneous2" label="Miscellaneous 2" />
      </PaginatedAndSortableTable>
    </div>
  )
}

SearchRecordsSummaryTable.propTypes = propTypes
SearchRecordsSummaryTable.defaultProps = defaultProps

export default SearchRecordsSummaryTable
