import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Notification } from 'components'
import { Variant as NotificationVariant } from 'components/Notification'

const propTypes = {
  exactMatchExists: PropTypes.bool,
  likelyMatches: PropTypes.array,
  hasConfirmed: PropTypes.bool,
  noAgreementFound: PropTypes.bool,
  missingAllPlans: PropTypes.bool,
}

const defaultProps = {
  exactMatchExists: false,
  likelyMatches: null,
  hasConfirmed: false,
  noAgreementFound: false,
  missingAllPlans: false,
}

function PlanMappingMatchMessage({
  exactMatchExists,
  likelyMatches,
  hasConfirmed,
  noAgreementFound,
  missingAllPlans,
}) {
  if (missingAllPlans) {
    return (
      <Notification type={NotificationVariant.ERROR}>
        A rollover for this plan cannot be submitted without a completed
        Services Agreement on file.
      </Notification>
    )
  }
  if (hasConfirmed) {
    if (noAgreementFound) {
      return (
        <Notification type={NotificationVariant.INFORMATIONAL}>
          <span>
            You've selected "No Services Agreement Found" so your file
            submission will include all plans except for this one. You will be
            required to provide a signed agreement in order to complete the
            submission for this plan.
          </span>
        </Notification>
      )
    }
    return (
      <Notification type={NotificationVariant.SUCCESS}>
        You've confirmed your services agreement.
      </Notification>
    )
  }
  if (exactMatchExists) {
    return (
      <Notification type={NotificationVariant.SUCCESS}>
        This Services Agreement has been automatically mapped. Please review the
        selected agreement.
      </Notification>
    )
  }
  if (likelyMatches) {
    const LIKELY_MATCH_TEXT =
      likelyMatches.length === 1 ? 'a likely match' : 'some likely matches'
    return (
      <Notification type={NotificationVariant.WARNING}>
        We've found {LIKELY_MATCH_TEXT} based on your upload. Please select the
        Services Agreement.
      </Notification>
    )
  }
  return (
    <Notification type={NotificationVariant.WARNING}>
      We couldn’t find a match from your upload. Select the corresponding
      services agreement.
    </Notification>
  )
}

PlanMappingMatchMessage.propTypes = exact(propTypes)
PlanMappingMatchMessage.defaultProps = defaultProps

export default React.memo(PlanMappingMatchMessage)
