import React from 'react'

const propTypes = {}

const defaultProps = {}

function SubscriptionDetails() {
  return (
    <div className="card company-details-label">
      <i>Coming soon</i>
    </div>
  )
}

SubscriptionDetails.propTypes = propTypes

SubscriptionDetails.defaultProps = defaultProps

export default SubscriptionDetails
