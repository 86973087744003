import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Helmet } from 'react-helmet'
import { Path, COMPANY_NAME } from 'config/portal'
import { formatUrl } from 'utils'

const propTypes = {
  pathname: PropTypes.string,
}

const defaultProps = {
  pathname: null,
}

const titles = {
  '/home': 'Home',
  '/products/search/history': 'Search History',
  '/products/search/.*': 'Order Details',
  '/products': 'Products',
  [Path.SEARCH_DETAILS]: 'Select Search Type',
  '/search/upload-file': 'Upload Search File',
  [Path.CONFIRM_ORDER]: 'Confirm Submission',
  [Path.SERVICES_AGREEMENTS]: 'All Agreements',
  [formatUrl(`${Path.SERVICES_AGREEMENTS}/.*`)]: 'Services Agreement Details',
  '/automatic-rollovers/complete-services-agreement':
    'Complete Services Agreement',
  '/automatic-rollovers/upload-file':
    'Upload Automatic Rollover Participant File',
  '/automatic-rollovers/plan-mapping': 'Participant File Plan Mapping',
  '/automatic-rollovers/plan-confirmation':
    'Participant File Plan Confirmation',
  '/automatic-rollovers/review-and-submit-participant-data':
    'Review and Submit Participant File',
}

const defaultTitle = 'Retirement Services Platform'

const fetchTitle = (pathname) => {
  let title
  Object.keys(titles).forEach((pathRegex) => {
    const match = pathname.match(pathRegex)
    if (match && pathname === match[0] && !title) title = titles[pathRegex]
  })
  return `${title || defaultTitle} | ${COMPANY_NAME['medium']} - RSP`
}

function TitleTag({ pathname }) {
  return (
    <Helmet>
      <title>{fetchTitle(pathname)}</title>
    </Helmet>
  )
}

TitleTag.propTypes = exact(propTypes)
TitleTag.defaultProps = defaultProps

export default React.memo(TitleTag)
