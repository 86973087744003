import { isString } from 'lodash'

function einSorter(firstNumber, secondNumber) {
  let aString, bString
  if (isString(firstNumber)) aString = firstNumber.replace(/\D/g, '')
  if (isString(secondNumber)) bString = secondNumber.replace(/\D/g, '')

  let aNumber = parseFloat(aString)
  let bNumber = parseFloat(bString)
  if (isNaN(aNumber)) aNumber = 0
  if (isNaN(bNumber)) bNumber = 0

  return aNumber - bNumber
}

export default einSorter
