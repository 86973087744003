import React from 'react'
import PropTypes from 'prop-types'
import { Icon as DownloadIcon } from 'images/download.svg'
import * as XLSX from 'xlsx'
import { EMPTY_STATE_TOOLTIP_CONTENT } from 'config/notifications'
import { tippyPlugins } from 'utils'
import { Tooltip } from 'components'
import { Icon as InfoIcon } from 'images/info.svg'

const { hideOnEsc, hideOnPopperBlur, preventEventBubbling } = tippyPlugins

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  fieldMappings: PropTypes.arrayOf(PropTypes.object).isRequired,
  fileName: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  showIcon: PropTypes.bool.isRequired,
}

const defaultProps = {}

function DownloadTableButton({
  data,
  fieldMappings,
  fileName,
  disabled,
  showIcon,
}) {
  const handleExportExcel = () => {
    if (disabled) {
      return
    }
    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(
      data.map((item) => {
        const row = {}
        fieldMappings.forEach((mapping) => {
          const fieldParts = mapping.field.split('.')
          let value = item
          for (const part of fieldParts) {
            value = value?.[part]
          }
          row[mapping.displayName] = value || ''
        })
        return row
      })
    )
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
    XLSX.writeFile(workbook, `${fileName}.xlsx`)
  }
  return (
    <div className="download-table">
      <button
        onClick={handleExportExcel}
        type="button"
        className={`button-text ${disabled ? 'disabled' : ''}`}
      >
        <DownloadIcon aria-hidden="true" />
        Download table
      </button>
      {showIcon && (
        <Tooltip
          content={EMPTY_STATE_TOOLTIP_CONTENT}
          ariaLabel="Empty state information"
          plugins={[hideOnEsc, hideOnPopperBlur, preventEventBubbling]}
        >
          <InfoIcon className="icon-info" />
        </Tooltip>
      )}
    </div>
  )
}
DownloadTableButton.propTypes = propTypes
DownloadTableButton.defaultProps = defaultProps
export default DownloadTableButton
