import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import * as actions from '../actions'
import FiduciarySearchForm from '../forms/FiduciarySearchForm'
import { useHistory, Redirect } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { Path } from 'config/portal'
import { TOAST_ERROR_CONTACT_SUPPORT_EMAIL } from 'config/notifications'
import * as apiActions from 'api-actions'

const propTypes = {
  newSearchFlowData: Types.newSearchFlowData,
  setNewSearchFlowData: PropTypes.func.isRequired,
  flashErrorMessageHandler: PropTypes.func.isRequired,
}

const defaultProps = {
  newSearchFlowData: null,
}

function FiduciarySearchView({
  newSearchFlowData,
  setNewSearchFlowData,
  flashErrorMessageHandler,
}) {
  const history = useHistory()
  if (isEmpty(newSearchFlowData)) return <Redirect to={Path.SEARCH_TYPE} />
  const handleSubmit = async ({ finalMailing }) => {
    try {
      setNewSearchFlowData({
        ...newSearchFlowData,
        finalMailing,
      })
      history.push(Path.CONFIRM_ORDER)
    } catch (e) {
      flashErrorMessageHandler(TOAST_ERROR_CONTACT_SUPPORT_EMAIL)
    }
  }

  return (
    <div className="search-details card full-height">
      <div className="form-masthead">
        <h2>Confirm if you’d like to send a final letter</h2>
      </div>
      <div className="fiduciary-search-form">
        <FiduciarySearchForm
          initialValues={{
            finalMailing: newSearchFlowData.finalMailing ?? '',
          }}
          onSubmit={handleSubmit}
        />
      </div>
    </div>
  )
}
FiduciarySearchView.propTypes = propTypes
FiduciarySearchView.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    newSearchFlowData: selectors.newSearchFlowData(state),
  }
}

const mapDispatchToProps = {
  setNewSearchFlowData: actions.setNewSearchFlowData,
  generateSearchOrderCost: apiActions.generateSearchOrderCost,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  FiduciarySearchView
)
