import React from 'react'
import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'
import { Input, Select, MaskedInput, ExternalLink } from 'components'
import CreditCardFields from '../main/profile/forms/CreditCardFields'
import { paymentTypeOptions, COMPANY_NAME } from 'config/portal'
import {
  COUNTRIES,
  US_STATES,
  US_COUNTRY_VALUE,
  US_ZIP_CODE_REGEX,
} from 'config/locations'
import { findUSState } from 'utils'
import { get } from 'lodash'
import * as Yup from 'yup'
import PciCompliance from 'images/credibility_pci-logo.png'

const propTypes = {
  sectionName: PropTypes.string,
}
const defaultProps = {
  sectionName: '',
}

const FIELD_NAMES = {
  paymentType: 'paymentType',
  firstName: 'firstName',
  lastName: 'lastName',
  cardData: 'cardData',
  country: 'country',
  address1: 'address1',
  address2: 'address2',
  city: 'city',
  state: 'state',
  zip: 'zip',
}

function getFieldName(sectionName, fieldName) {
  if (!fieldName) throw new Error('fieldName is required')
  if (!sectionName) return fieldName
  return `${sectionName}.${fieldName}`
}

function generateFieldNames(sectionName) {
  let fieldNames = {}
  Object.entries(FIELD_NAMES).forEach(([key, value]) => {
    const fieldName = getFieldName(sectionName, value)
    fieldNames[key] = fieldName
  })
  return fieldNames
}

const COUNTRY_OPTIONS = COUNTRIES.map(({ fullName, value }) => ({
  key: fullName,
  value,
}))

const US_STATE_OPTIONS = US_STATES.map(({ value, fullName }) => ({
  key: fullName,
  value,
}))

const COUNTRY_VALUES = COUNTRY_OPTIONS.map(({ value }) => value)
const US_STATE_VALUES = US_STATE_OPTIONS.map(({ value }) => value)
const paymentMethodValues = paymentTypeOptions.map(({ value }) => value)
export const paymentSchema = Yup.object({
  paymentType: Yup.string()
    .oneOf(paymentMethodValues, 'Must be a valid payment method')
    .required(),
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  cardData: Yup.object().required('Please complete the credit card capture'),
  country: Yup.string().oneOf(COUNTRY_VALUES).required('Required'),
  address1: Yup.string().required('Required'),
  address2: Yup.string(),
  city: Yup.string().required('Required'),
  state: Yup.string()
    .required('Required')
    .when('country', {
      is: US_COUNTRY_VALUE,
      then: (schema) =>
        schema.oneOf(US_STATE_VALUES, 'Must be a valid US state'),
    }),
  zip: Yup.string()
    .required('Required')
    .when('country', {
      is: US_COUNTRY_VALUE,
      then: (schema) =>
        schema.matches(
          US_ZIP_CODE_REGEX,
          'US Zip Codes must have format: XXXXX or XXXXX-XXXX'
        ),
    }),
})

function PaymentFields({ sectionName }) {
  const { values, setFieldValue, setFieldError, setFieldTouched } =
    useFormikContext()
  const resetField = (field) => {
    setFieldValue(field, '')
    setFieldError(field, '')
    setFieldTouched(field, false, false)
  }
  const {
    paymentType,
    firstName,
    lastName,
    cardData,
    address1,
    address2,
    city,
    state,
    zip,
    country,
  } = generateFieldNames(sectionName)
  const isCountryUS = get(values, country) === US_COUNTRY_VALUE

  return (
    <>
      <fieldset>
        <legend>Enter your payment information</legend>
        <p>
          We’ll need a payment method on file for account owners so we can
          charge transaction fees.
        </p>
        <div className="form-column">
          <Select
            name={paymentType}
            options={paymentTypeOptions}
            label=" "
            disabled
          />
        </div>
      </fieldset>
      <div className="form-column full-row pci-compliance-container">
        <img src={PciCompliance} alt="" />
        <p>
          {COMPANY_NAME.short}’s online processes are PCI DSS compliant. For
          more information on the online security requirements of capturing
          cardholder data visit{' '}
          <ExternalLink
            link={process.env.REACT_APP_PCI_SECURITY_STANDARDS_URL}
            className="link-text"
            aria-label="Download the new updated spreadsheet"
          >
            PCI security standards
          </ExternalLink>
          .
        </p>
      </div>
      <fieldset>
        <legend>Name of cardholder</legend>
        <p className="card-holder-text-info">
          <em>As it appears on card</em>
        </p>
        <div className="form-column">
          <Input name={firstName} label="First name" />
          <Input name={lastName} label="Last name" />
        </div>
      </fieldset>
      <fieldset>
        <legend>Credit card</legend>
        <CreditCardFields sectionName={cardData} />
      </fieldset>
      <fieldset>
        <legend>Billing address</legend>
        <Select
          name={country}
          options={COUNTRY_OPTIONS}
          onChange={(e) => {
            const value = e.target.value
            const currentValue = get(values, country)

            if (value === US_COUNTRY_VALUE) {
              const foundState = findUSState(get(values, state))
              if (foundState) {
                setFieldValue(state, foundState.value)
              } else {
                resetField(state)
              }
            } else if (currentValue === US_COUNTRY_VALUE) {
              resetField(state)
            }
          }}
        />
        <Input name={address1} label="Address line 1" />
        <Input name={address2} label="Address line 2" />
        <div className="form-column">
          <Input name={city} />
          {isCountryUS ? (
            <Select name={state} options={US_STATE_OPTIONS} />
          ) : (
            <Input name={state} />
          )}
          {isCountryUS ? (
            <MaskedInput
              name={zip}
              label="Zip code"
              maskOptions={{
                numericOnly: true,
                blocks: [5, 0, 4],
                delimiters: ['', '-'],
              }}
              className="flex-shrink"
            />
          ) : (
            <Input name={zip} className="flex-shrink" />
          )}
        </div>
      </fieldset>
    </>
  )
}

PaymentFields.propTypes = propTypes
PaymentFields.defaultProps = defaultProps

export default PaymentFields
