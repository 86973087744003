import React, { useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Modal } from 'lp-components'
import { useAuth0 } from '@auth0/auth0-react'
import { clearSessionToken } from 'auth'
import { isNil } from 'lodash'
import { addSeconds } from 'date-fns'
import { useTimeRemaining } from 'utils'
import { getTimerStartDate, setTimeoutRedirect } from 'local-storage'
import isEqual from 'date-fns/isEqual'

const propTypes = {
  onContinue: PropTypes.func.isRequired,
  timeoutDuration: PropTypes.number,
}

const defaultProps = {
  timeoutDuration: 60,
}

function IdleModal({ onContinue, timeoutDuration }) {
  const { logout } = useAuth0()
  const initialIdleTimer = useMemo(getTimerStartDate, [])
  const deadline = useMemo(
    () => addSeconds(new Date(), timeoutDuration),
    [timeoutDuration]
  )
  const msRemaining = useTimeRemaining(deadline)
  const secondsRemaining = useMemo(() => {
    if (isNil(msRemaining)) return null
    return Math.round(msRemaining / 1000)
  }, [msRemaining])

  const pluralizedSeconds = secondsRemaining > 1 ? 'seconds' : 'second'

  const logOutAndRedirect = useCallback(() => {
    const { pathname, search, hash } = window.location
    setTimeoutRedirect(pathname + search + hash)
    clearSessionToken()
    logout({ returnTo: window.location.origin })
  }, [logout])

  useEffect(() => {
    if (!isNil(secondsRemaining) && secondsRemaining <= 0) {
      // Only log out if timer has not been reset in another tab
      const isIdleTimerActive = !isEqual(getTimerStartDate(), initialIdleTimer)
      if (isIdleTimerActive) {
        onContinue()
      } else {
        logOutAndRedirect()
      }
    }
  }, [secondsRemaining])

  return (
    <Modal preventClose={true}>
      <h2>Session expiring</h2>
      <p>
        Your session will expire in {secondsRemaining} {pluralizedSeconds}. Do
        you want to continue this session?
      </p>
      <div className="idle-modal-actions-container">
        <button
          type="button"
          id="continue-session-btn"
          className="button-primary"
          onClick={onContinue}
        >
          Cancel
        </button>
        <button type="button" id="log-out-btn" onClick={logOutAndRedirect}>
          Quit & Exit
        </button>
      </div>
    </Modal>
  )
}

IdleModal.propTypes = exact(propTypes)
IdleModal.defaultProps = defaultProps

export default IdleModal
