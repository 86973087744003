import { truncate } from 'lodash'

const fileExtensionRegex = /(?<extension>\.[A-Za-z0-9]+)(?<reference>#.+)?$/

function truncateFileName(
  fileName,
  { maxStringLength = 70, truncatedLength = 50 } = {}
) {
  if (fileName.length < maxStringLength) return fileName

  const match = fileName.match(fileExtensionRegex)
  if (match && match?.groups) {
    const name = fileName.replace(fileExtensionRegex, '')
    const { extension, reference = '' } = match.groups
    const truncatedName = truncate(name, {
      length: truncatedLength,
      omission: '...',
    })
    return `${truncatedName}${extension}${reference}`
  }
}

export default truncateFileName
