import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

const propTypes = {
  children: PropTypes.node.isRequired,
}

const defaultProps = {}

function EmptyState({ children }) {
  return <div className="empty-state">{children}</div>
}

EmptyState.propTypes = exact(propTypes)
EmptyState.defaultProps = defaultProps

export default React.memo(EmptyState)
