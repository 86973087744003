import React, { useReducer, useEffect } from 'react'
import * as PropTypes from 'prop-types'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'
import * as apiActions from 'api-actions'
import { useFormikContext } from 'formik'
import { Button } from 'lp-components'
import { SupportEmailLink, SupportPhoneLink, Spinner } from 'components'
import Notification, {
  Variant as NotificationVariant,
} from 'components/Notification'
import { get } from 'lodash'

const propTypes = {
  sectionName: PropTypes.string,
}

const defaultProps = {
  sectionName: 'cardData',
}

const Status = {
  LOADING: 'loading',
  ERROR: 'error',
  READY: 'ready',
  COMPLETE: 'complete',
}

const ActionType = {
  SET_TOKENS: 'set-tokens',
  SET_HEIGHT: 'set-height',
  READY: 'ready',
  COMPLETE: 'complete',
  RESET: 'reset',
  EXCEPTION: 'exception',
}

const CardCaptureEvent = {
  HEIGHT: 'CardCapture_height',
  READY: 'CardCapture_ready',
  DELETE: 'CardCapture_delete',
  COMPLETE: 'CardCapture_complete',
  RELOAD: 'CardCapture_reload',
  EXCEPTION: 'CardCapture_exception',
}

const initialState = {
  height: '150px',
  status: Status.LOADING,
  paymentToken: '',
  fraudId: '',
  cardData: {},
}

const CARD_CAPTURE_ORIGIN = new URL(process.env.REACT_APP_CARD_CAPTURE_URL)
  .origin

const reducer = (state, action) => {
  switch (action.type) {
    case ActionType.SET_TOKENS:
      return {
        ...state,
        paymentToken: action.payload.token,
        fraudId: action.payload.fraudInfoId,
      }
    case ActionType.SET_HEIGHT:
      return {
        ...state,
        height: action.payload,
      }
    case ActionType.READY:
      return {
        ...state,
        status: Status.READY,
        height: action.payload.height,
      }
    case ActionType.COMPLETE:
      return {
        ...state,
        status: Status.COMPLETE,
        cardData: action.payload.cardData,
        height: action.payload.height,
      }
    case ActionType.EXCEPTION:
      return {
        ...state,
        status: Status.ERROR,
        height: action.payload?.height,
      }
    case ActionType.RESET:
      return initialState
    default:
      throw Error(`Unhandled action: ${action.type}`)
  }
}

function CreditCardFields({ sectionName }) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const reduxDispatch = useDispatch()
  const { setFieldValue, setFieldTouched, errors, touched } = useFormikContext()

  useEffect(() => {
    async function getToken() {
      try {
        const response = await reduxDispatch(apiActions.fetchCardToken())
        dispatch({ type: ActionType.SET_TOKENS, payload: response })
      } catch {
        dispatch({ type: ActionType.EXCEPTION })
      }
    }
    if (!state.paymentToken) {
      getToken()
    }
  }, [reduxDispatch, state.paymentToken])

  useEffect(() => {
    if (!state.paymentToken) return

    const handleCardCaptureEvent = (event) => {
      if (event == null || event.origin !== CARD_CAPTURE_ORIGIN) return // verify identity of sender

      const data = JSON.parse(event.data)

      switch (data.eventName) {
        case CardCaptureEvent.HEIGHT:
          return dispatch({ type: ActionType.SET_HEIGHT, payload: data.height })
        case CardCaptureEvent.READY:
          return dispatch({ type: ActionType.READY, payload: data })
        case CardCaptureEvent.DELETE:
        case CardCaptureEvent.RELOAD:
          setFieldValue(sectionName, '')
          setFieldTouched(sectionName, false)
          return dispatch({ type: ActionType.RESET })
        case CardCaptureEvent.COMPLETE:
          setFieldValue(sectionName, {
            ...data.cardData,
            paymentToken: state.paymentToken,
            fraudId: state.fraudId,
          })
          return dispatch({ type: ActionType.COMPLETE, payload: data })
        case CardCaptureEvent.EXCEPTION:
          return dispatch({ type: ActionType.EXCEPTION, payload: data })
        default:
          console.log('Unhandled event from CardCapture frame:', data.eventName) // eslint-disable-line
      }
    }
    window.addEventListener('message', handleCardCaptureEvent)
    return () => {
      window.removeEventListener('message', handleCardCaptureEvent)
    }
  }, [
    dispatch,
    setFieldValue,
    setFieldTouched,
    state.paymentToken,
    state.fraudId,
  ])

  const hasFieldError = !!get(errors, sectionName)

  if (state.status === Status.ERROR)
    return (
      <div>
        <Notification type={NotificationVariant.ERROR}>
          An unexpected error has occurred. Please try reloading the secure
          payment frame. If the issue continues, please contact support at{' '}
          <SupportEmailLink /> or call at <SupportPhoneLink />.
        </Notification>
        <Button onClick={() => dispatch({ type: ActionType.RESET })}>
          Reload
        </Button>
      </div>
    )

  return (
    <div
      className={classNames({
        'is-loading': state.status === Status.LOADING,
        error: hasFieldError,
      })}
      style={{
        minHeight: state.height,
        position: 'relative',
        opacity: state.status === Status.LOADING ? 0.7 : 1,
        marginBottom: '18px',
      }}
    >
      {state.status === Status.LOADING && (
        <Spinner
          style={{
            position: 'absolute',
            top: 'calc(50% - 15px)',
            left: 'calc(50% - 15px)',
          }}
        />
      )}
      {state.paymentToken ? (
        <iframe
          aria-label="Secure credit card capture"
          style={{
            border: 'medium none',
            overflow: 'hidden',
            display: 'block',
            visibility:
              state.status === Status.READY || state.status === Status.COMPLETE
                ? 'visible'
                : 'hidden',
          }}
          scrolling="no" // this attribute is deprecated, but it's needed to remove the unnecessary horizontal scroll on Windows
          width="100%"
          height={state.height}
          src={`${process.env.REACT_APP_CARD_CAPTURE_URL}?paymentToken=${state.paymentToken}&fraudToken=${state.fraudId}`}
        />
      ) : null}
      {hasFieldError && get(touched, sectionName) && (
        <span className="error-message">{get(errors, sectionName)}</span>
      )}
    </div>
  )
}

CreditCardFields.propTypes = propTypes
CreditCardFields.defaultProps = defaultProps

export default CreditCardFields
