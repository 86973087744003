import React from 'react'
import exact from 'prop-types-exact'
import PropTypes from 'prop-types'

const propTypes = {
  title: PropTypes.string.isRequired,
  details: PropTypes.node.isRequired,
  children: PropTypes.node,
  titleAs: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
}

const defaultProps = {
  children: null,
  titleAs: 'h3',
}

function NoTableResults({ title, details, children, titleAs: TitleComponent }) {
  return (
    <div className="no-table-results-container">
      <TitleComponent>{title}</TitleComponent>
      <p>{details}</p>
      {children}
    </div>
  )
}

NoTableResults.propTypes = exact(propTypes)
NoTableResults.defaultProps = defaultProps

export default React.memo(NoTableResults)
