import React from 'react'
import PropTypes from 'prop-types'
import { flashMessageType, removeMessage } from 'redux-flash'
import { FlashMessage } from 'lp-components'
import DOMPurify from 'dompurify'
import { Icon as ErrorIcon } from 'images/error-icon.svg'
import { Icon as SuccessIcon } from 'images/success-icon.svg'
import { useDispatch } from 'react-redux'

/**
 * This is an override of lp-component's FlashMessageContainer to facilitate
 * rendering raw html (e.g., a link) in the message _and_ dismissing an
 * individual message.
 *
 * NOTE: The string has to be a plain string or valid html, not jsx.
 * Do: <span class="danger">Hello</span>
 * Don't do: <SpanComponent className="danger" />
 *
 * @example
 * flashErrorMessage(
 *  'Oops! <span class="danger">Something went wrong</span>',
 *  { timeout: false, props: { dismissable: true } },
 * )
 */

const propTypes = {
  messages: PropTypes.arrayOf(flashMessageType).isRequired,
  limit: PropTypes.number,
}

const defaultProps = {
  limit: 5,
}

function FlashMessageContainer({ messages, limit, ...rest }) {
  const dispatch = useDispatch()
  const messagesToDisplay = messages.slice(0, limit)
  return (
    <div className="flash-message-container" role="alert">
      {messagesToDisplay.map((message) => {
        const { dismissable = true } = message.props
        const onDismiss = dismissable
          ? () => dispatch(removeMessage(message.id))
          : null
        const IconComponent = message.isError ? ErrorIcon : SuccessIcon
        const headingText = message.isError ? 'Error!' : 'Success!'
        return (
          <FlashMessage
            key={message.id}
            isError={message.isError}
            onDismiss={onDismiss}
            {...message.props}
            {...rest}
          >
            <div className="flash-message__inner">
              <IconComponent
                className="flash-icon"
                width="32"
                height="32"
                aria-hidden="true"
              />
              <div className="flash-message__content">
                <p className="flash-message__heading">{headingText}</p>
                <p
                  className="text-body--medium"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(message.message, {
                      ADD_ATTR: ['target'],
                    }),
                  }}
                ></p>
              </div>
            </div>
          </FlashMessage>
        )
      })}
    </div>
  )
}

FlashMessageContainer.propTypes = propTypes
FlashMessageContainer.defaultProps = defaultProps

export default FlashMessageContainer
