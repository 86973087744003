import React from 'react'
import { Formik } from 'formik'
import { ScrollErrorForm, RadioGroup } from 'components'
import * as Yup from 'yup'
import { map } from 'lodash'
import { FlowActions } from '../../products/components'
import PropTypes from 'prop-types'
import { Icon as StandardTypeIcon } from 'images/rollover-types/standard-rollover.svg'
import { Icon as UnCashedCheckTypeIcon } from 'images/rollover-types/uncashed-check.svg'

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  rolloverTypes: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      subDesc: PropTypes.string.isRequired,
    })
  ).isRequired,
}

function getIcon(rolloverTypeKey) {
  switch (rolloverTypeKey) {
    case 'standard':
      return StandardTypeIcon
    case 'uncashed-checks':
      return UnCashedCheckTypeIcon
    default:
      return () => null
  }
}
function RolloverOptionLabel({ htmlFor, option }) {
  const RolloverIcon = getIcon(option.key)
  return (
    <label htmlFor={htmlFor}>
      <div className="icon-container">
        <RolloverIcon aria-hidden="true" />
      </div>
      <div className="description-container">
        <p className="label">{option.value}</p>
        <p className="description">{option.description}</p>
        <p className="description">{option.subDesc}</p>
      </div>
    </label>
  )
}

function SelectRolloverTypeForm({ initialValues, onSubmit, rolloverTypes }) {
  const rolloverTypesOptions = map(rolloverTypes, 'value')
  const rolloverTypeSchema = Yup.object({
    rolloverType: Yup.string()
      .oneOf(rolloverTypesOptions, 'Invalid rollover type')
      .required('required'),
  })

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={rolloverTypeSchema}
    >
      {() => (
        <ScrollErrorForm>
          <div className="form-section">
            <div className="form-header">
              <h3 id="select-rollover-type">Select a rollover type.</h3>
            </div>
            <RadioGroup
              name="rolloverType"
              label={false}
              ariaLabelledby="select-rollover-type"
              options={rolloverTypes}
              className="card-radio-group"
              radioInputProps={{
                labelComponent: ({ htmlFor, option }) => {
                  return (
                    <RolloverOptionLabel htmlFor={htmlFor} option={option} />
                  )
                },
              }}
            />
          </div>
          <FlowActions preventBack />
        </ScrollErrorForm>
      )}
    </Formik>
  )
}

SelectRolloverTypeForm.propTypes = propTypes
export default SelectRolloverTypeForm
