function formatCurrency(input, decimal = 2) {
  const coercedValue = parseFloat(input)
  if (!isFinite(coercedValue)) return ''

  return coercedValue.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
  })
}

export default formatCurrency
