import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import * as actions from '../actions'
import { Redirect, useHistory } from 'react-router-dom'
import { ExternalLink } from 'components'
import {
  RecordTypes,
  searchOrderProhibitedCompaniesRegex,
} from 'flatfile-config'
import { formatAndValidateSearchRecord } from 'utils'
import * as Types from 'types'
import { isEmpty } from 'lodash'
import { FlowActions } from '../components'
import { Icon as DownloadIcon } from 'images/download-icon.svg'
import { Icon as UploadIcon } from 'images/upload-icon.svg'
import { Path, isFlatfileV4Enabled } from 'config/portal'
import { ReasonForDiscard } from 'config/automatic-rollovers'
import { SearchTypeID } from 'config/search-services'
import { FlatfileProvider } from '@flatfile/react'
import FlatfileUpload from '../../../components/FlatfileUpload'
import FlatfileButton from '../../../components/FlatfileButton'

const propTypes = {
  newSearchFlowData: Types.newSearchFlowData,
  setNewSearchFlowData: PropTypes.func.isRequired,
  resetDiscardedParticipants: PropTypes.func.isRequired,
}

const defaultProps = {
  newSearchFlowData: null,
}

function UploadFileView({
  newSearchFlowData,
  setNewSearchFlowData,
  resetDiscardedParticipants,
}) {
  const history = useHistory()
  const [errorMessage, setErrorMessage] = useState(null)

  const handleContinue = () => history.push(Path.UPLOAD_SUMMARY)

  if (isEmpty(newSearchFlowData)) return <Redirect to={Path.SEARCH_TYPE} />

  return (
    <>
      <div className="card full-height upload-participant-file-view-container">
        <div className="form-masthead">
          <h2>Submit your participant information</h2>
          <p>
            You can submit your information by manually completing our online
            participant file or by uploading the search services template.
          </p>
        </div>
        <div className="form-section">
          <div className="form-header">
            <h3 className="flow-section-headers">
              Upload search services file
            </h3>
          </div>
          {isFlatfileV4Enabled() ? (
            <FlatfileProvider
              publishableKey={process.env.REACT_APP_FLATFILE_PK}
            >
              <FlatfileUpload
                recordType={RecordTypes.SEARCH}
                constraints={{
                  ...(newSearchFlowData.searchType ===
                  SearchTypeID.BENEFICIARY_SEARCH
                    ? { dob: [{ type: 'required' }] }
                    : {}),
                }}
                buttonContent={
                  <>
                    Submit participant information{' '}
                    <UploadIcon aria-hidden="true" />
                  </>
                }
                handleRecords={formatAndValidateSearchRecord}
                handleRecordsSubmit={handleContinue}
                handleValidData={(validData) => {
                  setNewSearchFlowData({
                    ...newSearchFlowData,
                    recordsData: validData,
                  })
                }}
                handleInvalidData={(discardedData) => {
                  const discardedParticipantsWithReason = discardedData.map(
                    (participant) => {
                      const isProhibitedPlanFiduciary = new RegExp(
                        searchOrderProhibitedCompaniesRegex,
                        'i'
                      ).test(participant.planSponsorName)
                      const reasonForDiscard = isProhibitedPlanFiduciary
                        ? ReasonForDiscard.PROHIBITED_COMPANY
                        : ReasonForDiscard.INVALID_DATA
                      return {
                        ...participant,
                        reasonForDiscard,
                      }
                    }
                  )
                  resetDiscardedParticipants(discardedParticipantsWithReason)
                }}
              />
            </FlatfileProvider>
          ) : (
            <FlatfileButton
              recordType={RecordTypes.SEARCH}
              validators={{
                ...(newSearchFlowData.searchType ===
                SearchTypeID.BENEFICIARY_SEARCH
                  ? { dob: [{ validate: 'required' }] }
                  : {}),
                ...(newSearchFlowData.searchType === SearchTypeID.ADDRESS_SEARCH
                  ? {
                      ssn: [
                        {
                          validate: 'required_with_values',
                          fieldValues: {
                            address1: ['', 'N/A'],
                            city: ['', 'N/A'],
                            state: ['', 'N/A'],
                            zip: ['', 'N/A'],
                          },
                          error: 'If no Address, required',
                        },
                      ],
                      address1: [
                        {
                          validate: 'required_with_values',
                          fieldValues: {
                            ssn: ['', 'N/A'],
                          },
                          error: 'If no SSN, required',
                        },
                      ],
                      city: [
                        {
                          validate: 'required_with_values',
                          fieldValues: {
                            ssn: ['', 'N/A'],
                          },
                          error: 'If no SSN, required',
                        },
                      ],
                      state: [
                        {
                          validate: 'required_with_values',
                          fieldValues: {
                            ssn: ['', 'N/A'],
                          },
                          error: 'If no SSN, required',
                        },
                      ],
                      zip: [
                        {
                          validate: 'required_with_values',
                          fieldValues: {
                            ssn: ['', 'N/A'],
                          },
                          error: 'If no SSN, required',
                        },
                      ],
                    }
                  : {}),
              }}
              buttonContent={
                <>
                  Submit participant information{' '}
                  <UploadIcon aria-hidden="true" />
                </>
              }
              handleError={(error) => {
                if (!error) setErrorMessage(null)
                else setErrorMessage(error.message)
              }}
              handleData={(validData, discardedData) => {
                setNewSearchFlowData({
                  ...newSearchFlowData,
                  recordsData: validData,
                })
                const discardedParticipantsWithReason = discardedData.map(
                  (participant) => {
                    const isProhibitedPlanFiduciary = new RegExp(
                      searchOrderProhibitedCompaniesRegex,
                      'i'
                    ).test(participant.planSponsorName)
                    const reasonForDiscard = isProhibitedPlanFiduciary
                      ? ReasonForDiscard.PROHIBITED_COMPANY
                      : ReasonForDiscard.INVALID_DATA
                    return {
                      ...participant,
                      reasonForDiscard,
                    }
                  }
                )
                resetDiscardedParticipants(discardedParticipantsWithReason)
              }}
              handleRecords={formatAndValidateSearchRecord}
              handleRecordsSubmit={handleContinue}
            />
          )}

          {!!errorMessage && (
            <span className="error-message">{errorMessage}</span>
          )}
        </div>
        <div className="form-header">
          <h3 className="is-marginless">Upload file instructions</h3>
        </div>
        <p>1. Download the search services template.</p>
        <p>
          <ExternalLink
            link={process.env.REACT_APP_SEARCH_RECORD_TEMPLATE_URL}
            className="link-text"
            id="search-template"
          >
            Search services template
            <DownloadIcon aria-hidden="true" />
          </ExternalLink>
        </p>
        <p>2. Fill out the template and upload it above.</p>
        <p>
          Once your participant file is uploaded, if required information is not
          provided or fails validation, these rows will be discarded. You’ll get
          a discarded participant file after your file is successfully
          submitted. If this happens and you have questions, reach out to your
          relationship manager.
        </p>
      </div>
      <FlowActions
        handleSubmit={(e) => {
          e.preventDefault()
          // if, for instance, flatfile error exists, do nothing
          if (errorMessage) return
          if (isEmpty(newSearchFlowData.recordsData)) {
            setErrorMessage(
              'Please provide the required file in order to continue'
            )
            return
          }
          handleContinue()
        }}
        handleBack={() => history.push(Path.SEARCH_TYPE)}
      />
    </>
  )
}

UploadFileView.propTypes = propTypes
UploadFileView.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    newSearchFlowData: selectors.newSearchFlowData(state),
  }
}

const mapDispatchToProps = {
  setNewSearchFlowData: actions.setNewSearchFlowData,
  resetDiscardedParticipants: actions.resetDiscardedParticipants,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  UploadFileView
)
