import React from 'react'
import PropTypes from 'prop-types'
import { startCase } from 'lodash'
import * as Types from 'types'
import classnames from 'classnames'

const propTypes = {
  children: PropTypes.node,
  column: Types.tableColumn.isRequired,
  sortPath: PropTypes.string,
  ascending: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
}

function TableHeader({
  ascending,
  children,
  column: { label, name, disabled, width, className },
  onClick,
  sortPath,
}) {
  const active = sortPath === name
  const classes = classnames(getArrowClass(active, ascending), className, {
    sortable: !disabled,
  })
  return (
    <th onClick={onClick} className={classes} style={{ width }}>
      {label || startCase(name)}
      {children}
    </th>
  )
}

function getArrowClass(active, ascending) {
  if (!active) return ''
  return ascending ? 'order-ascend' : 'order-descend'
}

TableHeader.propTypes = propTypes

export default TableHeader
