import { externalConstraint } from '@flatfile/plugin-constraints'
import { recordHook } from '@flatfile/plugin-record-hook'

export const searchServiceListeners = (listener) => {
  listener.use(
    externalConstraint('planSponsorName', (value, key, { config, record }) => {
      if (
        value &&
        new RegExp(config.prohibitedCompanies.regex, 'i').test(value)
      ) {
        record.addError(key, config.prohibitedCompanies.error)
      }
    })
  )

  listener.use(
    externalConstraint('planEin', (value, key, { config, record }) => {
      if (value && !new RegExp(config.invalidEINFormat.regex).test(value)) {
        record.addError(key, config.invalidEINFormat.error)
      }
      if (value && !new RegExp(config.invalidEIN.regex).test(value)) {
        record.addError(key, config.invalidEIN.error)
      }
    })
  )

  listener.use(
    externalConstraint('planNumber', (value, key, { config, record }) => {
      if (value && new RegExp(config.invalidCharacters.regex).test(value)) {
        record.addError(key, config.invalidCharacters.error)
      }
      if (value && !new RegExp(config.mustBeThreeDigits.regex).test(value)) {
        record.addError(key, config.mustBeThreeDigits.error)
      }
    })
  )

  listener.use(
    externalConstraint('ssn', (value, key, { config, record }) => {
      if (value && new RegExp(config.ssnValidityCheck.regex).test(value)) {
        record.addError(key, config.ssnValidityCheck.error)
      }
      if (value && !new RegExp(config.formatCheck.regex).test(value)) {
        record.addError(key, config.formatCheck.error)
      }
    })
  )

  listener.use(
    recordHook('search-record', (record) => {
      const address1 = record.get('address1')
      const city = record.get('city')
      const state = record.get('state')
      const zip = record.get('zip')
      const ssn = record.get('ssn')

      // ensure that ssn is required when full address is missing
      if (
        (!address1 && !city && !state && !zip && !ssn) ||
        (!ssn && !address1) ||
        address1.toLowerCase() === 'n/a' ||
        (!ssn && !city) ||
        city.toLowerCase() === 'n/a' ||
        (!ssn && !state) ||
        state.toLowerCase() === 'n/a' ||
        (!ssn && !zip) ||
        zip.toLowerCase() === 'n/a'
      ) {
        record.addError('ssn', 'If no Address, required')
      }

      // ensure that address is required when ssn is missing
      if ((!address1 && !ssn) || ssn.toLocaleLowerCase() === 'n/a') {
        record.addError('address1', 'If no SSN, required')
      }

      // ensure that city is required when ssn is missing
      if ((!city && !ssn) || ssn.toLocaleLowerCase() === 'n/a') {
        record.addError('city', 'If no SSN, required')
      }

      // ensure that state is required when ssn is missing
      if ((!state && !ssn) || ssn.toLocaleLowerCase() === 'n/a') {
        record.addError('state', 'If no SSN, required')
      }

      // ensure that zipcode is required when ssn is missing
      if ((!zip && !ssn) || ssn.toLocaleLowerCase() === 'n/a') {
        record.addError('zip', 'If no SSN, required')
      }
      return record
    })
  )

  listener.use(
    externalConstraint('firstName', (value, key, { config, record }) => {
      if (value && new RegExp(config.checkNamePrefix.regex, 'i').test(value)) {
        record.addError(key, config.checkNamePrefix.error)
      }
      if (
        value &&
        new RegExp(config.checkNotApplicable.regex, 'i').test(value)
      ) {
        record.addError(key, config.checkNotApplicable.error)
      }
      if (
        value &&
        new RegExp(config.createMaxCharValidator.regex).test(value)
      ) {
        record.addError(key, config.createMaxCharValidator.error)
      }
    })
  )

  listener.use(
    externalConstraint('middleName', (value, key, { config, record }) => {
      if (
        value &&
        new RegExp(config.createMaxCharValidator.regex).test(value)
      ) {
        record.addError(key, config.createMaxCharValidator.error)
      }
    })
  )

  listener.use(
    externalConstraint('lastName', (value, key, { config, record }) => {
      if (
        value &&
        new RegExp(config.checkNotApplicable.regex, 'i').test(value)
      ) {
        record.addError(key, config.checkNotApplicable.error)
      }
      if (
        value &&
        new RegExp(config.createMaxCharValidator.regex).test(value)
      ) {
        record.addError(key, config.createMaxCharValidator.error)
      }
    })
  )

  listener.use(
    externalConstraint('phone', (value, key, { config, record }) => {
      if (value && !new RegExp(config.phoneNumber.regex).test(value)) {
        record.addError(key, config.phoneNumber.error)
      }
    })
  )
}
