import React from 'react'
import SelectPaymentMethodForm from '../forms/SelectPaymentMethodForm'
import { selectors } from '../reducer'
import * as actions from '../actions'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { paymentMethods } from 'config/benefit-distributions'
import { Path } from 'config/portal'

const propTypes = {
  selectedPaymentMethod: PropTypes.string,
  setSelectedPaymentMethod: PropTypes.func.isRequired,
  setParticipantsForSubmission: PropTypes.func.isRequired,
  setParticipantFileName: PropTypes.func.isRequired,
}

const defaultProps = {
  selectedPaymentMethod: '',
}

function SelectPaymentMethodView({
  setSelectedPaymentMethod,
  selectedPaymentMethod,
  setParticipantsForSubmission,
  setParticipantFileName,
}) {
  const history = useHistory()

  return (
    <div className="card full-height upload-participant-file-view-container">
      <div className="form-masthead">
        <h2>How would you like to pay Inspira’s distribution fees?</h2>
        <div>
          <p>Fees vary depending on the payment method. </p>
        </div>
      </div>
      <SelectPaymentMethodForm
        initialValues={{
          selectedPaymentMethod: selectedPaymentMethod ?? '',
        }}
        onSubmit={({ selectedPaymentMethod }) => {
          setSelectedPaymentMethod(selectedPaymentMethod)
          setParticipantsForSubmission([])
          setParticipantFileName(null)
          history.push({
            pathname: Path.UPLOAD_FILE_BD,
            state: {
              hasConfirmed: true,
              search: location.search,
            },
          })
        }}
        paymentMethods={paymentMethods}
      />
    </div>
  )
}

SelectPaymentMethodView.propTypes = propTypes
SelectPaymentMethodView.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    selectedPaymentMethod: selectors.selectedPaymentMethod(state),
  }
}
const mapDispatchToProps = {
  setSelectedPaymentMethod: actions.setSelectedPaymentMethod,
  setParticipantsForSubmission: actions.setParticipantsForSubmission,
  setParticipantFileName: actions.setParticipantFileName,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SelectPaymentMethodView
)
