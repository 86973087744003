// formats phone numbers with exactly 10 digits to (xxx)-xxx-xxxx
// other values received, displays as received

function formatPhoneNumber(phone) {
  // normalize string
  const sanitizedPhoneNumber = phone.replace(/[^\d]/g, '')

  if (sanitizedPhoneNumber.length !== 10) return phone
  const regExp = /(\d{3})(\d{3})(\d{4})/
  return sanitizedPhoneNumber.replace(regExp, '($1) $2-$3')
}

export default formatPhoneNumber
