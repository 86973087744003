import React from 'react'
import PropTypes from 'prop-types'
import BaseTooltip from './BaseTooltip'
import classnames from 'classnames'

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
}

const defaultProps = {
  className: null,
  ariaLabel: null,
}

function Tooltip({ children, className, ariaLabel, ...rest }) {
  return (
    <BaseTooltip {...rest}>
      <span
        className={classnames('tooltip', className)}
        aria-label={ariaLabel}
        tabIndex="0"
      >
        {children}
      </span>
    </BaseTooltip>
  )
}

Tooltip.propTypes = propTypes
Tooltip.defaultProps = defaultProps

export default React.memo(Tooltip)
