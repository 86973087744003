import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  FlashMessageContainer,
  WorkFlowHeader,
  ExitConfirmPrompt,
} from 'components'
import { getFlashMessages, flashMessageType } from 'redux-flash'
import { selectors as globalSelectors } from '../main/reducer'
import { useBeforeunload } from 'react-beforeunload'
import { WorkFlowTitles } from 'config/portal'
import classNames from 'classnames'

const propTypes = {
  flashMessages: PropTypes.arrayOf(flashMessageType).isRequired,
  children: PropTypes.node.isRequired,
  showIdleModal: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  exitPromptSubject: PropTypes.string.isRequired,
}

const defaultProps = {}

function WorkFlowPageLayout({
  children,
  flashMessages,
  showIdleModal,
  title,
  exitPromptSubject,
}) {
  useBeforeunload(() => {
    if (!showIdleModal) {
      return 'You’ll lose your data!'
    }
  })

  return (
    <>
      <ExitConfirmPrompt
        contentText={`Your progress won’t be saved, and you’ll have to restart a new ${exitPromptSubject}.`}
      />
      <FlashMessageContainer messages={flashMessages} />
      <WorkFlowHeader title={title} />
      <div
        className={classNames('workflow-layout-wrapper', {
          'workflow-search-services': title === WorkFlowTitles.SEARCH_SERVICES,
        })}
      >
        {children}
      </div>
    </>
  )
}

WorkFlowPageLayout.propTypes = propTypes

WorkFlowPageLayout.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    flashMessages: getFlashMessages(state),
    currentUser: globalSelectors.currentUser(state),
    showIdleModal: globalSelectors.showIdleModal(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  WorkFlowPageLayout
)
