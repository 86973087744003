import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect } from 'react-router-dom'
import * as Views from './views'
import Layout from './Layout'
import {
  NewSearchStage,
  SearchServicesNewServicesAgreement,
  WorkFlowTitles,
  NEW_SEARCH_STAGES,
  NEW_AGREEMENT_STAGES,
  CONFIRMED_SEARCH_STAGES,
  FIDUCIARY_SEARCH_STAGES,
} from 'config/portal'
import { SearchTypeID } from 'config/search-services'

const propTypes = {
  match: PropTypes.object.isRequired,
  hasSearchServiceAgreement: PropTypes.bool.isRequired,
}

const defaultProps = {}

function Routes({ match: { path }, hasSearchServiceAgreement }) {
  window.appEventData.pop()
  var appEventData = window.appEventData || []
  appEventData.push({
    event: 'Page Load Completed',
  })
  const [searchStages, setSearchStages] = useState(NEW_SEARCH_STAGES)
  return (
    <Switch>
      {hasSearchServiceAgreement && (
        <Route
          path={path + '/search-type'}
          render={() => (
            <Layout
              subject="search order"
              title={WorkFlowTitles.SEARCH_SERVICES}
              stages={searchStages}
              currentStage={NewSearchStage.searchSelection}
            >
              <Views.SearchTypeView
                onContinue={(searchType) => {
                  if (searchType === SearchTypeID.CONFIRMED_SEARCH) {
                    setSearchStages(CONFIRMED_SEARCH_STAGES)
                  } else if (searchType === SearchTypeID.FIDUCIARY_SEARCH) {
                    setSearchStages(FIDUCIARY_SEARCH_STAGES)
                  } else {
                    setSearchStages(NEW_SEARCH_STAGES)
                  }
                }}
              />
            </Layout>
          )}
        />
      )}
      {hasSearchServiceAgreement && (
        <Route
          path={path + '/upload-file'}
          render={(routeProps) => (
            <Layout
              subject="search order"
              title={WorkFlowTitles.SEARCH_SERVICES}
              stages={searchStages}
              currentStage={NewSearchStage.uploadFile}
            >
              <Views.UploadFileView {...routeProps} />
            </Layout>
          )}
        />
      )}
      {hasSearchServiceAgreement && (
        <Route
          path={path + '/upload-summary'}
          render={(routeProps) => (
            <Layout
              subject="search order"
              title={WorkFlowTitles.SEARCH_SERVICES}
              stages={searchStages}
              currentStage={NewSearchStage.uploadFile}
            >
              <Views.UploadSummaryView {...routeProps} />
            </Layout>
          )}
        />
      )}
      {hasSearchServiceAgreement && (
        <Route
          path={path + '/permissible-use'}
          render={() => (
            <Layout
              subject="search order"
              title={WorkFlowTitles.SEARCH_SERVICES}
              stages={searchStages}
              currentStage={NewSearchStage.submissionDetails}
            >
              <Views.PermissibleUseView nextStagePath={searchStages[3].path} />
            </Layout>
          )}
        />
      )}
      <Route
        path={path + '/final-letter'}
        render={(routeProps) => (
          <Layout
            subject="final letter"
            title={WorkFlowTitles.SEARCH_SERVICES}
            stages={searchStages}
            currentStage={NewSearchStage.finalLetter}
          >
            <Views.FiduciarySearchView {...routeProps} />
          </Layout>
        )}
      />
      <Route
        path={path + '/mailing-preferences'}
        render={(routeProps) => (
          <Layout
            subject="mailing-preferences"
            title={WorkFlowTitles.SEARCH_SERVICES}
            stages={searchStages}
            currentStage={NewSearchStage.mailingPreferences}
          >
            <Views.ConfirmedSearchView {...routeProps} />
          </Layout>
        )}
      />
      {hasSearchServiceAgreement && (
        <Route
          path={path + '/confirm-submission'}
          render={(routeProps) => (
            <Layout
              subject="search order"
              title={WorkFlowTitles.SEARCH_SERVICES}
              stages={searchStages}
              currentStage={NewSearchStage.confirmSubmission}
            >
              <Views.ConfirmSubmissionView {...routeProps} />
            </Layout>
          )}
        />
      )}
      {!hasSearchServiceAgreement && (
        <Route
          path={path + '/service-agreement-details'}
          render={(routeProps) => {
            return (
              <Layout
                title={WorkFlowTitles.CREATE_NEW_SEARCH_SERVICES_AGREEMENT}
                subject="services agreement"
                stages={NEW_AGREEMENT_STAGES}
                currentStage={
                  SearchServicesNewServicesAgreement.serviceAgreementDetails
                }
              >
                <Views.ServiceAgreementDetailsView {...routeProps} />
              </Layout>
            )
          }}
        />
      )}
      {!hasSearchServiceAgreement && (
        <Route
          path={path + '/add-new-services-agreement'}
          render={(routeProps) => {
            return (
              <Layout
                title={WorkFlowTitles.CREATE_NEW_SEARCH_SERVICES_AGREEMENT}
                subject="services agreement"
                stages={NEW_AGREEMENT_STAGES}
                currentStage={
                  SearchServicesNewServicesAgreement.addNewServicesAgreement
                }
              >
                <Views.AddNewServicesAgreementView {...routeProps} />
              </Layout>
            )
          }}
        />
      )}

      <Redirect path="*" to="/products/search/history" />
    </Switch>
  )
}

Routes.propTypes = propTypes
Routes.defaultProps = defaultProps

export default Routes
