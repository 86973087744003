import React from 'react'
import PropTypes from 'prop-types'
import PermissibleUseForm from '../forms/PermissibleUseForm'
import { Redirect, useHistory } from 'react-router-dom'
import * as Types from 'types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import * as actions from '../actions'
import { Path } from 'config/portal'
import { SearchTypeID } from 'config/search-services'
import { isEmpty } from 'lodash'

const propTypes = {
  newSearchFlowData: Types.newSearchFlowData,
  setNewSearchFlowData: PropTypes.func.isRequired,
  nextStagePath: PropTypes.string.isRequired,
}

const defaultProps = {
  newSearchFlowData: null,
}

function PermissibleUseView({
  newSearchFlowData,
  setNewSearchFlowData,
  nextStagePath,
}) {
  const history = useHistory()

  if (isEmpty(newSearchFlowData)) return <Redirect to={Path.SEARCH_TYPE} />
  if (isEmpty(newSearchFlowData.recordsData))
    return <Redirect to={Path.UPLOAD_FILE} />
  if (
    newSearchFlowData.searchType !== SearchTypeID.CONFIRMED_SEARCH ||
    newSearchFlowData.searchType !== SearchTypeID.FIDUCIARY_SEARCH
  ) {
    newSearchFlowData.finalMailing = ''
    newSearchFlowData.mailingMethod = ''
    newSearchFlowData.numberofMailings = ''
  }
  const isAdvancedDeathAudit =
    newSearchFlowData.searchType === SearchTypeID.ADVANCED_DEATH_AUDIT
  return (
    <div className="search-details card full-height">
      <div className="form-masthead">
        <h2>Confirm the permissible uses of your search</h2>
        <p>
          If your request includes more than one plan and the permissible uses
          are not the same for each plan, submit a separate search request for
          each plan.
        </p>
      </div>
      <div className="permissible-use-form">
        <PermissibleUseForm
          initialValues={{
            dmfPermissible: newSearchFlowData.dmfPermissible ?? '',
            glbaPermissible: newSearchFlowData.glbaPermissible ?? '',
            dppaPermissible: newSearchFlowData.dppaPermissible ?? '',
            dmfPermissibleAdditional:
              newSearchFlowData.dmfPermissibleAdditional ?? '',
            confirm: '',
          }}
          isAdvancedDeathAudit={isAdvancedDeathAudit}
          onSubmit={({
            dmfPermissible,
            dmfPermissibleAdditional,
            glbaPermissible,
            dppaPermissible,
          }) => {
            setNewSearchFlowData({
              ...newSearchFlowData,
              dmfPermissible,
              dmfPermissibleAdditional,
              glbaPermissible,
              dppaPermissible,
            })
            history.push(nextStagePath)
          }}
        />
      </div>
    </div>
  )
}
PermissibleUseView.propTypes = propTypes
PermissibleUseView.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    newSearchFlowData: selectors.newSearchFlowData(state),
  }
}

const mapDispatchToProps = {
  setNewSearchFlowData: actions.setNewSearchFlowData,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PermissibleUseView
)
