import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { kebabCase } from 'lodash'
import { SearchOrderStatus } from 'config/search-services'
import {
  ServicesAgreementStatus,
  RolloverAccountStatus,
} from 'config/automatic-rollovers'
import { Icon as CheckmarkIcon } from 'images/status-icons/checkmark.svg'
import { Icon as AlertIcon } from 'images/status-icons/alert.svg'
import { Icon as ClockIcon } from 'images/status-icons/clock.svg'
import { Icon as PencilIcon } from 'images/status-icons/pencil.svg'
import { Icon as ChecklistIcon } from 'images/status-icons/checklist.svg'

const propTypes = {
  status: PropTypes.string.isRequired,
}

const defaultProps = {}

const getStatusIcon = (status) => {
  switch (status) {
    case ServicesAgreementStatus.COMPLETED:
    case SearchOrderStatus.RESULTS_AVAILABLE:
    case RolloverAccountStatus.ROLLOVER_COMPLETE:
      return CheckmarkIcon
    case ServicesAgreementStatus.IN_REVIEW:
    case SearchOrderStatus.IN_PROGRESS:
    case SearchOrderStatus.RESULTS_EXPIRED:
    case RolloverAccountStatus.PROCESSING:
      return ClockIcon
    case ServicesAgreementStatus.PENDING_SIGNATURES:
      return PencilIcon
    case SearchOrderStatus.FAILED:
      return AlertIcon
    case RolloverAccountStatus.READY_FOR_FUNDING:
      return ChecklistIcon
    default:
      return () => null
  }
}

function StatusBadge({ status }) {
  const StatusIcon = getStatusIcon(status)
  return (
    <span className={classnames('status', `badge--${kebabCase(status)}`)}>
      <StatusIcon aria-hidden="true" />
      {status}
    </span>
  )
}

StatusBadge.propTypes = propTypes
StatusBadge.defaultProps = defaultProps

export default StatusBadge
