import { handleActions } from 'redux-actions'
import { selectorForSlice, unsetState, setState } from 'lp-redux-utils'
import { setOnSuccess, handleSuccess } from 'lp-redux-api'
import * as apiActions from 'api-actions'
import { createSelector } from 'reselect'
import { uniq, map, compact } from 'lodash'
import * as actions from './actions'
import { set } from 'lodash/fp'

const reducerKey = 'product'
const slice = 'root.product'

const initialState = {
  shouldTriggerAllServicesAgreementsFetch: true,
  shouldTriggerServicesAgreementDetailsFetch: true,
  searchShouldTriggerAllServicesAgreementsFetch: false,
  shouldTriggerSearchesFetch: true,
}

const reducer = handleActions(
  {
    [apiActions.fetchSearches]: setOnSuccess('searches'),
    [apiActions.fetchOrderDetails]: setOnSuccess('searchOrder'),
    [apiActions.fetchServicesAgreements]: setOnSuccess('servicesAgreements'),
    [apiActions.fetchServicesAgreementDetails]:
      setOnSuccess('servicesAgreement'),
    [actions.clearServicesAgreementDetails]: unsetState('servicesAgreement'),
    [actions.setShouldTriggerAllServicesAgreementsFetch]: setState(
      'shouldTriggerAllServicesAgreementsFetch'
    ),
    [actions.setShouldTriggerServicesAgreementDetailsFetch]: setState(
      'shouldTriggerServicesAgreementDetailsFetch'
    ),
    [apiActions.fetchAvailableInitialInvestments]: setOnSuccess(
      'availableInitialInvestments'
    ),
    [apiActions.fetchServicesAgreementParticipants]: handleSuccess(
      (state, action) => {
        const { servicesAgreementId, participants } = action.payload.data
        return set(
          `servicesAgreementParticipants.${servicesAgreementId}`,
          participants,
          state
        )
      }
    ),
    [apiActions.fetchSearchServices]: setOnSuccess('searchServices'),
    [apiActions.generateSearchOrderCost]: setOnSuccess('searchOrderCost'),
    [actions.resetSearchOrderCost]: unsetState('searchOrderCost'),
    [actions.setSearchShouldTriggerAllServicesAgreementsFetch]: setState(
      'searchShouldTriggerAllServicesAgreementsFetch'
    ),
    [actions.setShouldTriggerSearchesFetch]: setState(
      'shouldTriggerSearchesFetch'
    ),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  searches: select('searches'),
  searchOrder: select('searchOrder'),
  servicesAgreements: select('servicesAgreements'),
  servicesAgreement: select('servicesAgreement'),
  shouldTriggerAllServicesAgreementsFetch: select(
    'shouldTriggerAllServicesAgreementsFetch'
  ),
  shouldTriggerServicesAgreementDetailsFetch: select(
    'shouldTriggerServicesAgreementDetailsFetch'
  ),
  availableInitialInvestments: select('availableInitialInvestments'),
  servicesAgreementParticipants: (state, props) =>
    select(`servicesAgreementParticipants.${props.servicesAgreementId}`)(state),
  searchServices: select('searchServices'),
  searchOrderCost: select('searchOrderCost'),
  searchShouldTriggerAllServicesAgreementsFetch: select(
    'searchShouldTriggerAllServicesAgreementsFetch'
  ),
  shouldTriggerSearchesFetch: select('shouldTriggerSearchesFetch'),
}

selectors.planFiduciaries = createSelector([selectors.searches], (searches) => {
  if (!searches) return
  const planFiduciaryList = compact(map(searches, 'planFiduciary'))
  return uniq(planFiduciaryList).sort()
})

export { reducer, selectors, reducerKey }
