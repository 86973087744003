import React from 'react'
import PropTypes from 'prop-types'
import Spinner from './Spinner'
import classnames from 'classnames'

const propTypes = {
  className: PropTypes.string,
  showBackground: PropTypes.bool,
}

const defaultProps = {
  className: '',
  showBackground: false,
}

function FullPageSpinner({ className, showBackground, ...rest }) {
  return (
    <Spinner
      className={classnames('spinner-full-screen', className, {
        'spinner-full-screen--with-bg': showBackground,
      })}
      {...rest}
    />
  )
}

FullPageSpinner.propTypes = propTypes
FullPageSpinner.defaultProps = defaultProps

export default React.memo(FullPageSpinner)
