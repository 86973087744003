import React, { useState } from 'react'
import { Button, Modal } from 'lp-components'
import { AddNewUserForm } from '../forms'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as apiActions from 'api-actions'
import { selectors as globalSelectors } from 'global-reducer'
import { flashSuccessMessage, flashErrorMessage } from 'redux-flash'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { FLASH_SUCCESS_TIMEOUT } from 'config/portal'

const propTypes = {
  flashSuccessMessageHandler: PropTypes.func.isRequired,
  flashErrorMessageHandler: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  accountID: PropTypes.string,
  onSubmitSuccess: PropTypes.func.isRequired,
  buttonStyling: PropTypes.string,
}

const defaultProps = {
  buttonStyling: '',
}

function AddNewUser({
  createUser,
  accountID,
  flashSuccessMessageHandler,
  flashErrorMessageHandler,
  onSubmitSuccess,
  buttonStyling,
}) {
  const [openModal, setOpenModal] = useState(false)
  return (
    <div>
      <Button onClick={() => setOpenModal(true)} className={buttonStyling}>
        Add new user
      </Button>
      {openModal && (
        <Modal onClose={() => setOpenModal(false)}>
          <div className="user-form">
            <h2>Add user</h2>
            <hr />
            <AddNewUserForm
              handleSubmit={async (values) => {
                try {
                  await createUser({ ...values.user, accountID })
                  flashSuccessMessageHandler('New user successfully created!', {
                    timeout: FLASH_SUCCESS_TIMEOUT,
                  })
                  setOpenModal(false)
                  onSubmitSuccess()
                } catch (e) {
                  flashErrorMessageHandler(
                    `We were unable to create this user. Please try again.`
                  )
                }
              }}
              cancelHandler={() => setOpenModal(false)}
            />
          </div>
        </Modal>
      )}
    </div>
  )
}

AddNewUser.propTypes = exact(propTypes)
AddNewUser.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    accountID: globalSelectors.accountID(state),
  }
}

const mapDispatchToProps = {
  createUser: apiActions.createUser,
  flashSuccessMessageHandler: flashSuccessMessage,
  flashErrorMessageHandler: flashErrorMessage,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(AddNewUser)
