import { RecordTypes } from './flatfileConfig'
import { aroParticipantListeners } from './aroParticipantListeners'
import { recordHook } from '@flatfile/plugin-record-hook'
import { bdParticipantListeners } from './bdParticipantListeners'
import { searchServiceListeners } from './searchServiceListeners'
import { uncashedChecksParticipantListners } from './uncashedChecksParticipantListners'

export const iraAmountValidator = (
  listener,
  sheetSlug,
  fieldsToCheck,
  mainField,
  errorMessage
) => {
  listener.use(
    recordHook(sheetSlug, (record) => {
      // Check if all specified fields are missing
      const allFieldsMissing = fieldsToCheck.every(
        (field) => !record.get(field)
      )

      if (allFieldsMissing) {
        // Validate that the main field is present and not empty
        record.validate(
          mainField,
          (value) => value !== null && value !== undefined && value !== '',
          errorMessage
        )
      }

      return record
    })
  )
}

export const recordkeeperListener = (listener, sheetSlug) => {
  listener.use(
    recordHook(sheetSlug, (record) => {
      const recordkeeper = record.get('recordkeeper')
      // if recordkeeper field is empty then show custom error message
      if (!recordkeeper) {
        record.addError(
          'recordkeeper',
          'Enter your company name if self-administering.'
        )
      }
      return record
    })
  )
}

export const listeners = {
  [RecordTypes.ARO_PARTICIPANT]: aroParticipantListeners,
  [RecordTypes.BD_PARTICIPANT]: bdParticipantListeners,
  [RecordTypes.SEARCH]: searchServiceListeners,
  [RecordTypes.UCH_PARTICIPANT]: uncashedChecksParticipantListners,
}
