import React from 'react'
import PropTypes from 'prop-types'
import { Notification } from 'components'
import { Variant as NotificationVariant } from 'components/Notification'
import { Checkbox } from 'lp-components'
import classNames from 'classnames'
import { withFormikAdapter } from 'utils'

const propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.node.isRequired,
  meta: PropTypes.object.isRequired,
  errorText: PropTypes.string,
}

const defaultProps = {
  errorText: 'To complete your confirmation, you must check the box below',
}

function ConfirmationCheckbox(props) {
  const showError = props.meta.error && props.meta.touched
  return (
    <>
      {showError && (
        <Notification type={NotificationVariant.ERROR}>
          {props.errorText}
        </Notification>
      )}
      <div
        className={classNames('confirmation-checkbox-container', {
          'is-error': showError,
        })}
      >
        <Checkbox {...props} />
      </div>
    </>
  )
}

ConfirmationCheckbox.propTypes = propTypes
ConfirmationCheckbox.defaultProps = defaultProps

export default withFormikAdapter()(ConfirmationCheckbox)
