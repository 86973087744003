import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'components'
import { Icon as InfoIcon } from 'images/info.svg'

const propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  tooltipContent: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  tooltipAriaLabel: PropTypes.string,
  required: PropTypes.bool,
}

const defaultProps = {
  id: null,
  tooltipAriaLabel: 'View more information',
  required: true,
}

const OptionalTag = () => <span className="text-body--large">(optional)</span>

function LabelWithTooltip({
  label,
  tooltipContent,
  name,
  id,
  tooltipAriaLabel,
  required,
}) {
  return (
    <span>
      <label htmlFor={id || name}>
        {label}
        {!required && (
          <>
            {' '}
            <OptionalTag />
          </>
        )}
      </label>
      <Tooltip content={tooltipContent} ariaLabel={tooltipAriaLabel}>
        <InfoIcon className="icon-info" />
      </Tooltip>
    </span>
  )
}

LabelWithTooltip.propTypes = propTypes
LabelWithTooltip.defaultProps = defaultProps

export default LabelWithTooltip
