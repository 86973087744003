import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { SearchTypeID } from 'config/search-services'
import * as Yup from 'yup'
import { Icon as AddressSearchIcon } from 'images/subscription-services/address-search-new.svg'
import { Icon as ConfirmedSearchIcon } from 'images/subscription-services/confirmed-search-new.svg'
import { Icon as FiduciarySearchIcon } from 'images/subscription-services/fiduciary-search-new.svg'
import { Icon as AdvancedDeathAuditIcon } from 'images/subscription-services/advanced-death-audit-new.svg'
import { Icon as RelativeSearchIcon } from 'images/subscription-services/relative-search-new.svg'
import { Icon as BeneficiarySearchIcon } from 'images/subscription-services/beneficiary-search-new.svg'
import { ScrollErrorForm, RadioGroup, Notification } from 'components'
import { FlowActions } from '../components'
import { Formik } from 'formik'
import { map } from 'lodash'
import { formatCurrency } from 'utils'
import { Icon as FreeIcon } from 'images/free-icon.svg'
import { Variant as NotificationVariant } from 'components/Notification'

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  searchServices: PropTypes.arrayOf(Types.searchService).isRequired,
  isUploadCompleted: PropTypes.bool.isRequired,
}

const defaultProps = {}

function getIcon(searchId) {
  switch (searchId) {
    case SearchTypeID.ADDRESS_SEARCH.toLowerCase():
      return AddressSearchIcon
    case SearchTypeID.CONFIRMED_SEARCH.toLowerCase():
      return ConfirmedSearchIcon
    case SearchTypeID.FIDUCIARY_SEARCH.toLowerCase():
      return FiduciarySearchIcon
    case SearchTypeID.ADVANCED_DEATH_AUDIT.toLowerCase():
      return AdvancedDeathAuditIcon
    case SearchTypeID.RELATIVE_SEARCH.toLowerCase():
      return RelativeSearchIcon
    case SearchTypeID.BENEFICIARY_SEARCH.toLowerCase():
      return BeneficiarySearchIcon
    default:
      return () => null
  }
}

function SearchOptionLabel({ htmlFor, option, warningMessage }) {
  const SearchIcon = getIcon(option.value.toLowerCase())
  const priceLabel = formatCurrency(option.fee, 0) + '/search'
  return (
    <label htmlFor={htmlFor}>
      <div className="icon-container">
        <SearchIcon aria-hidden="true" />
      </div>
      <div className="description-container">
        <p className="label">{option.key}</p>
        {warningMessage && (
          <Notification
            type={NotificationVariant.WARNING}
            className="notification--tight"
            isBadge={true}
          >
            {warningMessage}
          </Notification>
        )}
        <p className="description">{option.description}</p>
        <p className="turnaround">
          <strong>Estimated turnaround:</strong> {option.turnaroundTime}
        </p>
        {option.feeExemption && (
          <div className="fee-waive-text">
            <FreeIcon aria-hidden="true" />
            <p>
              <strong>Limited time deal </strong>$0/search
            </p>
          </div>
        )}
        {!option.feeExemption && (
          <p className="price text-label--medium">{priceLabel}</p>
        )}
      </div>
    </label>
  )
}
function SearchTypeForm({
  initialValues,
  isUploadCompleted,
  onSubmit,
  searchServices,
}) {
  const searchServicesOptions = searchServices.map((searchType) => {
    const { searchService } = searchType
    return {
      ...searchType,
      key: searchService,
      value: searchService,
    }
  })

  const searchServicesValues = map(searchServicesOptions, 'value')

  const searchTypeSchema = Yup.object({
    searchType: Yup.string()
      .oneOf(searchServicesValues, 'Invalid search type')
      .required('required'),
  })
  const warningMessage =
    'Selecting this search type will require you to re-upload participant file.'

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={searchTypeSchema}
      onSubmit={onSubmit}
    >
      {() => {
        return (
          <ScrollErrorForm>
            <div className="form-section">
              <div className="form-header">
                <h3 id="subscription-services">Select a search type</h3>
              </div>
              <RadioGroup
                name="searchType"
                label={false}
                ariaLabelledby="subscription-services"
                options={searchServicesOptions}
                className="card-radio-group"
                radioInputProps={{
                  labelComponent: ({ htmlFor, option }) => {
                    return (
                      <SearchOptionLabel
                        htmlFor={htmlFor}
                        option={option}
                        warningMessage={
                          option.key === SearchTypeID.BENEFICIARY_SEARCH &&
                          isUploadCompleted
                            ? warningMessage
                            : undefined
                        }
                      />
                    )
                  },
                }}
              />
            </div>
            <FlowActions preventBack />
          </ScrollErrorForm>
        )
      }}
    </Formik>
  )
}
SearchTypeForm.propTypes = propTypes
SearchTypeForm.defaultProps = defaultProps

export default SearchTypeForm
