import React from 'react'
import { ExternalLink, YoutubeVideo } from 'components'
import PropTypes from 'prop-types'
import { YOUTUBE_BASE_URL } from 'config/portal'

const propTypes = {
  videoId: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  length: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
}

const defaultProps = {}

function ResourceCard({ videoId, header, content, length, date }) {
  return (
    <div className="resources-card-container">
      <div className="resources-card-video">
        <YoutubeVideo videoId={videoId} />
      </div>
      <div className="resources-card-header">
        <ExternalLink
          link={YOUTUBE_BASE_URL + videoId}
          className="youtube-link"
        >
          {header}
        </ExternalLink>
      </div>
      <div className="resources-card-content">
        <p>{content}</p>
      </div>
      <div className="resources-card-footer">
        <p>{length}</p>
        <p>{date}</p>
      </div>
    </div>
  )
}

ResourceCard.propTypes = propTypes

ResourceCard.defaultProps = defaultProps

export default ResourceCard
