import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { StatusTracker, WorkFlowPageLayout } from 'components'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as actions from './actions'

const propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  currentStage: PropTypes.string,
  stages: PropTypes.arrayOf(Types.stage),
  clearParticipantsData: PropTypes.func.isRequired,
  clearCompleteServicesAgreementForm: PropTypes.func.isRequired,
  resetErisapediaSearches: PropTypes.func.isRequired,
}

const defaultProps = {
  currentStage: null,
  stages: [],
}

function Layout({
  children,
  title,
  subject,
  currentStage,
  stages,
  clearParticipantsData,
  clearCompleteServicesAgreementForm,
  resetErisapediaSearches,
}) {
  useEffect(() => {
    return () => {
      clearParticipantsData()
      clearCompleteServicesAgreementForm()
      resetErisapediaSearches()
    }
  }, [])

  return (
    <WorkFlowPageLayout title={title} exitPromptSubject={subject}>
      {currentStage && (
        <StatusTracker stages={stages} currentStageName={currentStage} />
      )}
      {children}
    </WorkFlowPageLayout>
  )
}

Layout.propTypes = propTypes

Layout.defaultProps = defaultProps

function mapStateToProps(/* state */) {
  return {}
}

const mapDispatchToProps = {
  clearParticipantsData: actions.clearParticipantsData,
  clearCompleteServicesAgreementForm:
    actions.clearCompleteServicesAgreementForm,
  resetErisapediaSearches: actions.resetErisapediaSearches,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Layout)
