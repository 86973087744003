import React from 'react'
import { Icon as SearchIcon } from 'images/search-icon.svg'
import PropTypes from 'prop-types'

const propTypes = {
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
}

const defaultProps = {}

function SearchInput({ placeholder, onChange, value, ...rest }) {
  return (
    <div className="search-input">
      <input
        type="search"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        {...rest}
      />
      <SearchIcon aria-hidden="true" />
    </div>
  )
}
SearchInput.propTypes = propTypes
SearchInput.defaultProps = defaultProps
export default SearchInput
