import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import * as actions from '../actions'
import ConfirmedSearchForm from '../forms/ConfirmedSearchForm'
import { Path } from 'config/portal'
import { useHistory, Redirect } from 'react-router-dom'
import { isEmpty } from 'lodash'

const propTypes = {
  newSearchFlowData: Types.newSearchFlowData,
  setNewSearchFlowData: PropTypes.func.isRequired,
}

const defaultProps = {
  newSearchFlowData: null,
}

function ConfirmedSearchView({ newSearchFlowData, setNewSearchFlowData }) {
  const history = useHistory()
  if (isEmpty(newSearchFlowData)) return <Redirect to={Path.SEARCH_TYPE} />

  return (
    <div className="search-details card full-height">
      <div className="form-masthead">
        <h2>Confirm your mailing preferences</h2>
        <p>
          Mailings are sent to the most recent address(es) found for a
          participant.
        </p>
      </div>
      <div className="confirmed-search-form">
        <ConfirmedSearchForm
          initialValues={{
            numberofMailings: newSearchFlowData.numberofMailings ?? '',
            mailingMethod: newSearchFlowData.mailingMethod ?? '',
          }}
          onSubmit={({ numberofMailings, mailingMethod }) => {
            setNewSearchFlowData({
              ...newSearchFlowData,
              numberofMailings,
              mailingMethod,
            })
            history.push(Path.CONFIRM_ORDER)
          }}
        />
      </div>
    </div>
  )
}
ConfirmedSearchView.propTypes = propTypes
ConfirmedSearchView.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    newSearchFlowData: selectors.newSearchFlowData(state),
  }
}

const mapDispatchToProps = {
  setNewSearchFlowData: actions.setNewSearchFlowData,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ConfirmedSearchView
)
