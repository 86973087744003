import React from 'react'
import { Icon } from 'images/filter-icon.svg'
import classNames from 'classnames'

function FilterIcon({ isActive }) {
  return (
    <div className="filter-icon">
      <div
        className={classNames({ 'filter-applied': isActive })}
        aria-label={isActive ? 'filters are active' : 'no active filters'}
      >
        <Icon />
      </div>
    </div>
  )
}

export default FilterIcon
