import React from 'react'

function OptionalLabel({ label, name }) {
  return (
    <label htmlFor={name}>
      {label} <span className="text-body--large">(optional)</span>
    </label>
  )
}

export default OptionalLabel
