import React from 'react'
import PropTypes from 'prop-types'
import { FlowActions } from '../../../products/components'
import { ScrollErrorForm, SearchableSelect } from 'components'
import { Formik } from 'formik'
import { reduce } from 'lodash'
import * as Yup from 'yup'

const propTypes = {
  initialValues: PropTypes.array,
  agreementErrorState: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  setNewAddendumServicesAgreement: PropTypes.func,
}

const defaultProps = {
  initialValues: {},
}

function AddNewAddendumForm({
  initialValues,
  onSubmit,
  agreementErrorState,
  setNewAddendumServicesAgreement,
}) {
  const servicesAgreementOptions = initialValues

  const servicesAgreementSchema = Yup.object(
    reduce(
      servicesAgreementOptions,
      (schema, _, key) => {
        schema[key] = Yup.object({
          name: Yup.string()
            .required('Select a services agreement')
            .oneOf(servicesAgreementOptions, 'Must be a valid option'),
        })
        return schema
      },
      {}
    )
  )
  return (
    <Formik
      validationSchema={servicesAgreementSchema}
      initialValues={initialValues}
      onSubmit={(values) => {
        onSubmit(values)
      }}
    >
      {({ values, setField, isSubmitting }) => {
        return (
          <ScrollErrorForm>
            <div className="addendum-form-container">
              <SearchableSelect
                name="addendumServicesAgreement"
                label="Select services agreement *"
                options={values}
                isMulti={false}
                placeholder="Select"
                marginTop="0"
                onChange={(e) => {
                  var selection = e.target
                  setNewAddendumServicesAgreement(selection)
                  setField('addendumServicesAgreement', selection)
                }}
              />
            </div>
            {agreementErrorState && !values.addendumServicesAgreement && (
              <p className="addendum-agreement-error">
                Select a services agreement
              </p>
            )}
            <FlowActions isSubmitting={isSubmitting} />
          </ScrollErrorForm>
        )
      }}
    </Formik>
  )
}

AddNewAddendumForm.propTypes = propTypes
AddNewAddendumForm.defaultProps = defaultProps

export default AddNewAddendumForm
