import React from 'react'
import PropTypes from 'prop-types'
import BaseTooltip from './BaseTooltip'
import classnames from 'classnames'

const propTypes = {
  children: PropTypes.node.isRequired,
  buttonId: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  ariaDisabled: PropTypes.bool,
  ariaLabel: PropTypes.string,
}

const defaultProps = {
  buttonId: null,
  className: null,
  ariaDisabled: null,
  ariaLabel: null,
}

function ClickableTooltip({
  children,
  buttonId,
  className,
  onClick,
  ariaDisabled,
  ariaLabel,
  ...rest
}) {
  return (
    <BaseTooltip {...rest}>
      <button
        id={buttonId}
        className={classnames('tooltip', className)}
        type="button"
        onClick={onClick}
        aria-disabled={ariaDisabled}
        aria-label={ariaLabel}
      >
        {children}
      </button>
    </BaseTooltip>
  )
}

ClickableTooltip.propTypes = propTypes
ClickableTooltip.defaultProps = defaultProps

export default React.memo(ClickableTooltip)
