import { omit } from 'lodash'
import { US_COUNTRY_VALUE } from 'config/locations'
import { isAddressBlank, findCountry, findUSState, isAddressEqual } from 'utils'

/* Util for setting initial values of an existing entity (e.g., plan sponsor, recordkeeper)
   in the add services agreement form. An entity consists of company and contact.
   1) The default value for the Country field is 'US' if the entity is missing address
   2) The default value for the 'Address Same As Above' field for contact is checked if
      a) contact address matches company address or b) contact is missing address */
function getEntityInitialValues({ company, contact }) {
  const companyWithSanitizedAddress = sanitizeAddressData(company, 'billing')
  const contactWithSanitizedAddress = sanitizeAddressData(contact, 'mailing')

  const companyWithDefaultAddressValues = setAddressDefaultValues(
    companyWithSanitizedAddress,
    'billing'
  )
  const contactWithDefaultAddressValues = setAddressDefaultValues(
    contactWithSanitizedAddress,
    'mailing'
  )

  const isSameAsCompanyAddress =
    isAddressEqual({
      company: companyWithDefaultAddressValues,
      contact: contactWithDefaultAddressValues,
    }) || isAddressBlank(contactWithSanitizedAddress, 'mailing')

  return {
    company: omit(companyWithDefaultAddressValues, 'ID'),
    contact: {
      ...contactWithDefaultAddressValues,
      isSameAsCompanyAddress,
    },
  }
}

// ----- PRIVATE -----

// Standardize country and US state data values: if they exist in the pre-defined lists,
// use the corresponding value, otherwise set it to an empty string
function sanitizeAddressData(entity, type) {
  const initialCountryValue = entity[`${type}Country`]
  const initialStateValue = entity[`${type}State`]

  const countryValue = findCountry(initialCountryValue)?.value || ''
  if (initialCountryValue && !countryValue) {
    // eslint-disable-next-line
    console.error(`No country option found for: ${initialCountryValue}`)
  }
  let stateValue = initialStateValue
  if (countryValue === US_COUNTRY_VALUE) {
    stateValue = findUSState(initialStateValue)?.value || ''
    if (initialStateValue && !stateValue) {
      // eslint-disable-next-line
      console.error(`No US state option found for: ${initialStateValue}`)
    }
  }

  return {
    ...entity,
    [`${type}Country`]: countryValue,
    [`${type}State`]: stateValue,
  }
}

// Note on the following utils: entity should have a sanitized address, rather than raw data --

function setAddressDefaultValues(entity, type) {
  if (!isAddressBlank(entity, type)) return entity

  return {
    ...entity,
    [`${type}Country`]: US_COUNTRY_VALUE,
  }
}

export default getEntityInitialValues
