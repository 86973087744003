// Create a final row for the participants plan summary table (used in ARO participant file flow)
// this function is expected to be called with the result of the sumUpParticipantsSummaryData util
function createParticipantsSummaryOverviewRowBD(summaryOverview) {
  const {
    totalPlans,
    totalParticipants,
    amount,
    inspiraBenefitDisbursementProcessingFee,
    tpaBenefitDistributionProcessingFee,
    grossDistributionAmount,
  } = summaryOverview
  const pluralizedPlan = totalPlans === 1 ? 'plan' : 'plans'
  const pluralizedParticipant =
    totalParticipants === 1 ? 'participant' : 'participants'

  return {
    planName: `${totalPlans} ${pluralizedPlan}`,
    participants: `${totalParticipants} ${pluralizedParticipant}`,
    amount,
    inspiraBenefitDisbursementProcessingFee,
    tpaBenefitDistributionProcessingFee,
    grossDistributionAmount,
  }
}

export default createParticipantsSummaryOverviewRowBD
