import React from 'react'
import SelectRolloverTypeForm from '../forms/SelectRolloverTypeForm'
import { selectors } from '../reducer'
import * as actions from '../actions'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import {
  RolloverType,
  rolloverTypes,
} from '../../../config/automatic-rollovers'

const propTypes = {
  setRolloverType: PropTypes.func,
  rolloverType: PropTypes.string,
  setParticipantsForSubmission: PropTypes.func.isRequired,
  setParticipantFileName: PropTypes.func.isRequired,
}

function SelectRolloverTypeView({
  setRolloverType,
  rolloverType,
  setParticipantsForSubmission,
  setParticipantFileName,
}) {
  const history = useHistory()

  return (
    <div className="card full-height upload-participant-file-view-container">
      <div className="form-masthead">
        <h2>What type of rollover would you like to initiate?</h2>
      </div>
      <SelectRolloverTypeForm
        initialValues={{
          rolloverType: rolloverType ?? RolloverType.STANDARD,
        }}
        onSubmit={({ rolloverType }) => {
          setRolloverType(rolloverType)
          setParticipantsForSubmission([])
          setParticipantFileName(null)
          history.push({
            pathname: '/automatic-rollovers/upload-file',
          })
        }}
        rolloverTypes={rolloverTypes}
      />
    </div>
  )
}

SelectRolloverTypeView.propTypes = propTypes

function mapStateToProps(state) {
  return {
    rolloverType: selectors.rolloverType(state),
  }
}

const mapDispatchToProps = {
  setRolloverType: actions.setRolloverType,
  setParticipantsForSubmission: actions.setParticipantsForSubmission,
  setParticipantFileName: actions.setParticipantFileName,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SelectRolloverTypeView
)
