import { find } from 'lodash'
import {
  SigningMethod,
  signingMethodOptions,
  ServicesAgreementSubmissionType,
} from 'config/automatic-rollovers'

// A util to help specify the header for services agreement submission: `/submitPlanAgreement`
// submissionType depends whether it's for a new or existing services agreement, and its signing method
// Diagram to illustrate which submissionType to utilize by use cases:
// https://share.getcloudapp.com/ApuXB80z
function getServicesAgreementSubmissionType(planAgreementId, signingMethod) {
  const signingMethodOption = find(signingMethodOptions, [
    'value',
    signingMethod,
  ])

  switch (signingMethodOption.key) {
    case SigningMethod.ELECTRONIC_SIGNATURE: {
      if (planAgreementId)
        return ServicesAgreementSubmissionType.EXISTING_AGREEMENT
      return ServicesAgreementSubmissionType.NEW_AGREEMENT
    }
    case SigningMethod.WET_SIGNATURE: {
      if (planAgreementId)
        return ServicesAgreementSubmissionType.GENERATE_DOCUMENT_EXISTING
      return ServicesAgreementSubmissionType.GENERATE_DOCUMENT
    }
    case SigningMethod.E_SIGN_RSP: {
      if (planAgreementId)
        return ServicesAgreementSubmissionType.EXISTING_AGREEMENT
      return ServicesAgreementSubmissionType.NEW_AGREEMENT
    }
    default:
      throw new Error('Unable to generate a valid submission type')
  }
}

export default getServicesAgreementSubmissionType
