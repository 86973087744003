import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors as globalSelectors } from 'global-reducer'
import { ProductPortal } from 'components'
import * as chmln from '@chamaeleonidae/chmln'

const propTypes = {
  subscribedProducts: PropTypes.arrayOf(Types.subscribedProduct).isRequired,
  currentUser: PropTypes.object,
}

const defaultProps = {}

function Home({ subscribedProducts, currentUser }) {
  chmln.identify(currentUser.auth0UserID, {
    // Example user properties; to use these replace the placeholders (e.g. USER.SIGN_UP_DATE) with your app’s actual variable names.
    email: currentUser.EMAIL, // RECOMMENDED, email is used as the key to map user data for integrations
    name: `${currentUser.firstName} ${currentUser.lastName}`, // RECOMMENDED, name can be used to greet and/or personalize content
    role: currentUser.permissions, // OPTIONAL, properties such as ‘role’, ‘admin’, ‘membership’, etc. are useful for targeting types of users.
    // logins: currentUser.LOGIN_COUNT,         // OPTIONAL, data about user engagement (e.g. 39).
    // project: currentUser.PROJECT_ID,         // OPTIONAL, any other unique data that might appear in any page URLs (e.g. 09876 or "12a34b56").
  })

  return (
    <div className="home container">
      <h1>Welcome, {currentUser.firstName}</h1>
      <ProductPortal subscribedProducts={subscribedProducts} />
    </div>
  )
}

Home.propTypes = propTypes

Home.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    subscribedProducts: globalSelectors.orderedSubscribedProducts(state),
    currentUser: globalSelectors.currentUser(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Home)
