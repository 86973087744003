import React from 'react'
import * as Types from 'types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors as globalSelectors } from '../../reducer'
import { formatPhoneNumber, displayEin, displayAttribute } from 'utils'
import { ExternalLink } from 'components'

const propTypes = {
  currentUser: Types.admin.isRequired,
}

const defaultProps = {}

function CompanyDetailsHeader({ children }) {
  return (
    <div className="company-details-header">
      <h1>Company details</h1>
      {children}
    </div>
  )
}

function CompanyDetails({ currentUser }) {
  window.appEventData.pop()
  var appEventData = window.appEventData || []
  appEventData.push({
    event: 'Page Load Completed',
  })
  const {
    accountName,
    accountEIN,
    accountBillingWebsite,
    accountPhone,
    accountBillingStreet,
    accountBillingCountry,
    accountBillingState,
    accountBillingPostalCode,
    accountBillingCity,
  } = currentUser

  return (
    <div className="company-details-container">
      <CompanyDetailsHeader />
      <div className="card">
        <div className="company-details-header">
          <h2>Contact details</h2>
        </div>
        <div className="company-details-container">
          <dl>
            <div className="company-details-column">
              <dt>Company name</dt>
              <dd>{accountName}</dd>
            </div>
            <div className="company-details-column">
              <dt>
                Employer Identification Number (EIN) - <i>If applicable</i>
              </dt>
              <dd>{displayEin(accountEIN)}</dd>
            </div>
            <div className="company-details-column">
              <dt>
                Company website - <i>Optional</i>
              </dt>
              <dd>
                {accountBillingWebsite ? (
                  <ExternalLink
                    link={accountBillingWebsite}
                    className="company-website"
                  />
                ) : (
                  '-'
                )}
              </dd>
            </div>
            <div className="company-details-column">
              <dt>Company phone</dt>
              <dd>{accountPhone ? formatPhoneNumber(accountPhone) : '-'}</dd>
            </div>
          </dl>
        </div>
        <div className="company-details-header">
          <h2>Address details</h2>
        </div>
        <div className="company-details-container">
          <dl>
            <div className="company-details-column company-details-full-row">
              <dt>Street address</dt>
              <dd>{accountBillingStreet}</dd>
            </div>
            <div className="company-details-column">
              <dt>Country</dt>
              <dd>{accountBillingCountry}</dd>
            </div>
            <div className="company-details-column">
              <dt>State/Province/Region</dt>
              <dd>{displayAttribute(accountBillingState)}</dd>
              {/* not required if country is outside of US or CA */}
            </div>
            <div className="company-details-column">
              <dt>Zipcode</dt>
              <dd>{accountBillingPostalCode}</dd>
            </div>
            <div className="company-details-column company-details-full-row">
              <dt>City</dt>
              <dd>{displayAttribute(accountBillingCity)}</dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  )
}

CompanyDetails.propTypes = propTypes
CompanyDetails.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentUser: globalSelectors.currentUser(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CompanyDetails
)
