import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Modal, Button } from 'lp-components'
import { Icon as DownloadIcon } from 'images/download.svg'

const propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  handleDownload: PropTypes.func.isRequired,
}

const defaultProps = {}

function AllParticipantsRemovedModal({ onClose, onConfirm, handleDownload }) {
  return (
    <Modal id="all-participants-removed-modal" onClose={onClose}>
      <h1>All participants removed from submission</h1>
      <p>
        All of the rollover participants in the file uploaded have been removed
        from this submission due to issues with those participant's data, their
        plan type and status, agreement status, or their rollover amount. A
        rollover submission cannot be initiated unless there is at least one
        participant included.
      </p>
      <p>
        Please download the list of discarded participants to view the reason
        for their removal from your submission.
      </p>
      <div className="download-container">
        <button className="link-primary" type="button" onClick={handleDownload}>
          <DownloadIcon aria-hidden="true" />
          Download participant data
        </button>
      </div>
      <div className="modal-actions">
        <Button onClick={onConfirm}>OK</Button>
        <Button onClick={onClose} variant="text">
          Cancel
        </Button>
      </div>
    </Modal>
  )
}

AllParticipantsRemovedModal.propTypes = exact(propTypes)
AllParticipantsRemovedModal.defaultProps = defaultProps

export default React.memo(AllParticipantsRemovedModal)
