import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Path } from 'config/portal'
import { ConfirmModal } from 'components'

const propTypes = {
  className: PropTypes.string,
  isMockedPayment: PropTypes.bool.isRequired,
}

const defaultProps = {
  className: '',
}

function AddNewPaymentMethod({ className, isMockedPayment }) {
  const [showRemovePaymentModal, setShowRemovePaymentModal] = useState(false)
  const [showAddPaymentModal, setShowAddPaymentModal] = useState(false)
  return (
    <div>
      {!isMockedPayment && (
        <div
          onClick={() => setShowRemovePaymentModal(true)}
          className={className}
        >
          Replace payment details
        </div>
      )}
      {isMockedPayment && (
        <div onClick={() => setShowAddPaymentModal(true)} className={className}>
          Add payment details
        </div>
      )}
      {showRemovePaymentModal && (
        <ConfirmModal
          onClose={() => setShowRemovePaymentModal(false)}
          onConfirm={() => {
            setShowRemovePaymentModal(false)
            window.location.href = Path.ADD_PAYMENT_DETAILS
          }}
          confirmContent="Yes, Replace"
          className="remove-payment-modal"
        >
          <h2>Replace payment details</h2>
          <p>
            Keep in mind you can only have one card on file. Replacing it here
            will replace it across the platform.
          </p>
        </ConfirmModal>
      )}
      {showAddPaymentModal && (
        <ConfirmModal
          onClose={() => setShowAddPaymentModal(false)}
          onConfirm={() => {
            setShowAddPaymentModal(false)
            window.location.href = Path.ADD_PAYMENT_DETAILS
          }}
          confirmContent="Yes"
          className="remove-payment-modal"
        >
          <h2>Add payment details</h2>
        </ConfirmModal>
      )}
    </div>
  )
}

AddNewPaymentMethod.propTypes = exact(propTypes)
AddNewPaymentMethod.defaultProps = defaultProps

export default AddNewPaymentMethod
