import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Modal, Button } from 'lp-components'
import { AroActionItems, BDActionItems } from 'config/portal'
import { ExternalLink } from 'components'

const propTypes = {
  onClose: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  agreementsType: PropTypes.string.isRequired,
}

const defaultProps = {}

function StartNewRolloverDistributionModal({
  onClose,
  onContinue,
  agreementsType,
}) {
  return (
    <Modal id="start-new-rollover-modal" onClose={onClose}>
      <h1>
        {agreementsType === 'Automatic Rollovers'
          ? AroActionItems.INITIATE_ROLLOVER
          : BDActionItems.INITIATE_DISTRIBUTION}
      </h1>
      <p>
        We've made a few changes to our rollover process. Please review the
        following items before you initiate a new rollover.
      </p>
      <ul>
        <li>
          A single rollover submission may include participants from several
          different retirement plans and/or plan sponsors.
        </li>
        <li>
          All rollovers will be required to have a completed Services Agreement
          on file for the corresponding retirement plan.
        </li>
        <li>
          All participant data spreadsheets must follow our updated participant
          data file template.{' '}
          <ExternalLink
            link={
              agreementsType === 'Automatic Rollovers'
                ? process.env.REACT_APP_ARO_EZ_WORKSHEET_TEMPLATE_URL
                : process.env
                    .REACT_APP_RSP_INSPIRAFINANCIAL_EZ_BENEFIT_DISBURSEMENT_URL
            }
            className="link-text"
          >
            Download Updated Spreadsheet
          </ExternalLink>
        </li>
      </ul>
      <div className="modal-actions">
        <Button onClick={onContinue}>Continue</Button>
        <Button className="button-cancel" onClick={onClose} variant="text">
          Cancel
        </Button>
      </div>
    </Modal>
  )
}

StartNewRolloverDistributionModal.propTypes = exact(propTypes)
StartNewRolloverDistributionModal.defaultProps = defaultProps

export default React.memo(StartNewRolloverDistributionModal)
