function formatZipCode(zipcode) {
  let zip = zipcode.replace(/[^0-9-]/g, '')

  // Keep only the first hyphen and remove subsequent hyphens
  const firstHyphenIndex = zip.indexOf('-')
  if (firstHyphenIndex !== -1) {
    zip =
      zip.slice(0, firstHyphenIndex + 1) +
      zip.slice(firstHyphenIndex + 1).replace(/-/g, '')

    return zip
  }

  // Runs the actual zip formatting
  if (zip.trim() !== '') {
    const sanitizedZip = zip.match(/[0-9]/g)?.join('')
    if (sanitizedZip) {
      zip = sanitizedZip
      if (sanitizedZip.length === 4 || sanitizedZip.length === 8) {
        zip = '0' + zip
      } else if (sanitizedZip.length === 3 || sanitizedZip.length === 7) {
        zip = '00' + zip
      } else if (sanitizedZip.length === 2 || sanitizedZip.length === 6) {
        zip = '000' + zip
      }
    }
    if (sanitizedZip.length > 5) {
      zip = zip.substring(0, 5) + '-' + zip.substring(5)
    }
    return zip
  }

  // Returns empty string when it's not a valid zipcode
  return ''
}

export default formatZipCode
