import { omit } from 'lodash'

// An entity consists of 'contact' and 'company' (e.g., recordkeeper, plan sponsor)
// If 'contact' has the same address as 'company', update its address fields to match company's address fields

function updateContactAddress(entity) {
  const { contact, company } = entity

  if (contact.isSameAsCompanyAddress) {
    return {
      ...omit(contact, 'isSameAsCompanyAddress'),
      mailingStreet: company.billingStreet,
      mailingCountry: company.billingCountry,
      mailingState: company.billingState,
      mailingPostalCode: company.billingPostalCode,
      mailingCity: company.billingCity,
    }
  }
  return omit(contact, 'isSameAsCompanyAddress')
}

export default updateContactAddress
