import React from 'react'
import PropTypes from 'prop-types'
import ReactShowMoreText from 'react-show-more-text'
import classnames from 'classnames'

const propTypes = {
  text: PropTypes.string.isRequired,
  lines: PropTypes.number,
  className: PropTypes.string,
}

const defaultProps = {
  lines: 3,
  className: '',
}

function ShowMoreText({ text, lines, className, ...rest }) {
  return (
    <ReactShowMoreText
      lines={lines}
      more="See more"
      less="See less"
      truncatedEndingComponent={'... '}
      className={classnames('show-more-text', className)}
      {...rest}
    >
      {text}
    </ReactShowMoreText>
  )
}

ShowMoreText.propTypes = propTypes
ShowMoreText.defaultProps = defaultProps

export default React.memo(ShowMoreText)
