import React, { useState, useEffect } from 'react'
import { AddNewPaymentForm } from '../forms'
import * as Types from 'types'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors as globalSelectors } from '../../reducer'
import { selectors } from '../reducer'
import { isSearchPaymentEnabled } from 'config/portal'
import { ComponentFailure } from 'components'
import * as apiActions from 'api-actions'
import { displayAttribute } from 'utils'
import { isEmpty } from 'lodash'
import * as actions from '../actions'

const propTypes = {
  paymentMethod: Types.paymentMethod,
  fetchPaymentMethod: PropTypes.func.isRequired,
  shouldTriggerPaymentInfoFetch: PropTypes.bool.isRequired,
  setShouldTriggerPaymentInfoFetch: PropTypes.func.isRequired,
}

const defaultProps = {
  paymentMethod: null,
}

function PaymentDetails({
  paymentMethod,
  fetchPaymentMethod,
  shouldTriggerPaymentInfoFetch,
  setShouldTriggerPaymentInfoFetch,
}) {
  const [error, setError] = useState(false)

  const paymentEnabled = isSearchPaymentEnabled()

  useEffect(() => {
    if (!paymentEnabled) return

    async function getPaymentMethod() {
      try {
        await fetchPaymentMethod()
      } catch {
        setError(true)
      }
    }
    if (!paymentMethod || shouldTriggerPaymentInfoFetch) {
      getPaymentMethod()
      setShouldTriggerPaymentInfoFetch(false)
    }
    return () => {
      setShouldTriggerPaymentInfoFetch(false)
    }
  }, [paymentMethod, shouldTriggerPaymentInfoFetch])

  if (error) {
    return (
      <div>
        <div className="card">
          <ComponentFailure />
        </div>
      </div>
    )
  }
  const isMockedPayment = paymentMethod && paymentMethod[0]?.mock == ''
  return (
    <div className="user-details-container">
      <div className="company-details-header">
        <h1>Payment details</h1>
      </div>
      {!isEmpty(paymentMethod) && !isMockedPayment && (
        <>
          <div className="personal-details-container">
            <dl>
              <div className="personal-details-column">
                <dt>Card holder</dt>
                <dd>
                  {displayAttribute(
                    `${paymentMethod.firstName} ${paymentMethod.lastName}`
                  )}
                </dd>
              </div>
              <div className="personal-details-column">
                <dt>Credit card number</dt>
                <dd>
                  {paymentMethod.creditCardLastFour
                    ? paymentMethod.creditCardLastFour.padStart(16, '•')
                    : paymentMethod.cardLabel}
                </dd>
              </div>
              <div className="personal-details-column">
                <dt>Expiration date (month/year)</dt>
                <dd>
                  {displayAttribute(
                    [paymentMethod.cardExpMonth, paymentMethod.cardExpYear]
                      .filter(Boolean)
                      .join('/')
                  )}
                </dd>
              </div>
              <div className="personal-details-column personal-details-full-row">
                <dt>Billing street address</dt>
                <dd>{displayAttribute(paymentMethod.address1)}</dd>
              </div>
              <div className="personal-details-column">
                <dt>Country</dt>
                <dd>{displayAttribute(paymentMethod.country)}</dd>
              </div>
              <div className="personal-details-column">
                <dt>State/Province/Region</dt>
                <dd>{displayAttribute(paymentMethod.state)}</dd>
              </div>
              <div className="personal-details-column">
                <dt>Zipcode</dt>
                <dd>{displayAttribute(paymentMethod.zipCode)}</dd>
              </div>
              <div className="personal-details-column">
                <dt>City</dt>
                <dd>{displayAttribute(paymentMethod.city)}</dd>
              </div>
            </dl>
          </div>
        </>
      )}
      <div className="card">
        <AddNewPaymentForm />
      </div>
    </div>
  )
}

PaymentDetails.propTypes = propTypes
PaymentDetails.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentUser: globalSelectors.currentUser(state),
    paymentMethod: selectors.paymentMethod(state),
    shouldTriggerPaymentInfoFetch:
      selectors.shouldTriggerPaymentInfoFetch(state),
  }
}

const mapDispatchToProps = {
  fetchPaymentMethod: apiActions.fetchPaymentMethod,
  setShouldTriggerPaymentInfoFetch: actions.setShouldTriggerPaymentInfoFetch,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PaymentDetails
)
