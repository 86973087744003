export const BD_TRANSACTION_TYPES = {
  check: 'check',
  wire: 'wire',
  ach: 'ACH',
  overnight: 'check/overnight delivery',
}
export const BD_PAYMENT_INSTRUCTIONS = {
  directRollover: 'direct rollover',
  directToParticipant: 'direct to participant',
}
export const paymentMethods = [
  {
    key: 'deductFromDistribution',
    value: 'Deduct from distribution',
    description: 'We’ll deduct fees directly from the distribution amount.',
  },
  {
    key: 'creditCard',
    value: 'Pay with credit card',
    description:
      'You’ll confirm your credit card information after completing the process.',
  },
]
export const PAYMENT_METHODS = {
  deductFromDistribution: 'Deduct from distribution',
  creditCard: 'Pay with credit card',
}
export const PAYMENT_CONFIRMATION_TEXT = `I authorize Inspira Financial to charge my credit card for the total amount of this purchase for benefit distributions. I understand that this charge will appear on my credit card statement as Inspira Financial. I further certify that I am the authorized user of the credit card and that all information provided is true and accurate.`
