import React, { useState, useMemo } from 'react'
import SearchInput from '../../../components/SearchInput'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as globalActions from '../../../main/actions'
import {
  Accordion as BaseAccordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'

import { Icon as TogglePlusIcon } from 'images/utility-icons/plus.svg'
import { Icon as ToggleMinusIcon } from 'images/utility-icons/minus.svg'

import pausedGif from 'images/play-grey-5.svg'

const GIFS = [
  require('images/gifs/view service agreements - ARO and BD.gif'),
  require('images/gifs/download search agreement -2.gif'),
  require('images/gifs/create service agreement.gif'),
  require('images/gifs/3rd party e-sign.gif'),
  require('images/gifs/3rd party physical sign - 2.gif'),
  require('images/gifs/initiate rollover.gif'),
  require('images/gifs/check rollover status.gif'),
  require('images/gifs/download rollover report 2.gif'),
  require('images/gifs/start new search.gif'),
  require('images/gifs/add payment.gif'),
  require('images/gifs/add new user.gif'),
]

const GIFPlayer = ({ gif }) => {
  const [play, setPlay] = useState(false)
  return (
    <div onClick={() => setPlay(!play)}>
      <img
        className={play ? 'faq-gif' : 'faq-gif-paused'}
        src={play ? gif : pausedGif}
        alt="faq-gif"
      />
    </div>
  )
}

const FAQ_DATA = [
  {
    header: 'Services agreements',
    faqs: [
      {
        heading: 'Where can I see my services agreements? ',
        panel: (
          <>
            <div>
              <div className="answer-bold-1">
                For automatic rollovers and benefit distributions (coming soon)
              </div>
              From the homepage, select the product you’d like to see your
              services agreements for. Your agreements will display on the
              product page.
              <GIFPlayer gif={GIFS[0]} />
            </div>
            <div>
              <div className="answer-bold-1">For search services</div> From the
              homepage, open search services. Then select “Actions” and
              “Download agreement.”
              <GIFPlayer gif={GIFS[1]} />
            </div>
          </>
        ),
      },
      {
        heading: 'How do I initiate a services agreement? ',
        panel: (
          <>
            From the homepage, open the relevant product, then select “Create
            new services agreement”.
            <GIFPlayer gif={GIFS[2]} />
          </>
        ),
      },
      {
        heading:
          'Can I have a third party, like a plan sponsor, sign an agreement? ',
        panel: (
          <>
            <div>
              Yes. For products that require a third-party signature, you can
              either:
            </div>
            <div>
              Choose “Electronic signature’ and enter the email of the signer.
              <GIFPlayer gif={GIFS[3]} />
            </div>
            <div>
              Choose ‘Physical signature’ and download a copy of the agreement
              to get it signed.
              <GIFPlayer gif={GIFS[4]} />
            </div>
          </>
        ),
      },
      {
        heading: 'How long is the services agreement review process?  ',
        panel: (
          <>
            Once the services agreement is received by Inspira, our team
            typically reviews it within 2 business days.
          </>
        ),
      },
      {
        heading:
          'Will I be notified when my service agreement has been approved? ',
        panel: (
          <>
            Yes, you’ll receive a confirmation email when the services agreement
            has been approved.
          </>
        ),
      },
      {
        heading: 'How can I amend the information I put in an agreement? ',
        panel: (
          <>
            You can email us at{' '}
            <a className="faq-link" href="mailto:RS_Sales@inspirafinancial.com">
              RS_Sales@inspirafinancial.com
            </a>{' '}
            to make changes to your agreement.
          </>
        ),
      },
      {
        heading: 'What if I don’t see all my agreements?  ',
        panel: (
          <>
            You can email us at{' '}
            <a className="faq-link" href="mailto:RS_Sales@inspirafinancial.com">
              RS_Sales@inspirafinancial.com
            </a>{' '}
            or reach out to your relationship manager for help. Keep in mind you
            should be able to see all the agreements for your company, even if
            it was submitted by someone else.
          </>
        ),
      },
    ],
  },
  {
    header: 'Automatic rollovers',
    faqs: [
      {
        heading: 'Where can I initiate a rollover? ',
        panel: (
          <>
            From the homepage, open “Automatic rollovers” and select “Initiate
            rollover”.
            <GIFPlayer gif={GIFS[5]} />
          </>
        ),
      },
      {
        heading: 'Can I submit rollovers for multiple plans at once?  ',
        panel: (
          <>
            Yes, be sure to include information for each plan in the EZ
            worksheet.
          </>
        ),
      },
      {
        heading: 'Where can I find the EZ worksheet template? ',
        panel: (
          <>
            You can access it{' '}
            <a
              className="faq-link"
              href={process.env.REACT_APP_ARO_EZ_WORKSHEET_TEMPLATE_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            .
          </>
        ),
      },
      {
        heading: 'Where can I check the status of my rollovers?  ',
        panel: (
          <>
            From the automatic rollover page, select the relevant plan, then
            navigate to the participants tab.
            <GIFPlayer gif={GIFS[6]} />
          </>
        ),
      },
      {
        heading: 'How can I download rollover reports? ',
        panel: (
          <>
            From the participants tab, select the download icon.
            <GIFPlayer gif={GIFS[7]} />
          </>
        ),
      },
    ],
  },
  {
    header: 'Search services',
    faqs: [
      {
        heading: 'How can I initiate a search? ',
        panel: (
          <>
            From the homepage, open “Search services” and select “Start new
            search”.
            <GIFPlayer gif={GIFS[8]} />
          </>
        ),
      },
      {
        heading: 'What types of searches do you offer?  ',
        panel: (
          <>
            <div className="faq-answer-header">
              We offer 6 types of searches:
            </div>
            <ul>
              <li>Address search</li>
              <div className="faq-answer-text">
                Search for participants' addresses, dates of birth, and phone
                numbers.
                <div>
                  <div className="answer-bold-2">Estimated turnaround:</div> 1-2
                  business days
                </div>
              </div>
              <li>Confirmed search</li>
              <div className="faq-answer-text">
                Search for participants’ or beneficiaries’ confirmed addresses
                and conduct a one-time advanced death audit of the death master
                file (DMF) and obituary records.
                <div>
                  <div className="answer-bold-2">What’s included:</div> Mailing
                  services, completion report
                </div>
                <div>
                  <div className="answer-bold-2">Estimated turnaround:</div> 100
                  business days
                </div>
              </div>

              <li>Fiduciary search</li>
              <div className="faq-answer-text">
                Search for participants’ or beneficiaries’ confirmed addresses
                (up to 3) and conduct a one-time advanced death audit of the
                death master file (DMF) and obituary records. Death results are
                then manually reviewed.
                <div>
                  <div className="answer-bold-2">What’s included:</div> Up to 3
                  mailings, up to 2 outbound phone calls, 2 social media
                  services, completion report, and letter if applicable
                </div>
                <div>
                  <div className="answer-bold-2">Estimated turnaround:</div>{' '}
                  Completed within 100 business days
                </div>
              </div>

              <li>Advanced death audit</li>
              <div className="faq-answer-text">
                Search the death master file (DMF) and obituary records.
                <div>
                  <div className="answer-bold-2">Estimated turnaround:</div> Up
                  to 10 business days, 100,000+ requests and backlog of old
                  deaths may take 3 -4 weeks
                </div>
              </div>

              <li>Relative search</li>
              <div className="faq-answer-text">
                Search for participants’ relatives to update life statuses for
                required communications or de-risking strategies—or determine
                life status by conducting an audit of the death master file
                (DMF).
                <div>
                  <div className="answer-bold-2">Estimated turnaround:</div> 1-2
                  business days
                </div>
              </div>

              <li>Beneficiary search</li>
              <div className="faq-answer-text">
                Identify if a deceased participant has a surviving spouse and
                first-degree relatives. If found, the search will provide
                contact information or obituary records through a manual search
                from multiple sources.
                <div>
                  <div className="answer-bold-2">Estimated turnaround:</div>{' '}
                  6-12 business days, or up to 7 weeks for 50+ participant
                  requests.
                </div>
              </div>
            </ul>
          </>
        ),
      },
      {
        heading: 'How can I pay for a search order? ',
        panel: (
          <>
            We now accept credit card payments. You can enter your payment
            details when placing an order. If credit card payment is not
            feasible, contact your relationship manager.
            <GIFPlayer gif={GIFS[9]} />
          </>
        ),
      },
      {
        heading: 'How long will my search results be available? ',
        panel: (
          <>
            Your search results will be available for 30 days once they become
            available.
          </>
        ),
      },
    ],
  },
  {
    header: 'Onboarding',
    faqs: [
      {
        heading:
          'How can a colleague at my company gain access to the Retirement Services Platform? ',
        panel: (
          <>
            Users that are designated as administrators for their company can
            onboard new users by going to:
            <div>
              <div className="answer-bold-2">
                Manage account {'>'} User details {'>'} Add new user.
              </div>
            </div>
            <GIFPlayer gif={GIFS[10]} />
            You can also email us at{' '}
            <a className="faq-link" href="mailto:RS_Sales@inspirafinancial.com">
              RS_Sales@inspirafinancial.com
            </a>{' '}
            or reach out to your relationship manager.
          </>
        ),
      },
    ],
  },
]

function FAQ({ setSupportModalShown }) {
  const [searchInput, setSearchInput] = useState('')
  const [expandedItems, setExpandedItems] = useState('')

  const filteredData = useMemo(() => {
    return FAQ_DATA.filter((item) => {
      return item.faqs.some(
        (faq) =>
          faq.panel.props.children
            .toString()
            .toLowerCase()
            .includes(searchInput.toLowerCase()) ||
          faq.heading
            .toString()
            .toLowerCase()
            .includes(searchInput.toLowerCase())
      )
    })
  }, [searchInput, FAQ_DATA])

  return (
    <div className="faq">
      <div className="faq-container">
        <div className="faq-header-section">
          <div className="faq-header">Frequently asked questions</div>
          <button
            type="button"
            id="faq-contact-button"
            className="button-text"
            onClick={() => setSupportModalShown(true)}
          >
            Contact us
          </button>
        </div>
        <div className="faq-search">
          <SearchInput
            className="faq-search-input"
            placeholder="Search"
            value={searchInput}
            onChange={(change) => {
              setSearchInput(change.target.value)
            }}
          />
        </div>
        <div className="faq-list">
          <BaseAccordion
            allowZeroExpanded={true}
            allowMultipleExpanded={false}
            onChange={(itemUuids) => {
              setExpandedItems(itemUuids)
            }}
          >
            {filteredData.map((item, parentIndex) => (
              <div className="faq-card-container" key={parentIndex}>
                <div className="faq-card-header">{item.header}</div>
                {item.faqs.map((item, childIndex) => {
                  let itemIndex = `[${parentIndex}-${childIndex}]`
                  return (
                    <AccordionItem
                      className="faq-accordion-item"
                      uuid={itemIndex}
                      key={itemIndex}
                    >
                      <AccordionItemHeading aria-level={5}>
                        <AccordionItemButton className="faq-question">
                          {item.heading}
                          <div className="faq-question-button">
                            {itemIndex == expandedItems ? (
                              <ToggleMinusIcon aria-hidden="true" />
                            ) : (
                              <TogglePlusIcon aria-hidden="true" />
                            )}
                          </div>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel region={true} className="faq-answer">
                        {item.panel}
                      </AccordionItemPanel>
                    </AccordionItem>
                  )
                })}
              </div>
            ))}
          </BaseAccordion>
        </div>
      </div>
    </div>
  )
}
const mapDispatchToProps = {
  setSupportModalShown: globalActions.setSupportModalShown,
}

export default compose(connect(null, mapDispatchToProps))(FAQ)
