import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useClickAway } from 'react-use'

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  selectedValues: PropTypes.arrayOf(PropTypes.string),
}

const defaultProps = {
  className: '',
  selectedValues: [],
}

// Wraps the `DropdownCheckboxGroup` component
// Extended the DropdownSelect component so that we could
// display the selected items according to the MTC design
// which shows a truncated list of selected items

function DropdownSelect({ children, className, selectedValues }) {
  const ref = useRef()
  const [expanded, setExpanded] = useState(false)
  const toggleExpanded = () => setExpanded((v) => !v)

  useClickAway(ref, () => setExpanded(false))

  return (
    <div ref={ref} className="dropdown-select">
      <div
        className="select-input"
        onClick={toggleExpanded}
        onKeyDown={toggleExpanded}
      >
        <p>
          {selectedValues.length > 0 && <span>{selectedValues[0]}</span>}
          {selectedValues.length > 1 && (
            <span>+ {selectedValues.length - 1}...</span>
          )}
          {selectedValues.length < 1 && 'None'}
        </p>
      </div>
      <div
        className={classnames(className, 'options', {
          'is-active': expanded,
        })}
      >
        <div className="scroll-box">{children}</div>
      </div>
    </div>
  )
}

DropdownSelect.propTypes = propTypes
DropdownSelect.defaultProps = defaultProps

export default DropdownSelect
