import { isGetFeedbackEnabled } from 'config/portal'

// Invokes GetFeedback Digital's virtual page views to indicate a page switch for SPAs
// https://support.usabilla.com/hc/en-us/articles/115004155665-Virtual-pageviews-in-Single-Page-Applications-SPA-
function getFeedbackUrlChanged() {
  if (isGetFeedbackEnabled() && window.usabilla_live)
    return window.usabilla_live('virtualPageView')
}

export default getFeedbackUrlChanged
