import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { displayAttribute, formatPhoneNumber } from 'utils'

const propTypes = {
  company: PropTypes.shape({
    name: PropTypes.string.isRequired,
    phone: PropTypes.string,
    website: PropTypes.string,
    billingStreet: PropTypes.string,
    billingCity: PropTypes.string,
    billingState: PropTypes.string,
    billingPostalCode: PropTypes.string,
    billingCountry: PropTypes.string,
  }).isRequired,
}

const defaultProps = {}

function CompanyDetails({ company }) {
  const {
    name,
    phone,
    website,
    billingStreet: street,
    billingCity: city,
    billingState: state,
    billingPostalCode: postalCode,
    billingCountry: country,
  } = company

  return (
    <dl className="audience-info">
      <div className="row">
        <dt>Company name</dt>
        <dd>{name}</dd>
        <dt>Company phone</dt>
        <dd>{phone ? formatPhoneNumber(phone) : displayAttribute()}</dd>
      </div>
      <div className="row">
        <dt>Company website</dt>
        <dd>{displayAttribute(website)}</dd>
        <dt>Street address</dt>
        <dd>{displayAttribute(street)}</dd>
      </div>
      <div className="row">
        <dt>Country</dt>
        <dd>{displayAttribute(country)}</dd>
        <dt>State/Province/Region</dt>
        <dd>{displayAttribute(state)}</dd>
      </div>
      <div className="row">
        <dt>Zip code</dt>
        <dd>{displayAttribute(postalCode)}</dd>
        <dt>City</dt>
        <dd>{displayAttribute(city)}</dd>
      </div>
    </dl>
  )
}

CompanyDetails.propTypes = exact(propTypes)
CompanyDetails.defaultProps = defaultProps

export default React.memo(CompanyDetails)
