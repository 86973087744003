import React, { useState, useEffect } from 'react'
import * as Types from 'types'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectors as globalSelectors } from '../../reducer'
import { selectors } from '../reducer'
import { isSearchPaymentEnabled } from 'config/portal'
import { Spinner, ComponentFailure } from 'components'
import * as apiActions from 'api-actions'
import AddNewPaymentMethod from '../components/AddNewPaymentMethod'
import { displayAttribute } from 'utils'
import { isEmpty } from 'lodash'
import * as actions from '../actions'

const propTypes = {
  currentUser: Types.user.isRequired,
  paymentMethod: Types.paymentMethod,
  fetchPaymentMethod: PropTypes.func.isRequired,
  shouldTriggerPaymentInfoFetch: PropTypes.bool.isRequired,
  setShouldTriggerPaymentInfoFetch: PropTypes.func.isRequired,
}

const defaultProps = {
  paymentMethod: null,
}

function ProfileDetailsTitle() {
  return <h1>Profile details</h1>
}

function ProfileDetailsHeader({ children }) {
  return (
    <div className="personal-details-header">
      <ProfileDetailsTitle />
      {children}
    </div>
  )
}

function DetailsView({
  currentUser,
  paymentMethod,
  fetchPaymentMethod,
  shouldTriggerPaymentInfoFetch,
  setShouldTriggerPaymentInfoFetch,
}) {
  const { firstName, lastName, userEmail, userPhone } = currentUser
  const [error, setError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const paymentEnabled = isSearchPaymentEnabled()
  useEffect(() => {
    if (!paymentEnabled) return

    async function getPaymentMethod() {
      try {
        await fetchPaymentMethod()
        setIsLoading(false)
      } catch {
        setError(true)
        setIsLoading(false)
      }
    }
    if (!paymentMethod || shouldTriggerPaymentInfoFetch) {
      setIsLoading(true)
      getPaymentMethod()
      setShouldTriggerPaymentInfoFetch(false)
    }
  }, [shouldTriggerPaymentInfoFetch])
  const isMockedPayment = paymentMethod && paymentMethod[0]?.mock == ''

  if (isLoading) {
    return (
      <div>
        <ProfileDetailsTitle />
        <div className="card">
          <Spinner />
        </div>
      </div>
    )
  }

  if (error) {
    return (
      <div>
        <ProfileDetailsTitle />
        <div className="card">
          <ComponentFailure />
        </div>
      </div>
    )
  }

  return (
    <div className="personal-details-container">
      <ProfileDetailsHeader>
        {paymentEnabled ? (
          <AddNewPaymentMethod
            className="button-primary-outline add-new-user-btn"
            isMockedPayment={isMockedPayment}
          />
        ) : null}
      </ProfileDetailsHeader>
      <div className="card">
        <div className="personal-details-header">
          <h2>Personal details</h2>
        </div>
        <div className="personal-details-container">
          <dl>
            <div className="personal-details-column">
              <dt>First name</dt>
              <dd>{firstName}</dd>
            </div>
            <div className="personal-details-column">
              <dt>Last name</dt>
              <dd>{lastName}</dd>
            </div>
            <div className="personal-details-column">
              <dt>Email</dt>
              <dd>{userEmail}</dd>
            </div>
            <div className="personal-details-column">
              <dt className="personal-details-label">
                Phone - <em>Optional</em>
              </dt>
              <dd>{displayAttribute(userPhone)}</dd>
            </div>
          </dl>
        </div>

        {!isEmpty(paymentMethod) && !isMockedPayment && (
          <>
            <div className="personal-details-header">
              <h2>Payment details</h2>
            </div>
            <div className="personal-details-container">
              <dl>
                <div className="personal-details-column">
                  <dt>Card holder</dt>
                  <dd>
                    {displayAttribute(
                      `${paymentMethod.firstName} ${paymentMethod.lastName}`
                    )}
                  </dd>
                </div>
                <div className="personal-details-column">
                  <dt>Credit card number</dt>
                  <dd>
                    {paymentMethod.creditCardLastFour
                      ? paymentMethod.creditCardLastFour.padStart(16, '•')
                      : paymentMethod.cardLabel}
                  </dd>
                </div>
                <div className="personal-details-column">
                  <dt>Expiration date (month/year)</dt>
                  <dd>
                    {displayAttribute(
                      [paymentMethod.cardExpMonth, paymentMethod.cardExpYear]
                        .filter(Boolean)
                        .join('/')
                    )}
                  </dd>
                </div>
                <div className="personal-details-column personal-details-full-row">
                  <dt>Billing street address</dt>
                  <dd>{displayAttribute(paymentMethod.address1)}</dd>
                </div>
                <div className="personal-details-column">
                  <dt>Country</dt>
                  <dd>{displayAttribute(paymentMethod.country)}</dd>
                </div>
                <div className="personal-details-column">
                  <dt>State/Province/Region</dt>
                  <dd>{displayAttribute(paymentMethod.state)}</dd>
                </div>
                <div className="personal-details-column">
                  <dt>Zipcode</dt>
                  <dd>{displayAttribute(paymentMethod.zipCode)}</dd>
                </div>
                <div className="personal-details-column">
                  <dt>City</dt>
                  <dd>{displayAttribute(paymentMethod.city)}</dd>
                </div>
              </dl>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

DetailsView.propTypes = propTypes
DetailsView.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentUser: globalSelectors.currentUser(state),
    paymentMethod: selectors.paymentMethod(state),
    shouldTriggerPaymentInfoFetch:
      selectors.shouldTriggerPaymentInfoFetch(state),
  }
}

const mapDispatchToProps = {
  fetchPaymentMethod: apiActions.fetchPaymentMethod,
  setShouldTriggerPaymentInfoFetch: actions.setShouldTriggerPaymentInfoFetch,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DetailsView
)
